@keyframes point-map {
  0% {
    clip-path: circle(30%);
    opacity: 0.05;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    clip-path: circle(100%);
  }
}
@keyframes scroll {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes viewMore-hover {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  51% {
    left: 0;
    width: 0;
  }
  100% {
    left: 0;
    width: 100%;
  }
}
@keyframes is-fade {
  0% {
    opacity: 0;
    transform: translateY(1.875rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes drawer {
  0% {
    clip-path: inset(0% 0% 0% 0%);
  }
  100% {
    clip-path: inset(0% 0% 100% 0%);
  }
}
@keyframes hamburger-opening {
  0% {
    clip-path: inset(0% 0% 0% 0%);
  }
  50% {
    clip-path: inset(0% 0% 0% 100%);
  }
  51% {
    clip-path: inset(0% 100% 0% 0%);
  }
  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}
@keyframes hamburger-closing {
  0% {
    clip-path: inset(0% 0% 0% 0%);
  }
  50% {
    clip-path: inset(0% 0% 0% 100%);
  }
  51% {
    clip-path: inset(0% 100% 0% 0%);
  }
  100% {
    clip-path: inset(0% 0% 0% 0%);
  }
}
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 0.9523809524vw;
  }
}
@media (min-width: 1680px) {
  html {
    font-size: 16px;
  }
}

[data-animation] {
  opacity: 0;
}

.is-fade {
  animation: is-fade 0.8s ease-in-out forwards;
}

* {
  font-family: "noto-sans-cjk-jp", sans-serif;
  font-weight: 400;
}

body {
  color: #000;
}

img {
  width: 100%;
}

main.blur {
  filter: blur(8px);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.6em;
}

a img {
  opacity: 1;
  transition: opacity 0.4s;
}
a:hover img {
  opacity: 0.8;
}
a.opacity {
  opacity: 1;
  transition: opacity 0.4s;
}
a.opacity:hover {
  opacity: 0.6;
}

picture {
  display: block;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-jc-sb {
  justify-content: space-between;
}

.f-jc-c {
  justify-content: center;
}

.f-jc-fe {
  justify-content: flex-end;
}

.f-ai-c {
  align-items: center;
}

.f-ai-fs {
  align-items: flex-start;
}

.f-ai-fe {
  align-items: flex-end;
}

.f-ac-c {
  align-content: center;
}

.base {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  word-wrap: break-word;
}

input, textarea {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  object-fit: cover;
  border: 0;
}

.bg-ttl {
  font-size: 10rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
  color: #000;
}
@media screen and (max-width: 767px) {
  .bg-ttl {
    font-size: 3.125rem;
  }
}

.card1 {
  display: block;
  max-width: 31.25rem;
  color: #000;
  transition: all 0.3s ease-in-out;
}
.card1__img {
  aspect-ratio: 500/336;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .card1__img {
    margin-bottom: rme(10);
  }
}
.card1__img picture {
  height: 100%;
}
.card1__img picture img {
  height: 100%;
}
.card1__text {
  display: flex;
  gap: 0.9375rem;
  align-items: flex-start;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .card1__text {
    gap: 0.5rem;
  }
}
.card1__date {
  display: flex;
  gap: 0.3125rem;
  letter-spacing: 0.05em;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .card1__date {
    gap: 0.1875rem;
  }
}
.card1__year-month {
  line-height: 1;
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .card1__year-month {
    font-size: 0.75rem;
  }
}
.card1__day {
  line-height: 1;
  font-family: "Marcellus", serif;
  font-size: 1.875rem;
}
@media screen and (max-width: 767px) {
  .card1__day {
    font-size: 1.5rem;
  }
}
.card1__title {
  font-size: 1.125rem;
  letter-spacing: 0.1em;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .card1__title {
    font-size: 0.9375rem;
  }
}
.card1__info {
  margin-top: 0.9375rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.card1__cat {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #FFF;
  padding: 0.4375rem 0.4375rem 0.5rem;
  line-height: 1;
  background: #000;
}
.card1__tags {
  display: flex;
  gap: 0.9375rem;
}
.card1__tag {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.05em;
}
.card1__tag::before {
  content: "#";
  color: #aaaaaa;
  padding-right: 0.1875rem;
}

.card1:hover .card1__text {
  color: #f48654;
}

.footer {
  background: #f9f9f9;
  padding: 6.875rem 0 4.375rem;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 3.4375rem 0 1.875rem;
  }
}
.footer__inner {
  max-width: 75rem;
  margin: 0 auto;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .footer__inner {
    max-width: 37.5rem;
  }
}
.footer__contents {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .footer__contents {
    flex-direction: column;
    gap: 1.875rem;
  }
}
.footer__content {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .footer__content {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .footer__logo {
    text-align: center;
  }
}
.footer__logo a {
  color: #000;
  font-size: 3rem;
}
@media screen and (max-width: 767px) {
  .footer__logo a {
    font-size: 2.375rem;
  }
}
.footer__menus {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 767px) {
  .footer__menus {
    grid-template-columns: 1fr 1fr;
    gap: 1.875rem;
  }
}
.footer__menu {
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .footer__menu {
    text-align: left;
    font-size: 1rem;
  }
}
.footer__menu a {
  color: #000;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  transition: all 0.3s ease-in-out;
}
.footer__menu a:hover {
  color: #f48654;
}
.footer__sns {
  margin-bottom: 3.125rem;
  display: flex;
  gap: 0.9375rem;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .footer__sns {
    margin-bottom: 1.5625rem;
    justify-content: center;
  }
}
.footer__sns a {
  display: block;
  color: #000;
  font-size: 1.5rem;
}
@media screen and (max-width: 767px) {
  .footer__sns a {
    font-size: 1.125rem;
  }
}
.footer__copy {
  text-align: center;
  color: #999999;
  font-size: 0.75rem;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .footer__copy {
    font-size: 0.625rem;
  }
}

.header.blur {
  filter: blur(8px);
}

.header__logo {
  position: absolute;
  top: 3.4375rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 400;
  z-index: 10;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .header__logo {
    top: 1.875rem;
  }
}
.header__logo a {
  color: #000;
  font-weight: 400;
}

.is-open.hamburger {
  animation: hamburger-opening 1s ease-in-out;
}
.is-open.hamburger .hamburger__line:nth-of-type(1) {
  top: 0.625rem;
  left: -0.125rem;
  transform: rotate(45deg) translateY(0.0625rem);
  width: 3.125rem;
}
@media screen and (max-width: 767px) {
  .is-open.hamburger .hamburger__line:nth-of-type(1) {
    left: 0;
    transform: rotate(45deg);
    width: 1.5625rem;
    top: 0.375rem;
  }
}
.is-open.hamburger .hamburger__line:nth-of-type(2) {
  opacity: 0;
}
.is-open.hamburger .hamburger__line:nth-of-type(3) {
  top: 0.625rem;
  left: 0;
  transform: rotate(-45deg);
  width: 3.125rem;
}
@media screen and (max-width: 767px) {
  .is-open.hamburger .hamburger__line:nth-of-type(3) {
    top: 0.375rem;
    width: 1.5625rem;
  }
}

.is-close.is-moving.hamburger {
  animation: hamburger-closing 1s ease-in-out;
}

.header__hamburger {
  position: fixed;
  top: 2.8125rem;
  right: 4.6875rem;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .header__hamburger {
    top: 2.0625rem;
    right: 1.875rem;
  }
}

.hamburger.is-moving {
  pointer-events: none;
  cursor: pointer;
}

.hamburger {
  width: 3.125rem;
  height: 1.5625rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .hamburger {
    width: 1.5625rem;
    height: 0.8125rem;
  }
}
.hamburger__line {
  display: block;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
}
.hamburger__line:nth-of-type(1) {
  width: 2.8125rem;
  top: 0;
  transition-delay: 0.5s;
}
@media screen and (max-width: 767px) {
  .hamburger__line:nth-of-type(1) {
    width: 1.375rem;
  }
}
.hamburger__line:nth-of-type(2) {
  width: 3.125rem;
  left: 0;
  top: 0.625rem;
  opacity: 1;
  transition-delay: 0.5s;
}
@media screen and (max-width: 767px) {
  .hamburger__line:nth-of-type(2) {
    width: 1.5625rem;
    top: 0.375rem;
  }
}
.hamburger__line:nth-of-type(3) {
  transition-delay: 0.5s;
  width: 1.875rem;
  top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .hamburger__line:nth-of-type(3) {
    width: 0.9375rem;
    top: 0.75rem;
  }
}

.drawer.is-active {
  clip-path: inset(0% 0% 0% 0%);
  visibility: visible;
}

.drawer.is-close {
  clip-path: inset(0% 0% 100% 0%);
  visibility: visible;
}

.drawer {
  visibility: hidden;
  transition: all 0.7s ease-in-out;
  position: fixed;
  clip-path: inset(100% 0% 0% 0%);
  z-index: 5;
  background-image: url("../img/common/drawer-bg.jpg");
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}
.drawer__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .drawer__inner {
    max-width: 37.5rem;
  }
}
.drawer__container {
  width: 100%;
  margin-top: 25%;
  margin-bottom: 5%;
}
.drawer__contents {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 11.25rem;
}
@media screen and (max-width: 767px) {
  .drawer__contents {
    margin-bottom: 1.875rem;
    flex-direction: column;
  }
}
.drawer__content:nth-of-type(1) {
  width: 75%;
}
@media screen and (max-width: 767px) {
  .drawer__content:nth-of-type(1) {
    width: 100%;
  }
}
.drawer__content:nth-of-type(2) {
  width: 10%;
}
@media screen and (max-width: 767px) {
  .drawer__content:nth-of-type(2) {
    width: 100%;
  }
}
.drawer__menus {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
@media screen and (max-width: 767px) {
  .drawer__menus {
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
    margin-bottom: 3.125rem;
  }
}
.drawer__menu a {
  font-size: 2.625rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  color: #000;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .drawer__menu a {
    font-size: 1.375rem;
  }
}
.drawer__menu a:hover {
  color: #f48654;
}
.drawer__sns-icon {
  display: block;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  color: #000;
  color: #000;
  font-family: "Marcellus", serif;
}
.drawer__copy {
  font-size: 0.75rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .drawer__copy {
    font-size: 0.625rem;
  }
}

.drawer .fa-facebook {
  padding-right: 0.625rem;
  padding-bottom: 0.3125rem;
  font-size: 1.5rem;
  vertical-align: middle;
}
.drawer .fa-instagram {
  padding-right: 0.625rem;
  padding-bottom: 0.3125rem;
  font-size: 1.5rem;
  vertical-align: middle;
  margin-left: 0.125rem;
}

.logo {
  font-family: copperplate, serif;
  letter-spacing: 0.16em;
  line-height: 1;
  font-size: 2.125rem;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .logo {
    font-size: 1.25rem;
  }
}

.section-ttl {
  white-space: nowrap;
  line-height: 1;
  overflow: hidden;
  font-size: 5.625rem;
  display: block;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
}
.section-ttl span {
  font-size: 5.625rem;
  display: block;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .section-ttl span {
    font-size: 2.8125rem;
  }
}

.view-more {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
  color: #000;
  padding-bottom: 0.9375rem;
  line-height: 1;
  display: block;
  width: fit-content;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .view-more {
    font-size: 1.125rem;
    padding-bottom: 0.4375rem;
  }
}
.view-more__line {
  position: absolute;
  bottom: 0.0625rem;
  left: 0;
  width: 100%;
  height: 0.0625rem;
  background: #000;
}

.view-more:hover .view-more__line {
  animation: viewMore-hover 0.5s ease-in-out;
}

.wp-content__text {
  display: flex;
  gap: 1.5625rem;
  align-items: center;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .wp-content__text {
    gap: 0.9375rem;
    margin-bottom: 1.5625rem;
    align-items: unset;
    flex-direction: column;
  }
}
.wp-content__date {
  display: flex;
  gap: 0.625rem;
  letter-spacing: 0.05em;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .wp-content__date {
    gap: 0.3125rem;
  }
}
.wp-content__year-month {
  line-height: 1;
  font-size: 1.125rem;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .wp-content__year-month {
    font-size: 1rem;
  }
}
.wp-content__day {
  line-height: 1;
  font-family: "Marcellus", serif;
  font-size: 2.5rem;
}
@media screen and (max-width: 767px) {
  .wp-content__day {
    font-size: 1.625rem;
  }
}
.wp-content__title {
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .wp-content__title {
    font-size: 1.5rem;
  }
}
.wp-content__info {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .wp-content__info {
    margin-bottom: 2.8125rem;
  }
}
.wp-content__cat {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #FFF;
  padding: 0.625rem 0.8125rem;
  line-height: 1;
  background: #000;
}
.wp-content__tags {
  display: flex;
  gap: 0.9375rem;
}
.wp-content__tag {
  display: block;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .wp-content__tag {
    font-size: 1rem;
  }
}
.wp-content__tag::before {
  content: "#";
  color: #aaaaaa;
  padding-right: 0.1875rem;
}
.wp-content__editor {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .wp-content__editor {
    margin-bottom: 2.5rem;
  }
}
.wp-content__prev {
  display: block;
  margin: 0 auto;
  width: fit-content;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  color: #000;
  letter-spacing: 0.1em;
  padding-bottom: 0.9375rem;
  border-bottom: 1px solid #000;
  margin-bottom: 8.75rem;
}
@media screen and (max-width: 767px) {
  .wp-content__prev {
    margin-bottom: 3.125rem;
  }
}
.wp-content__prev span {
  padding-right: 0.625rem;
  font-size: 1.4375rem;
  transform: translateY(0.1875rem);
}
@media screen and (max-width: 767px) {
  .wp-content__prev span {
    font-size: 1.25rem;
    vertical-align: revert;
    padding-right: 0.3125rem;
  }
}

.wp-editor p {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
  margin: 1.25rem 0;
}
.wp-editor h1 {
  font-size: 1.875rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 3.125rem 0 2.1875rem;
  line-height: 1.8;
}
@media screen and (max-width: 767px) {
  .wp-editor h1 {
    font-size: 1.5rem;
  }
}
.wp-editor h1 span {
  font-family: shippori-mincho, sans-serif;
}
.wp-editor h2 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  margin: 3.125rem 0 2.1875rem;
  padding: 0.625rem 0.9375rem;
  background: #f1f1f1;
  line-height: 1.8;
}
@media screen and (max-width: 767px) {
  .wp-editor h2 {
    font-size: 1.25rem;
  }
}
.wp-editor h2 span {
  font-family: shippori-mincho, sans-serif;
}
.wp-editor h3 {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 3.125rem 0 2.1875rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8;
}
@media screen and (max-width: 767px) {
  .wp-editor h3 {
    font-size: 1.25rem;
  }
}
.wp-editor h3 span {
  font-family: shippori-mincho, sans-serif;
}
.wp-editor h4 {
  font-size: 1.25rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #FFF;
  width: fit-content;
  padding: 0.625rem 0.9375rem;
  background: #000;
  margin: 3.125rem 0 2.1875rem;
  line-height: 1.8;
}
@media screen and (max-width: 767px) {
  .wp-editor h4 {
    font-size: 1.125rem;
  }
}
.wp-editor h4 span {
  font-family: shippori-mincho, sans-serif;
}
.wp-editor h5 {
  font-family: shippori-mincho, sans-serif;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 0.625rem;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #000;
  line-height: 1.8;
  margin: 3.125rem 0 2.1875rem;
}
@media screen and (max-width: 767px) {
  .wp-editor h5 {
    font-size: 1.125rem;
  }
}
.wp-editor h5 span {
  font-family: shippori-mincho, sans-serif;
}
.wp-editor h6 {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  margin: 3.125rem 0 2.1875rem;
}
@media screen and (max-width: 767px) {
  .wp-editor h6 {
    font-size: 1.125rem;
  }
}
.wp-editor h6 span {
  font-family: shippori-mincho, sans-serif;
}

#toc_container {
  width: 100% !important;
  font-size: 100% !important;
  border: none !important;
  background: #f1f1f1 !important;
  padding: 42px 57px 30px !important;
}
#toc_container .toc_title {
  font-size: 1.125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: normal;
  text-align: left !important;
  margin-bottom: 35px;
}
#toc_container ul li a {
  font-size: 1rem;
  color: #000;
  letter-spacing: 0.05em;
  line-height: 1.4em;
  display: block;
  margin-bottom: 10px;
}
#toc_container ul li a:hover {
  text-decoration: none !important;
}
#toc_container ul li a span {
  color: #aaaaaa;
}
#toc_container ul ul {
  margin-left: 15px !important;
}
@media screen and (max-width: 767px) {
  #toc_container {
    padding: 25px 15px 20px !important;
  }
  #toc_container .toc_title {
    font-size: 0.875rem;
    margin-bottom: 25px;
  }
  #toc_container ul li a {
    font-size: 0.75rem;
  }
}

.blog .page-top {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .blog .page-top {
    margin-bottom: 1.875rem;
  }
}
.blog .page-top__ttl {
  line-height: 1;
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .blog .page-top__ttl {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .blog .page-top__ttl {
    line-height: auto;
    margin-bottom: 1.25rem;
  }
}
.blog .page-top__inner {
  position: relative;
}
.blog .page-top__inner::after {
  position: absolute;
  content: "";
  display: block;
  max-width: 75rem;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 25px;
  background: #eeeeee;
}
@media screen and (max-width: 767px) {
  .blog .page-top__inner::after {
    max-width: calc(100% - 2.5rem);
    left: 1.25rem;
  }
}
.blog .page-top__ttl .katsuki {
  font-size: 3rem;
  font-family: copperplate, serif;
  font-weight: 300;
  letter-spacing: 0.12em;
  vertical-align: middle;
  display: inline-block;
  padding-right: 0.625rem;
}
@media screen and (max-width: 767px) {
  .blog .page-top__ttl .katsuki {
    font-size: 1.75rem;
    letter-spacing: 0.1em;
  }
}

.pb-body {
  max-width: 78.125rem;
  padding: 0 25px;
  display: flex;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pb-body {
    max-width: 37.5rem;
    display: block;
    padding: 0 20px;
  }
}
.pb-body__blog {
  padding-right: 5rem;
  width: 78%;
}
@media screen and (max-width: 767px) {
  .pb-body__blog {
    width: 100%;
    padding-right: unset;
  }
}
.pb-body__pages {
  margin: 0 auto;
  padding-bottom: 8.75rem;
}
@media screen and (max-width: 767px) {
  .pb-body__pages {
    padding-bottom: 4.375rem;
  }
}

.pb-blog__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem 3.75rem;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .pb-blog__cards {
    grid-template-columns: 1fr;
    gap: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.pb-blog .card1__title {
  font-feature-settings: "palt";
  letter-spacing: 0.03em;
}

.concept picture,
.concept img {
  height: 100%;
  object-fit: cover;
}
.concept .page-top__ttl {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .concept .page-top__ttl {
    margin-bottom: 0.625rem;
  }
}

.pc-read {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .pc-read__inner {
    max-width: 37.5rem;
    padding: 0 20px;
    margin: 0 auto;
  }
}
.pc-read__container {
  padding: 8.75rem 0 11.25rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .pc-read__container {
    padding: 3.75rem 0 5rem;
  }
}
.pc-read__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .pc-read__content {
    align-items: unset;
    justify-content: unset;
    flex-direction: column-reverse;
    gap: 1.875rem;
  }
}
.pc-read__text {
  margin-top: 2.8125rem;
  width: 50%;
  padding-right: 6.25rem;
  max-width: 37.5rem;
}
@media screen and (max-width: 767px) {
  .pc-read__text {
    margin-top: unset;
    padding-right: unset;
    width: 100%;
    max-width: unset;
  }
}
.pc-read__ttl span {
  font-family: shippori-mincho, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  display: block;
  line-height: 1.5em;
  margin-bottom: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .pc-read__ttl span {
    font-size: 1.125rem;
    margin-bottom: unset;
  }
}
.pc-read__ttl {
  font-family: shippori-mincho, sans-serif;
  font-size: 2.625rem;
  font-weight: 500;
  line-height: 1.5em;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pc-read__ttl {
    margin-bottom: 1.25rem;
    font-size: 1.625rem;
  }
}
.pc-read__text1 {
  font-size: 1.125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 2.2222222222;
}
@media screen and (max-width: 767px) {
  .pc-read__text1 {
    font-size: 0.875rem;
    margin-bottom: 1.875rem;
  }
}
.pc-read__img {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pc-read__img {
    width: 100%;
  }
}
.pc-read__content:nth-of-type(2) {
  margin: -11.875rem auto 0;
  max-width: 78.125rem;
  padding: 0 25px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .pc-read__content:nth-of-type(2) {
    max-width: unset;
    padding: unset;
    justify-content: unset;
    flex-direction: column;
    align-items: unset;
    margin-top: unset;
  }
}
.pc-read__img2 {
  padding-right: 10.625rem;
  max-width: 37.5rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pc-read__img2 {
    padding-right: unset;
    width: 60%;
    max-width: unset;
  }
}
.pc-read__text2 {
  margin-top: 6.875rem;
  font-size: 1.125rem;
  width: 50%;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  line-height: 2.2222222222;
}
@media screen and (max-width: 767px) {
  .pc-read__text2 {
    font-size: 0.875rem;
    margin-bottom: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  .pc-read__text2 {
    font-size: 0.875rem;
    width: 100%;
    margin-top: unset;
  }
}
.pc-read__bg-text {
  position: absolute;
  width: 100%;
  left: 15%;
  bottom: 2.8125rem;
  font-family: "Marcellus", serif;
  font-size: 10rem;
  font-weight: 400;
  background: linear-gradient(90deg, #f8f8f8, #fdefe3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
  .pc-read__bg-text {
    width: fit-content;
    width: 130%;
    left: 70%;
    bottom: 1.875rem;
  }
}

.pc-style {
  overflow: hidden;
  position: relative;
  background-image: url("../img/concept/style-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.pc-style__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pc-style__inner {
    padding: 0 20px;
    max-width: 37.5rem;
  }
}
.pc-style__container {
  padding: 6.875rem 0 15rem;
}
@media screen and (max-width: 767px) {
  .pc-style__container {
    padding: 3.125rem 0 4.375rem;
  }
}
.pc-style__ttl {
  text-align: center;
  margin-bottom: 1.25rem;
  font-family: shippori-mincho, sans-serif;
  font-size: 2.625rem;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .pc-style__ttl {
    font-size: 1.625rem;
    margin-bottom: 1.25rem;
    z-index: 1;
    position: relative;
  }
}
.pc-style__read {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.1428571429;
  font-weight: 200;
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pc-style__read {
    margin-bottom: 1.875rem;
    text-align: left;
    z-index: 1;
    position: relative;
  }
}
.pc-style__contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6.875rem 4.0625rem;
}
@media screen and (max-width: 767px) {
  .pc-style__contents {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}
.pc-style__content:nth-of-type(2n) {
  transform: translateY(5.625rem);
}
@media screen and (max-width: 767px) {
  .pc-style__content:nth-of-type(2n) {
    transform: translateY(0);
  }
}
.pc-style__content {
  position: relative;
}
.pc-style__c-bgttl {
  color: #FFF;
  opacity: 0.3;
  position: relative;
  z-index: 0;
  font-size: 7.5rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 0.7;
  margin-left: -0.5625rem;
}
@media screen and (max-width: 767px) {
  .pc-style__c-bgttl {
    font-size: 3.75rem;
    margin-left: -0.25rem;
  }
}
.pc-style__c-img {
  margin-bottom: 1.25rem;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .pc-style__c-img {
    margin-bottom: 0.9375rem;
  }
}
.pc-style__c-ttl {
  margin-bottom: 0.3125rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pc-style__c-ttl {
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
  }
}
.pc-style__c-text {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.03em;
  line-height: 1.7142857143;
  position: relative;
  z-index: 3;
}
.pc-style__c-bgtext {
  position: absolute;
  top: 6rem;
  left: -0.5rem;
  transform: rotate(90deg);
  transform-origin: top left;
  font-size: 0.75rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pc-style__c-bgtext {
    font-size: 0.625rem;
    top: 3rem;
    left: -0.1875rem;
  }
}
.pc-style__bg-text {
  font-size: 9.375rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #FFF;
  position: absolute;
  top: -0.75rem;
  left: 100%;
  width: 110%;
  line-height: 0.8;
  transform: rotate(90deg);
  transform-origin: top left;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .pc-style__bg-text {
    font-size: 3.75rem;
    top: -0.1875rem;
    width: 200%;
  }
}

.pc-approach__container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 767px) {
  .pc-approach__container {
    flex-direction: column;
  }
}
.pc-approach__left {
  width: 50%;
  height: 100%;
  padding-right: 8.125rem;
  position: sticky;
  top: 0;
}
@media screen and (max-width: 767px) {
  .pc-approach__left {
    width: 100%;
    padding-right: unset;
    height: 0;
    z-index: -1;
    opacity: 0.15;
  }
}
.pc-approach__fixedimg {
  height: 100vh;
}
@media screen and (max-width: 767px) {
  .pc-approach__fixedimg {
    height: 100vh;
  }
}
.pc-approach__main {
  margin: 5.625rem 0 3.125rem;
  max-width: 38.75rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pc-approach__main {
    max-width: 37.5rem;
    margin: 2.5rem auto 0;
    padding: 0 20px;
    width: 100%;
  }
}
.pc-approach__contents {
  padding-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pc-approach__contents {
    padding-left: unset;
  }
}
.pc-approach__ttl {
  margin-bottom: 0.9375rem;
  font-size: 5.625rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .pc-approach__ttl {
    font-size: 2.625rem;
    margin-bottom: 0.625rem;
  }
}
.pc-approach__read {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.6666666667;
}
@media screen and (max-width: 767px) {
  .pc-approach__read {
    margin-bottom: 0.9375rem;
    font-size: 1.25rem;
  }
}
.pc-approach__read2 {
  margin-bottom: 6.875rem;
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
}
@media screen and (max-width: 767px) {
  .pc-approach__read2 {
    font-size: 0.75rem;
    margin-bottom: 3.75rem;
  }
}
.pc-approach__content {
  margin-bottom: 4.6875rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .pc-approach__content {
    margin-bottom: 3.75rem;
  }
}
.pc-approach__c-step {
  position: absolute;
  top: -0.9375rem;
  left: -0.9375rem;
  aspect-ratio: 1;
  width: 6.25rem;
  text-align: center;
  font-size: 1.125rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #FFF;
  line-height: 6.25rem;
  border-radius: 50%;
  background: linear-gradient(#ffc4bf, #ceece9);
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .pc-approach__c-step {
    font-size: 0.875rem;
    width: 5rem;
    line-height: 5rem;
  }
}
.pc-approach__c-img {
  aspect-ratio: 580/400;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pc-approach__c-img {
    margin-bottom: 1rem;
  }
}
.pc-approach__c-ttl span {
  font-size: 1.625rem;
  font-weight: 500;
  transform: translateY(0.25rem);
  display: inline-block;
  padding-right: 0.9375rem;
  letter-spacing: 0.1em;
  font-family: shippori-mincho, sans-serif;
  background: linear-gradient(90deg, #ffc4bf, #ceece9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
  .pc-approach__c-ttl span {
    transform: unset;
    padding-right: 0.625rem;
    width: fit-content;
    display: block;
    font-size: 1.375rem;
    margin-bottom: 0.625rem;
  }
}
.pc-approach__c-ttl {
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3em;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pc-approach__c-ttl {
    font-size: 1rem;
    margin-bottom: 0.9375rem;
    line-height: 1.4em;
  }
}
.pc-approach__c-text {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 1.7142857143;
}

.gallery .page-top {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .gallery .page-top {
    margin-bottom: 1.875rem;
  }
}
.gallery .page-top__inner {
  position: relative;
}
.gallery .page-top__inner::after {
  position: absolute;
  content: "";
  display: block;
  max-width: 78.125rem;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 25px;
  background: #eeeeee;
}
@media screen and (max-width: 767px) {
  .gallery .page-top__inner::after {
    max-width: calc(100% - 2.5rem);
    left: 1.25rem;
  }
}
.gallery .fancybox__backdrop {
  background: rgba(0, 0, 0, 0.4);
}
.gallery .fancybox__toolbar {
  background: none;
}

.gallery .page-read {
  margin-bottom: 7.8125rem;
}
@media screen and (max-width: 767px) {
  .gallery .page-read {
    margin-bottom: 3.75rem;
  }
}
.gallery .page-read__img {
  width: 38%;
  aspect-ratio: 640/480;
}
@media screen and (max-width: 767px) {
  .gallery .page-read__img {
    width: 100%;
  }
}
.gallery .page-read__read {
  padding-right: 7.5rem;
  margin-top: -9.375rem;
  font-size: 2.25rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 767px) {
  .gallery .page-read__read {
    padding-right: unset;
    margin-top: unset;
    font-size: 1.125rem;
  }
}
.gallery .page-read__bg-text {
  bottom: 0;
  left: 14%;
}
@media screen and (max-width: 767px) {
  .gallery .page-read__bg-text {
    bottom: unset;
    top: -2.5rem;
    z-index: -1;
    left: 70%;
  }
}

.pg-select {
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pg-select {
    margin-bottom: 3.125rem;
  }
}
.pg-select__lists {
  width: fit-content;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pg-select__lists {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25rem;
  }
}
.pg-select__list a {
  width: 10rem;
  aspect-ratio: 1;
  text-align: center;
  line-height: 10rem;
  font-size: 1.5rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  border-radius: 50%;
  display: block;
  color: #aaaaaa;
  border: 1px solid #f1f1f1;
}
@media screen and (max-width: 767px) {
  .pg-select__list a {
    width: 6.25rem;
    line-height: 6.25rem;
    font-size: 1.125rem;
  }
}
.pg-select__list.is-active a {
  background: #000;
  color: #FFF;
}

.pg-gallery__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pg-gallery__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pg-gallery__images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
  margin-bottom: 4.6875rem;
}
@media screen and (max-width: 767px) {
  .pg-gallery__images {
    grid-template-columns: 1fr 1fr;
    gap: 0.9375rem;
    margin-bottom: 1.875rem;
  }
}
.pg-gallery__image {
  padding-top: 148.5%;
  position: relative;
}
.pg-gallery__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.pg-gallery__image .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.4s opacity;
}
.pg-gallery__image .overlay:hover {
  opacity: 1;
}
.pg-gallery__image .overlay ul li {
  display: flex;
  margin-bottom: 15px;
}
.pg-gallery__image .overlay ul li:last-child {
  margin-bottom: 0;
}
.pg-gallery__image .overlay ul li .txt01 {
  font-size: 1.125rem;
  color: #fff;
  font-family: "Marcellus", serif;
  width: 4.375rem;
  text-align: right;
  margin-right: 4.375rem;
  position: relative;
  letter-spacing: 0.05em;
}
.pg-gallery__image .overlay ul li .txt01:after {
  content: "";
  height: 1px;
  width: 2.5rem;
  background: #fff;
  position: absolute;
  top: 50%;
  right: -0.9375rem;
  transform: translateX(100%);
}
.pg-gallery__image .overlay ul li .txt02 {
  font-size: 1.125rem;
  color: #fff;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.05em;
}
.pg-gallery__pages {
  margin: 0 auto 8.75rem;
}
@media screen and (max-width: 767px) {
  .pg-gallery__pages {
    margin-bottom: 4.375rem;
  }
}

.menu .page-top__ttl {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .menu .page-top__ttl {
    margin-bottom: 0.75rem;
  }
}
@media screen and (max-width: 767px) {
  .menu .page-top__content {
    gap: unset;
  }
}

.pm-menu__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pm-menu__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pm-menu__container {
  padding: 8.75rem 0 6.25rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__container {
    padding: 3.75rem 0;
  }
}
.pm-menu__ttl {
  font-size: 3rem;
  font-family: "Marcellus", serif;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .pm-menu__ttl {
    font-size: 1.875rem;
    margin-bottom: unset;
  }
}
.pm-menu__ttl-at {
  font-size: 0.875rem;
  font-family: "noto-sans-cjk-jp", sans-serif;
  font-weight: 200;
  display: block;
  text-align: right;
  margin-bottom: 5.625rem;
  margin-top: -2.5rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__ttl-at {
    text-align: center;
    margin-top: unset;
    margin-bottom: 2.1875rem;
    font-size: 0.75rem;
  }
}
.pm-menu__c-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.375rem;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 767px) {
  .pm-menu__c-top {
    padding-bottom: 0.3125rem;
    margin-bottom: 1.875rem;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }
}
.pm-menu__ttl2 {
  font-size: 1.75rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .pm-menu__ttl2 {
    font-size: 1.375rem;
  }
}
.pm-menu__ttl2 span {
  font-size: 1.25rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
  padding-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__ttl2 span {
    font-size: 1.125rem;
  }
}
.pm-menu__read {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .pm-menu__read {
    font-size: 0.75rem;
    text-align: right;
  }
}
.pm-menu__content {
  margin-bottom: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__content {
    margin-bottom: 2.5rem;
  }
}
.pm-menu__lists {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem 0;
  margin-bottom: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__lists {
    flex-direction: column;
    gap: 1.5625rem;
  }
}
.pm-menu__list {
  width: 50%;
  padding: 0 1.5625rem;
  display: flex;
  gap: 0.9375rem;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .pm-menu__list {
    width: 100%;
    padding: unset;
  }
}
.pm-menu__l-name {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pm-menu__l-name {
    font-size: 0.875rem;
  }
}
.pm-menu__l-name span {
  margin-top: 0.3125rem;
  display: block;
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.5;
  color: #646464;
}
@media screen and (max-width: 767px) {
  .pm-menu__l-name span {
    font-size: 0.6875rem;
    font-feature-settings: "palt";
    margin-top: unset;
  }
}
.pm-menu__l-price {
  font-size: 1.125rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .pm-menu__l-price {
    font-size: 0.875rem;
    white-space: nowrap;
    width: fit-content;
  }
}
.pm-menu__ttl3 {
  font-size: 1.5rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  padding: 0.625rem 0;
  text-align: center;
  background: #fafafa;
  line-height: 1;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__ttl3 {
    margin-bottom: 0.625rem;
    font-size: 1.25rem;
  }
}
.pm-menu__attention {
  width: fit-content;
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  color: #646464;
  line-height: 1.5;
  margin-bottom: 2.0625rem;
}
@media screen and (max-width: 767px) {
  .pm-menu__attention {
    margin-bottom: 1.4375rem;
    font-size: 0.75rem;
  }
}

.news .page-top {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .news .page-top {
    margin-bottom: 1.875rem;
  }
}
.news .page-top__ttl {
  line-height: 1;
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .news .page-top__ttl {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .news .page-top__ttl {
    line-height: auto;
    margin-bottom: 2.5rem !important;
  }
}
.news .page-top__inner {
  position: relative;
}
.news .page-top__inner::after {
  position: absolute;
  content: "";
  display: block;
  max-width: 75rem;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 25px;
  background: #eeeeee;
}
@media screen and (max-width: 767px) {
  .news .page-top__inner::after {
    max-width: calc(100% - 2.5rem);
    left: 1.25rem;
  }
}

.pn-body {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
  display: flex;
}
@media screen and (max-width: 767px) {
  .pn-body {
    max-width: 37.5rem;
    display: block;
    padding: 0 20px;
  }
}
.pn-body__news {
  padding-right: 5rem;
  width: 78%;
}
@media screen and (max-width: 767px) {
  .pn-body__news {
    width: 100%;
    padding-right: unset;
  }
}
.pn-body__pages {
  margin: 0 auto;
  padding-bottom: 8.75rem;
}
@media screen and (max-width: 767px) {
  .pn-body__pages {
    padding-bottom: 4.375rem;
  }
}

.pn-news__cards {
  margin-bottom: 4.6875rem;
}
.pn-news__card {
  display: flex;
  gap: 2.5rem;
  align-items: flex-start;
  width: 100%;
  max-width: unset;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #eeeeee;
}
@media screen and (max-width: 767px) {
  .pn-news__card {
    flex-direction: column;
    gap: 1.25rem;
  }
}
.pn-news .card1__img {
  aspect-ratio: 200/135;
  max-width: 12.5rem;
  margin-bottom: unset;
}
@media screen and (max-width: 767px) {
  .pn-news .card1__img {
    max-width: unset;
    width: 100%;
  }
}
.pn-news .card1__title {
  font-size: 1.75rem;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .pn-news .card1__title {
    font-size: 1.25rem;
  }
}
.pn-news .card1__text {
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .pn-news .card1__year-month {
    font-size: 0.6875rem;
  }
}
.pn-news .card1__info {
  margin-top: 1.5625rem;
  gap: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pn-news .card1__info {
    margin-top: 0.9375rem;
    gap: 0.9375rem;
  }
}
.pn-news .card1__year-month {
  font-size: 1rem;
}
.pn-news .card1__day {
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  .pn-news .card1__day {
    font-size: 1.5rem;
  }
}

.page-top__inner {
  max-width: 78.125rem;
  padding: 0 25px 5.625rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .page-top__inner {
    max-width: 37.5rem;
    padding: 0 20px 3.125rem;
  }
}
.page-top__container {
  padding-top: 11.5625rem;
}
@media screen and (max-width: 767px) {
  .page-top__container {
    padding-top: 6.25rem;
  }
}
.page-top__ttl {
  font-size: 5.625rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  margin-bottom: 0.3125rem;
  line-height: 1.2em;
}
@media screen and (max-width: 1024px) {
  .page-top__ttl {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .page-top__ttl {
    font-size: 2.625rem;
    margin-bottom: 0.625rem;
  }
}
.page-top__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .page-top__content {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }
}
.page-top__read {
  font-size: 1rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
}
@media screen and (max-width: 767px) {
  .page-top__read {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
}
.page-top__breads {
  display: flex;
  gap: 0.625rem;
}
@media screen and (max-width: 767px) {
  .page-top__breads {
    flex-wrap: wrap;
  }
}
.page-top__bread {
  font-size: 0.75rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  color: #acacac;
  letter-spacing: 0.1em;
}
.page-top__bread:not(:last-of-type) {
  padding: 0 0.625rem;
  margin: 0 1.25rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-top__bread:not(:last-of-type) {
    padding: 0 0.625rem 0 0;
    margin: 0 1rem 0 0;
  }
}
.page-top__bread:not(:last-of-type)::after {
  position: absolute;
  content: "";
  display: block;
  width: 1.5rem;
  height: 1px;
  background: #dddddd;
  top: 50%;
  left: 100%;
  transform: translate(0.5rem, -50%);
}
@media screen and (max-width: 767px) {
  .page-top__bread:not(:last-of-type)::after {
    width: 1.25rem;
    transform: translate(0rem, -50%);
  }
}
.page-top__bread:last-of-type {
  pointer-events: none;
  padding-left: 0.625rem;
  margin-left: 1.25rem;
  color: #000;
}
@media screen and (max-width: 767px) {
  .page-top__bread:last-of-type {
    padding-left: 0.5rem;
    margin-left: 0.3125rem;
  }
}
@media screen and (max-width: 767px) {
  .page-top__bread:nth-of-type(n+3) {
    padding-left: 0;
    margin-left: 0;
    display: block;
    width: 100%;
  }
}

.page-top--flex .page-top__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .page-top--flex .page-top__container {
    display: block;
  }
}
.page-top--flex .page-top__ttl {
  line-height: 1;
  margin-bottom: unset;
}
@media screen and (max-width: 767px) {
  .page-top--flex .page-top__ttl {
    line-height: unset;
  }
}

.page-mv {
  aspect-ratio: 1680/660;
  position: relative;
}
@media screen and (max-width: 767px) {
  .page-mv {
    aspect-ratio: 3/2;
  }
}
.page-mv__img {
  height: 100%;
  width: 100%;
}
.page-mv__img picture, .page-mv__img img {
  object-fit: cover;
  height: 100%;
}

.page-read {
  position: relative;
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .page-read {
    margin-bottom: 3.125rem;
  }
}
.page-read__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 25px;
}
@media screen and (max-width: 767px) {
  .page-read__container {
    flex-direction: column;
    max-width: 37.5rem;
    padding: 0 20px;
    margin: 0 auto;
    justify-content: unset;
    align-items: unset;
    gap: 1.25rem;
  }
}
.page-read__read {
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.875;
  padding-right: 4.375rem;
}
@media screen and (max-width: 767px) {
  .page-read__read {
    font-size: 1.125rem;
    padding-right: unset;
    letter-spacing: 0.05em;
  }
}
.page-read__bg-text {
  position: absolute;
  line-height: 1.3;
  background: linear-gradient(90deg, #f8f8f8, #fdefe3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
  .page-read__bg-text {
    white-space: nowrap;
  }
}

.pagination {
  width: fit-content;
  display: flex;
  gap: 1.8125rem;
}
@media screen and (max-width: 767px) {
  .pagination {
    gap: 0.625rem;
  }
}
.pagination .page-numbers {
  display: block;
  padding: 0.1875rem;
  font-size: 0.875rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 700;
  line-height: 1;
  color: #000;
}
@media screen and (max-width: 767px) {
  .pagination .page-numbers {
    padding: 0.375rem 0.375rem 0.1875rem;
  }
}
.pagination .current {
  border-bottom: 1px solid #000;
}
.pagination span {
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .pagination span {
    vertical-align: unset;
  }
}

.page-side {
  width: 22%;
}
@media screen and (max-width: 767px) {
  .page-side {
    width: 100%;
  }
}
.page-side__content {
  margin-bottom: 4.375rem;
}
@media screen and (max-width: 767px) {
  .page-side__content {
    margin-bottom: 3.125rem;
  }
}
.page-side__name {
  font-size: 1.5rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  padding: 0 0 0.9375rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #eeeeee;
}
.page-side__item a {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #000;
  padding: 0.625rem 0;
}
.page-side__tags {
  display: flex;
  gap: 0.9375rem;
  flex-wrap: wrap;
}
.page-side__tag a {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #000;
  position: relative;
}
.page-side__tag::before {
  content: "#";
  padding-right: 0.1875rem;
  color: #aaaaaa;
}

.js-sidebar .page-side__name {
  position: relative;
  cursor: pointer;
}
.js-sidebar .page-side__name::after {
  position: absolute;
  content: "";
  display: block;
  top: 40%;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
  width: 0.75rem;
  height: 0.4375rem;
  background-image: url("../img/common/side-arrow.png");
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.4s all ease-in-out;
}
.js-sidebar .page-side__items {
  clip-path: inset(0% 0% 100% 0%);
  transition: 0.4s all ease-in-out;
}
.js-sidebar .page-side__item a {
  line-height: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s all ease-in-out;
}
.js-sidebar .page-side__tags {
  clip-path: inset(0% 0% 100% 0%);
  transition: 0.4s all ease-in-out;
  opacity: 0;
}
.js-sidebar .page-side__tag {
  line-height: 0;
  padding: 0;
  transition: 0.4s all ease-in-out;
  overflow: hidden;
}

.js-sidebar.is-open .page-side__items {
  clip-path: inset(0% 0% 0% 0%);
}
.js-sidebar.is-open .page-side__item a {
  line-height: 1.2;
  opacity: 1;
  overflow: hidden;
  padding: 0.625rem 0;
}
.js-sidebar.is-open .page-side__name::after {
  transform: translateY(-50%) rotate(0deg);
}
.js-sidebar.is-open .page-side__tags {
  clip-path: inset(0% 0% 0% 0%);
  opacity: 1;
}
.js-sidebar.is-open .page-side__tag {
  line-height: 1.2;
}

.sec-404 {
  padding: 9.375rem 0 6.25rem;
}
.sec-404 .ttl {
  font-size: 8.125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 35px;
}
.sec-404 .ttl small {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  display: block;
  line-height: 1em;
  letter-spacing: 0.05em;
}
.sec-404 .txt {
  font-size: 0.875rem;
  line-height: 1.6em;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .sec-404 {
    padding: 6.25rem 0 3.75rem;
  }
  .sec-404 .ttl {
    font-size: 6.875rem;
    margin-bottom: 35px;
  }
  .sec-404 .ttl small {
    font-size: 1.25rem;
  }
  .sec-404 .txt {
    font-size: 0.875rem;
  }
}

.product picture,
.product img {
  height: 100%;
  object-fit: cover;
}

.product .page-top__container {
  padding-top: 14.375rem;
}
@media screen and (max-width: 767px) {
  .product .page-top__container {
    padding-top: 6.25rem;
  }
}
@media screen and (max-width: 767px) {
  .product .page-top__ttl {
    margin-bottom: unset;
  }
}
.product .page-top__breads {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .product .page-top__breads {
    margin-top: 0.625rem;
  }
}

.pp-read {
  background-image: url("../img/concept/style-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.pp-read__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pp-read__inner {
    padding: 0 20px;
    max-width: 37.5rem;
  }
}
.pp-read__container {
  padding: 6.875rem 0;
}
@media screen and (max-width: 767px) {
  .pp-read__container {
    padding: 3.125rem 0;
  }
}
.pp-read__ttl {
  text-align: center;
  margin-bottom: 1.25rem;
  font-family: shippori-mincho, sans-serif;
  font-size: 2.625rem;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .pp-read__ttl {
    font-size: 1.625rem;
    margin-bottom: 1.25rem;
    z-index: 1;
    position: relative;
  }
}
.pp-read__read {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2.1428571429;
  font-weight: 200;
}
@media screen and (max-width: 767px) {
  .pp-read__read {
    text-align: left;
    z-index: 1;
    position: relative;
  }
}

.pp-select {
  margin: 10rem 0;
}
@media screen and (max-width: 767px) {
  .pp-select {
    margin: 4.375rem 0;
  }
}
.pp-select__lists {
  display: flex;
  gap: 3.125rem;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .pp-select__lists {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    padding: 0 20px;
    max-width: 37.5rem;
    margin: 0 auto;
  }
}
.pp-select__list {
  width: fit-content;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 767px) {
  .pp-select__list {
    width: unset;
    text-align: center;
    white-space: nowrap;
  }
}
.pp-select__list a {
  display: block;
  padding-bottom: 0.625rem;
  line-height: 1;
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pp-select__list a {
    font-size: 1.125rem;
    padding-bottom: 0.4375rem;
  }
}

.pp-product {
  margin-bottom: 9.375rem;
}
@media screen and (max-width: 767px) {
  .pp-product {
    margin-bottom: 3.75rem;
  }
}
.pp-product__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pp-product__inner {
    padding: 0 20px;
    max-width: 37.5rem;
  }
}
.pp-product__content {
  margin-bottom: 12.5rem;
}
@media screen and (max-width: 767px) {
  .pp-product__content {
    margin-bottom: 6.25rem;
  }
}
.pp-product__content:last-of-type {
  margin-bottom: unset;
}
@media screen and (max-width: 767px) {
  .pp-product__content:last-of-type {
    margin: unset;
  }
}
.pp-product__c-top {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .pp-product__c-top {
    flex-direction: column;
    margin-bottom: 1.875rem;
  }
}
.pp-product__c-texts {
  width: 42%;
  padding: 5.625rem 2.5rem 6.25rem;
  background-image: url("../img/product/product-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .pp-product__c-texts {
    width: 100%;
    margin: 0 auto;
    padding: 3.125rem 0.9375rem;
  }
}
.pp-product__c-ttl {
  margin-bottom: 1.25rem;
  font-size: 3rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .pp-product__c-ttl {
    margin-bottom: 0.9375rem;
    font-size: 1.5rem;
  }
}
.pp-product__c-read {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pp-product__c-read {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.pp-product__c-text {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.7857142857;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pp-product__c-text {
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
  }
}
.pp-product__c-link {
  display: block;
  font-size: 1rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #000;
  border-bottom: 1px solid #000;
  width: fit-content;
  margin: 0 auto;
  padding: 0.5rem 0;
}
@media screen and (max-width: 767px) {
  .pp-product__c-link {
    font-size: 0.875rem;
  }
}
.pp-product__c-link i {
  padding-left: 0.625rem;
}
.pp-product__c-img {
  width: 58%;
  margin-top: 5rem;
  aspect-ratio: 70/48;
}
@media screen and (max-width: 767px) {
  .pp-product__c-img {
    margin-top: unset;
    width: 100%;
  }
}
.pp-product__c-bottom {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pp-product__c-bottom {
    grid-template-columns: 1fr;
    gap: 2.1875rem;
  }
}
.pp-product__ci-img {
  aspect-ratio: 1;
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pp-product__ci-img {
    margin-bottom: 0.625rem;
  }
}
.pp-product__ci-name {
  font-family: shippori-mincho, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  font-feature-settings: "palt";
  text-align: center;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pp-product__ci-name {
    font-size: 1.25rem;
  }
}

.recruit picture,
.recruit img {
  height: 100%;
  object-fit: cover;
}

.noto {
  font-size: 0.875rem;
  line-height: 2.1428571429;
  font-weight: 200;
}

.noto span {
  color: #ea9d93;
}

.mission-top {
  background-image: url("../img/recruit/mission1-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .mission-top {
    background-position: 75% 50%;
  }
}
.mission-top__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .mission-top__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.mission-top__container {
  padding: 6.875rem 0 10.625rem;
  color: #FFF;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .mission-top__container {
    padding: 3.4375rem 0 5rem;
  }
}
.mission-top__num {
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .mission-top__num {
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }
}
.mission-top__ttl {
  margin-bottom: 0.625rem;
  font-size: 3.875rem;
  font-weight: 500;
  color: #FFF;
  font-family: shippori-mincho, sans-serif;
}
@media screen and (max-width: 767px) {
  .mission-top__ttl {
    margin-bottom: 0.3125rem;
    font-size: 2.25rem;
  }
}
.mission-top__read {
  padding-bottom: 1.875rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #FFF;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .mission-top__read {
    font-size: 1.125rem;
    padding-bottom: 0.9375rem;
    margin-bottom: 1.5625rem;
  }
}
.mission-top__text {
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 2.2;
}
@media screen and (max-width: 767px) {
  .mission-top__text {
    text-align: left;
    line-height: 1.8;
  }
}

.pr-ttl1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.9375rem;
  padding-bottom: 1.25rem;
  border-bottom: 2px solid #000;
}
@media screen and (max-width: 767px) {
  .pr-ttl1 {
    flex-direction: column;
    padding-bottom: 0.625rem;
    align-items: unset;
  }
}
.pr-ttl1__ttl {
  font-size: 5rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pr-ttl1__ttl {
    font-size: 2.375rem;
    letter-spacing: unset;
  }
}
.pr-ttl1__read {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .pr-ttl1__read {
    text-align: center;
  }
}

.pr-ttl2 {
  background-size: cover;
  background-repeat: no-repeat;
}
.pr-ttl2__content {
  padding: 10rem 0;
}
@media screen and (max-width: 767px) {
  .pr-ttl2__content {
    padding: 5rem 0;
  }
}
.pr-ttl2__ttl {
  text-align: center;
  font-size: 2rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  margin-bottom: 0.3125rem;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .pr-ttl2__ttl {
    margin-bottom: 0.3125rem;
    font-size: 1.5rem;
  }
}
.pr-ttl2__subttl {
  text-align: center;
  font-size: 0.875rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .pr-ttl2__subttl {
    font-size: 0.75rem;
  }
}

.pr-ttl3 {
  padding-bottom: 1.25rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  font-family: shippori-mincho, sans-serif;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 767px) {
  .pr-ttl3 {
    font-size: 1.5rem;
    padding-bottom: 0.625rem;
  }
}

.pr-info__item {
  display: flex;
  align-items: flex-start;
  gap: 0.9375rem;
  padding: 1.125rem 0;
}
@media screen and (max-width: 767px) {
  .pr-info__item {
    align-items: unset;
    flex-direction: column;
    padding: 0.9375rem 0;
    gap: 0.625rem;
  }
}
.pr-info__label {
  width: 10%;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .pr-info__label {
    width: 100%;
    font-size: 0.9375rem;
  }
}
.pr-info__data {
  width: 85%;
}
@media screen and (max-width: 767px) {
  .pr-info__data {
    width: 100%;
  }
}
.pr-info .noto {
  line-height: 1.7;
}
@media screen and (max-width: 767px) {
  .pr-info .noto {
    font-size: 0.8125rem;
  }
}
.pr-info b {
  font-weight: 700;
}

.recruit .page-top__ttl {
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .recruit .page-top__ttl {
    margin-bottom: 0.625rem;
  }
}
.recruit .page-top__inner {
  padding: 0 1.5625rem 6.5625rem;
}
@media screen and (max-width: 767px) {
  .recruit .page-top__inner {
    padding: 0 1.25rem 3.125rem;
  }
}
.recruit .page-top__read {
  font-feature-settings: "palt";
}
.recruit .page-top__container {
  padding-top: 12.8125rem;
}
@media screen and (max-width: 767px) {
  .recruit .page-top__container {
    padding-top: 6.25rem;
  }
}
.recruit .page-top__content {
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .recruit .page-top__content {
    align-items: unset;
  }
}
@media screen and (max-width: 767px) {
  .recruit .page-mv {
    aspect-ratio: 50/22;
  }
}

.pr-read {
  background-image: url("../img/recruit/mission1-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 center;
}
.pr-read__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pr-read__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-read__container {
  padding: 10.3125rem 0;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .pr-read__container {
    padding: 3.4375rem 0 5rem;
    flex-direction: column;
    gap: 1.25rem;
  }
}
.pr-read__left {
  width: 52%;
  padding-right: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .pr-read__left {
    padding-right: unset;
    width: 100%;
  }
}
.pr-read__ttl {
  margin-bottom: 1.5625rem;
  font-size: 2.625rem;
  line-height: 1.4285714286;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 767px) {
  .pr-read__ttl {
    font-size: 1.5625rem;
    margin-bottom: 0.9375rem;
  }
}
.pr-read__read {
  font-size: 1.5rem;
  line-height: 1.6666666667;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-read__read {
    font-size: 1.125rem;
  }
}
.pr-read__right {
  width: 52%;
}
@media screen and (max-width: 767px) {
  .pr-read__right {
    width: 100%;
  }
}
.pr-read__text {
  font-size: 1.125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 400;
  line-height: 2.2222222222;
}
@media screen and (max-width: 767px) {
  .pr-read__text {
    font-size: 0.875rem;
    font-feature-settings: "palt";
  }
}

.pr-mission1 {
  background: #fff9f8;
}
.pr-mission1__inner {
  padding: 0 25px;
  margin: 0 auto;
  max-width: 78.125rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-mission1__container {
  padding-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__container {
    padding-bottom: 3.75rem;
  }
}
.pr-mission1__top {
  padding: 7.1875rem 0 4.375rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .pr-mission1__top {
    flex-direction: column;
    gap: 1.875rem;
    align-items: unset;
    justify-content: unset;
    padding: 3.125rem 0;
  }
}
.pr-mission1__t-img {
  width: 50%;
  padding: 0 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-img {
    width: 100%;
    padding: unset;
  }
}
.pr-mission1__t-img picture {
  max-width: 29.0625rem;
  margin-left: auto;
  display: block;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-img picture {
    max-width: unset;
    margin-left: unset;
  }
}
.pr-mission1__t-contents {
  width: 50%;
  padding: 0 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-contents {
    width: 100%;
    padding: unset;
  }
}
.pr-mission1__t-content {
  margin-bottom: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-content {
    background: #fff;
    padding: 20px;
    margin-bottom: 0.625rem;
  }
}
.pr-mission1__t-ttl span {
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.1em;
  display: inline-block;
  padding-right: 0.75rem;
  background: linear-gradient(#ffc4bf, #ceece9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-ttl span {
    font-size: 0.75rem;
    padding-right: 0.4375rem;
  }
}
.pr-mission1__t-ttl {
  margin-bottom: 0.75rem;
  font-size: 1.5rem;
  line-height: 1;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .pr-mission1__t-ttl {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }
}
.pr-mission1__t-text {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
}
.pr-mission1__middle {
  background-image: url("../img/recruit/mission1-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 6.25rem 0.625rem 5.625rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__middle {
    padding: 3.125rem 0.625rem 2.5rem;
  }
}
.pr-mission1__m-read {
  margin-bottom: 0.9375rem;
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.25;
}
@media screen and (max-width: 767px) {
  .pr-mission1__m-read {
    font-size: 1.375rem;
    margin-bottom: 0.9375rem;
    letter-spacing: unset;
    line-height: 1.5;
  }
}
.pr-mission1__m-text {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
}
@media screen and (max-width: 767px) {
  .pr-mission1__m-text {
    font-size: 0.75rem;
    text-align: left;
  }
}
.pr-mission1__bottom {
  padding: 3.75rem 0 8.125rem;
  background: #FFF;
}
@media screen and (max-width: 767px) {
  .pr-mission1__bottom {
    padding: 1.875rem 0 3.75rem;
  }
}
.pr-mission1__b-content {
  display: flex;
  align-items: flex-start;
  margin: 0 5rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-content {
    margin: 0 0.625rem;
    flex-direction: column;
    align-items: unset;
  }
}
.pr-mission1__b-content::after {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 1px;
  display: block;
  content: "";
  background: #dddddd;
  top: 0;
  left: 4.5rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-content::after {
    display: none;
  }
}
.pr-mission1__b-content:nth-of-type(3)::after {
  display: none;
}
.pr-mission1__b-content:nth-of-type(1) {
  padding-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-content:nth-of-type(1) {
    padding-bottom: 3.125rem;
  }
}
.pr-mission1__b-content:nth-of-type(2) {
  padding-bottom: 5.625rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-content:nth-of-type(2) {
    padding-bottom: 3.125rem;
  }
}
.pr-mission1__b-num {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 9.375rem;
  background: #ffccc6;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-num {
    width: 6.25rem;
    margin: 0 auto;
  }
}
.pr-mission1__b-num p {
  color: #FFF;
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-num p {
    font-size: 1.375rem;
  }
}
.pr-mission1__b-num p span {
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
  color: #FFF;
  font-weight: 400;
  display: block;
  text-align: center;
  margin-bottom: 0.9375rem;
  line-height: 1;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-num p span {
    margin-bottom: 0.3125rem;
    font-size: 0.75rem;
  }
}
.pr-mission1__b-body {
  padding-left: 1.5625rem;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-body {
    padding-left: unset;
    width: 100%;
  }
}
.pr-mission1__b-body--1 {
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-body--1 {
    margin-top: 0.9375rem;
  }
}
.pr-mission1__b-body--2 {
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-body--2 {
    margin-top: 0.9375rem;
  }
}
.pr-mission1__b-body--3 {
  margin-top: 0.9375rem;
}
.pr-mission1__b-top1 {
  margin-bottom: 0.3125rem;
  font-size: 2rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  line-height: 1.3;
}
@media screen and (max-width: 767px) {
  .pr-mission1__b-top1 {
    letter-spacing: unset;
    margin-bottom: 0.625rem;
    font-size: 1.375rem;
  }
}
.pr-mission1__b-top2 {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
}

.pr-stage1 {
  display: flex;
  align-items: flex-start;
  margin: 3.75rem 0 3.125rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1 {
    align-items: unset;
    flex-direction: column;
    gap: 1.25rem;
    margin-top: 1.875rem;
    margin-bottom: 1.5625rem;
  }
}
.pr-stage1__img {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pr-stage1__img {
    width: 100%;
  }
}
.pr-stage1__text {
  width: 50%;
  padding-left: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1__text {
    width: 100%;
    padding-left: unset;
  }
}
.pr-stage1__text1 {
  padding: 0.5625rem 0.625rem;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #eba9a1;
  width: fit-content;
  border: 1px solid #eba9a1;
  margin-bottom: 1.25rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pr-stage1__text1 {
    margin-bottom: 0.625rem;
    font-size: 0.9375rem;
  }
}
.pr-stage1__text2 {
  margin-bottom: 0.4375rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.7142857143;
}
@media screen and (max-width: 767px) {
  .pr-stage1__text2 {
    letter-spacing: unset;
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.pr-stage1__text3 {
  font-size: 0.875rem;
  line-height: 2.1428571429;
  font-weight: 200;
  letter-spacing: 0.05em;
}
.pr-stage1__s-contents {
  background: #f9f9f9;
  padding: 1.25rem 0 1.875rem;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-contents {
    padding: 0.9375rem 0;
  }
}
.pr-stage1__s-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.9375rem;
  padding: 0 1.875rem 1.5625rem;
  border-bottom: 1px solid #efefef;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-top {
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
    padding: 0 0.9375rem 0.9375rem;
  }
}
.pr-stage1__s-ttl {
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-ttl {
    font-size: 1.125rem;
    letter-spacing: unset;
  }
}
.pr-stage1__s-items {
  display: flex;
  gap: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-items {
    flex-direction: column;
    gap: 0.625rem;
  }
}
.pr-stage1__s-item {
  position: relative;
  padding-left: 0.9375rem;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-item {
    font-size: 0.9375rem;
    padding-left: 0.8125rem;
  }
}
.pr-stage1__s-item::before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  aspect-ratio: 1;
  width: 0.8125rem;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-item::before {
    width: 0.625rem;
  }
}
.pr-stage1__s-item:nth-of-type(1)::before {
  background: #f6cbc7;
}
.pr-stage1__s-item:nth-of-type(2)::before {
  background: #b3d9d2;
}
.pr-stage1__s-item:nth-of-type(3)::before {
  background: #c7eff6;
}
.pr-stage1__s-dates {
  display: flex;
  gap: 2.1875rem;
  max-width: 53.75rem;
  justify-content: center;
  margin: 0 auto;
  padding-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-dates {
    max-width: unset;
    padding: 0.9375rem 0.625rem 0;
    gap: 0.625rem;
  }
}
.pr-stage1__s-date {
  text-align: center;
  aspect-ratio: 1;
  width: 2.8125rem;
  border-radius: 50%;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .pr-stage1__s-date {
    width: 1.875rem;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
}
.pr-stage1__s-date:nth-of-type(1), .pr-stage1__s-date:nth-of-type(2) {
  background: #f6cbc7;
}
.pr-stage1__s-date:nth-of-type(3), .pr-stage1__s-date:nth-of-type(4), .pr-stage1__s-date:nth-of-type(5) {
  background: #b3d9d2;
}
.pr-stage1__s-date:nth-of-type(6), .pr-stage1__s-date:nth-of-type(7) {
  background: #c7eff6;
}
.pr-stage1__attention {
  font-size: 0.75rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .pr-stage1__attention {
    font-size: 0.625rem;
  }
}

.pr-stage2 {
  margin-top: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .pr-stage2 {
    margin-top: 1.5625rem;
  }
}
.pr-stage2__contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-stage2__contents {
    grid-template-columns: 1fr;
  }
}
.pr-stage2__content:nth-of-type(1) {
  background: #faedeb;
}
.pr-stage2__content:nth-of-type(2) {
  background: #e0f1ee;
}
.pr-stage2__c-img {
  width: 100%;
  margin-bottom: 2.5rem;
  aspect-ratio: 420/250;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-img {
    margin-bottom: 1.5625rem;
  }
}
.pr-stage2__c-ttl {
  margin-bottom: 0.625rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-ttl {
    margin-bottom: 1.25rem;
  }
}
.pr-stage2__c-ttl span {
  display: block;
  font-size: 0.875rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-ttl span {
    font-size: 0.75rem;
  }
}
.pr-stage2__c-texts {
  padding: 0 2.1875rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-texts {
    padding: 0 0.9375rem 1.25rem;
  }
}
.pr-stage2__c-ttl {
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-family: shippori-mincho, sans-serif;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-ttl {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.pr-stage2__c-text {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 2.1428571429;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .pr-stage2__c-text {
    line-height: 1.5714285714;
  }
}

.pr-stage3 {
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-stage3 {
    margin-top: 1.25rem;
  }
}
.pr-stage3__content {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .pr-stage3__content {
    flex-direction: column;
    gap: 1.25rem;
    align-items: unset;
  }
}
.pr-stage3__c-img {
  aspect-ratio: 1;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-img {
    padding-top: 68%;
    width: 100%;
    position: relative;
    aspect-ratio: initial;
  }
  .pr-stage3__c-img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.pr-stage3__c-body {
  padding-left: 2.5rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-body {
    padding-left: unset;
    width: 100%;
  }
}
.pr-stage3__c-ttl {
  margin-bottom: 0.625rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-ttl {
    font-size: 1.125rem;
  }
}
.pr-stage3__c-text {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  margin-bottom: 1.5625rem;
  line-height: 2.1;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-text {
    margin-bottom: 0.9375rem;
  }
}
.pr-stage3__c-bottom {
  background: #f6beb7;
  text-align: center;
  color: #FFF;
  font-size: 0.9375rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  padding: 1.5625rem 0;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-bottom {
    font-size: 0.875rem;
    padding: 0.9375rem 0;
  }
}
.pr-stage3__c-bottom span {
  display: block;
  margin-bottom: 0.9375rem;
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  font-family: shippori-mincho, sans-serif;
}
@media screen and (max-width: 767px) {
  .pr-stage3__c-bottom span {
    margin-bottom: 0.625rem;
  }
}

.pr-mission2 {
  background: #fff9f8;
}
.pr-mission2__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
  padding-bottom: 8.125rem;
}
@media screen and (max-width: 767px) {
  .pr-mission2__inner {
    padding: 0 20px 3.75rem;
    max-width: 37.5rem;
  }
}
.pr-mission2__top {
  background-image: url("../img/recruit/mission2-1.jpg");
}

.pr-point1__container {
  margin: 7.5rem 0 3.75rem;
  background: #FFF;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .pr-point1__container {
    margin: 3.75rem 0 1.875rem;
    flex-direction: column;
    gap: 1.25rem;
  }
}
.pr-point1__img {
  padding-right: 1.25rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .pr-point1__img {
    width: 100%;
    padding-right: unset;
  }
}
.pr-point1__texts {
  width: 50%;
  padding-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point1__texts {
    width: 100%;
    padding-left: unset;
  }
}
.pr-point1__top {
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .pr-point1__top {
    margin-bottom: 1.25rem;
  }
}
.pr-point1__text span {
  color: #ea9d93;
}

.point-container {
  padding: 5rem;
  background: #FFF;
}
@media screen and (max-width: 767px) {
  .point-container {
    padding: 1.875rem 0.625rem 1.5625rem;
  }
}

.point-top {
  display: flex;
  align-items: center;
  gap: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .point-top {
    gap: 0.625rem;
  }
}
.point-top__num {
  aspect-ratio: 1;
  width: 6.25rem;
  text-align: center;
  color: #FFF;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  line-height: 6.25rem;
  border-radius: 50%;
  background: linear-gradient(#ffc4bf, #ceece9);
}
@media screen and (max-width: 767px) {
  .point-top__num {
    font-size: 0.875rem;
    line-height: 4.0625rem;
    width: 4.0625rem;
  }
}
.point-top__ttl span {
  margin-top: 0.3125rem;
  display: block;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .point-top__ttl span {
    font-size: 1rem;
    margin-top: 0.1875rem;
  }
}
.point-top__ttl {
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  width: calc(100% - 7.1875rem);
}
@media screen and (max-width: 767px) {
  .point-top__ttl {
    font-size: 1.25rem;
    width: 75%;
  }
}

.pr-point2__top {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-point2__top {
    margin-bottom: 1.25rem;
  }
}
.pr-point2__container {
  display: flex;
  align-items: center;
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .pr-point2__container {
    flex-direction: column-reverse;
    align-items: unset;
    margin-bottom: 1.875rem;
    gap: 1.25rem;
  }
}
.pr-point2__circle {
  position: absolute;
  top: -3.75rem;
  right: -3.125rem;
  aspect-ratio: 1;
  width: 10rem;
  background: #f6beb7;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .pr-point2__circle {
    top: -1.25rem;
    right: -1.25rem;
    width: 6.25rem;
  }
}
.pr-point2__texts {
  width: 50%;
  padding-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point2__texts {
    padding-right: unset;
    width: 100%;
  }
}
.pr-point2__c-text {
  font-family: shippori-mincho, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #FFF;
  line-height: 1.25;
}
@media screen and (max-width: 767px) {
  .pr-point2__c-text {
    font-size: 0.75rem;
  }
}
.pr-point2__c-text span {
  line-height: 1;
  font-size: 2.625rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 700;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .pr-point2__c-text span {
    font-size: 1.75rem;
  }
}
.pr-point2__text {
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-point2__text {
    margin-bottom: 0.625rem;
  }
}
.pr-point2__bottom {
  background: #f6beb7;
  padding: 0.9375rem 1.875rem 0.9375rem 1.25rem;
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-point2__bottom {
    padding: 0.625rem 0.9375rem 0.625rem 0.625rem;
  }
}
.pr-point2__b-num {
  aspect-ratio: 1;
  width: 5rem;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .pr-point2__b-num {
    width: 4.375rem;
  }
}
.pr-point2__b-num p {
  text-align: center;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #f6beb7;
  line-height: 1.25;
}
@media screen and (max-width: 767px) {
  .pr-point2__b-num p {
    font-size: 0.875rem;
  }
}
.pr-point2__b-text {
  width: 90%;
  color: #FFF;
  line-height: 1.5714285714;
}
@media screen and (max-width: 767px) {
  .pr-point2__b-text {
    font-size: 0.875rem;
  }
}
.pr-point2__b-text .small {
  font-size: 0.75rem;
  color: #FFF;
}
.pr-point2__img {
  width: 50%;
  padding-left: 1.25rem;
  aspect-ratio: 520/365;
  position: relative;
}
@media screen and (max-width: 767px) {
  .pr-point2__img {
    width: 100%;
    padding-left: unset;
    aspect-ratio: 500/365;
  }
}

.pr-point3__top {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__top {
    margin-bottom: 0.9375rem;
  }
}
.pr-point3__container {
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__container {
    margin-bottom: 1.875rem;
  }
}
.pr-point3__contents {
  display: flex;
  align-items: center;
  margin-bottom: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__contents {
    align-items: unset;
    flex-direction: column;
    margin-bottom: 1.875rem;
    gap: 1.25rem;
  }
}
.pr-point3__img {
  aspect-ratio: 520/365;
  width: 50%;
  padding-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__img {
    aspect-ratio: 500/365;
    padding-right: unset;
    width: 100%;
  }
}
.pr-point3__texts {
  width: 50%;
  padding-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__texts {
    padding-left: unset;
    width: 100%;
  }
}
.pr-point3__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__items {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }
}
.pr-point3__item {
  padding: 1.875rem 0.625rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__item {
    padding: 0.9375rem 0.625rem 1.25rem;
  }
}
.pr-point3__item:nth-of-type(1) {
  background: #faedeb;
}
.pr-point3__item:nth-of-type(2) {
  background: #e0f1ee;
}
.pr-point3__i-ttl {
  text-align: center;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point3__i-ttl {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.pr-point3__i-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem 0.3125rem;
  justify-content: center;
}
.pr-point3__i-item {
  width: fit-content;
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  border-radius: 6.25rem;
  background: #FFF;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pr-point3__i-item {
    padding: 0.625rem;
  }
}

.pr-point4__container {
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pr-point4__container {
    margin-bottom: 1.875rem;
  }
}
.pr-point4__top {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-point4__top {
    margin-bottom: 1.25rem;
  }
}
.pr-point4 .point-top__ttl {
  letter-spacing: -0.02em;
}
@media screen and (max-width: 1024px) {
  .pr-point4 .point-top__ttl {
    letter-spacing: -0.05em;
  }
}
@media screen and (max-width: 767px) {
  .pr-point4 .point-top__ttl {
    font-feature-settings: unset;
  }
}
.pr-point4__contents {
  margin-bottom: 3.75rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .pr-point4__contents {
    margin-bottom: 1.875rem;
    align-items: unset;
    flex-direction: column-reverse;
    gap: 1.25rem;
  }
}
.pr-point4__texts {
  width: 52%;
  padding-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point4__texts {
    width: 100%;
    padding-right: unset;
  }
}
.pr-point4__img {
  width: 50%;
  padding-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-point4__img {
    width: 100%;
    padding-left: unset;
  }
}
.pr-point4__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-point4__items {
    grid-template-columns: 1fr;
  }
}
.pr-point4__item {
  background: #faedeb;
  text-align: center;
  padding: 1.5625rem 0;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pr-point4__item {
    font-size: 0.9375rem;
    padding: 0.625rem 0;
  }
}

.pr-point5__contents {
  margin-bottom: 3.125rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .pr-point5__contents {
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 1.5625rem;
  }
}
.pr-point5__container {
  padding-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__container {
    padding-bottom: 3.125rem;
  }
}
.pr-point5__img {
  width: 50%;
  padding-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__img {
    width: 100%;
    padding-right: unset;
  }
}
.pr-point5__texts {
  width: 50%;
  padding-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__texts {
    width: 100%;
    padding-left: unset;
  }
}
.pr-point5__top {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__top {
    margin-bottom: 1.25rem;
  }
}
.pr-point5__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__items {
    grid-template-columns: 1fr 1fr;
  }
}
.pr-point5__item {
  padding: 2.5rem 0.625rem;
  background: #faedeb;
}
@media screen and (max-width: 767px) {
  .pr-point5__item {
    padding: 1.25rem 0.625rem;
  }
}
.pr-point5__i-circle {
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 0 auto;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #e7aea6;
  margin-bottom: 0.75rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-circle {
    height: 5rem !important;
    margin-bottom: 0.9375rem;
  }
}
@media screen and (max-width: 1024px) {
  .pr-point5__i-circle {
    height: 8.75rem;
  }
}
.pr-point5__i-c-text {
  margin-top: 0.3125rem;
  font-size: 0.8125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.3076923077;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-c-text {
    font-size: 0.6875rem;
  }
}
.pr-point5__i-c-text span {
  font-family: "Marcellus", serif;
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
  line-height: 1.1666666667;
  color: #FFF;
  line-height: 1;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-c-text span {
    font-size: 1.25rem;
  }
}
.pr-point5__i-text1 {
  margin-bottom: 0.875rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  line-height: 1;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-text1 {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.pr-point5__i-text2 {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-text2 {
    line-height: 1.6em;
    font-size: 0.75rem;
    text-align: left;
  }
}
.pr-point5__i-text2 .small {
  font-size: 0.75rem;
  color: #000;
  display: block;
}
@media screen and (max-width: 767px) {
  .pr-point5__i-text2 .small {
    margin-top: 0.4375rem;
    font-size: 0.625rem;
  }
}
.pr-point5__item--green {
  background: #e0f1ee;
}
.pr-point5__item--green .pr-point5__i-circle {
  background: #81c3b7;
}

.pr-inter {
  overflow: hidden;
  margin-bottom: 8.75rem;
}
@media screen and (max-width: 767px) {
  .pr-inter {
    margin-bottom: 4.375rem;
  }
}
.pr-inter__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pr-inter__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-inter__container {
  padding-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pr-inter__container {
    padding-top: 3.125rem;
  }
}
.pr-inter__ttls {
  margin-bottom: 5.625rem;
}
@media screen and (max-width: 767px) {
  .pr-inter__ttls {
    margin-bottom: 2.8125rem;
  }
}
.pr-inter__contents {
  margin-bottom: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .pr-inter__contents {
    margin-bottom: 1.875rem;
  }
}
.pr-inter__content {
  padding: 5.625rem 7.5rem;
  background: linear-gradient(rgba(255, 196, 191, 0.5), rgba(206, 236, 233, 0.5));
  border-radius: 0.625rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .pr-inter__content {
    padding: 2.5rem 0.9375rem;
    border-radius: 0.3125rem;
  }
}
.pr-inter__c-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.9375rem;
  margin-bottom: 1.875rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-top {
    flex-direction: column;
    gap: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
}
.pr-inter__c-top::after {
  position: absolute;
  top: 0;
  left: -2.1875rem;
  display: block;
  content: "";
  width: 3.75rem;
  aspect-ratio: 60/45;
  background-image: url("../img/recruit/staff-com.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-top::after {
    width: 1.25rem;
    left: -0.625rem;
  }
}
.pr-inter__c-read {
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.5625;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-read {
    font-size: 1.25rem;
  }
}
.pr-inter__c-profile {
  display: flex;
  align-items: center;
  gap: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-profile {
    gap: 0.9375rem;
  }
}
.pr-inter__c-name {
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-name {
    font-size: 0.875rem;
  }
}
.pr-inter__c-name span {
  display: inline-block;
  padding-left: 0.625rem;
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-name span {
    font-size: 1.25rem;
  }
}
.pr-inter__c-img {
  aspect-ratio: 1;
  width: 8.125rem;
  border-radius: 50%;
}
@media screen and (max-width: 767px) {
  .pr-inter__c-img {
    width: 5rem;
  }
}
.pr-inter .swiper-pagination {
  position: unset;
}
.pr-inter .swiper-pagination-bullet {
  aspect-ratio: 1;
  width: 0.625rem;
  height: unset;
  background: #d6e6e3;
  opacity: 1;
}
.pr-inter .swiper-pagination-bullet-active {
  background: #81c3b7;
}

.pr-event__inner {
  max-width: 91.5625rem;
  padding-left: 25px;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .pr-event__inner {
    max-width: unset;
    padding: unset;
    margin: 0 auto;
  }
}
.pr-event__container {
  padding-bottom: 7.5rem;
  display: flex;
  gap: 0.9375rem;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .pr-event__container {
    flex-direction: column;
    gap: 1.875rem;
    padding-bottom: 3.75rem;
  }
}
.pr-event__top {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .pr-event__top {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 37.5rem;
  }
}
.pr-event__ttl {
  margin-bottom: 1.875rem;
  width: fit-content;
  font-size: 3.75rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  line-height: 1;
  background: linear-gradient(90deg, #ffc4bf, #ceece9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 767px) {
  .pr-event__ttl {
    font-size: 2.25rem;
    margin-bottom: 0.9375rem;
  }
}
.pr-event__read {
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.5238095238;
}
@media screen and (max-width: 767px) {
  .pr-event__read {
    font-size: 1.125rem;
  }
}
.pr-event__read span {
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  display: block;
}
@media screen and (max-width: 767px) {
  .pr-event__read span {
    font-size: 0.875rem;
  }
}
.pr-event__wrapper {
  width: 75%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .pr-event__wrapper {
    width: 100%;
    padding-left: 1.25rem;
  }
}
.pr-event__c-img {
  aspect-ratio: 1;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-event__c-img {
    margin-bottom: 0.5rem;
  }
}
.pr-event__c-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-event__c-tags {
    gap: 0.625rem;
  }
}
.pr-event__c-tag {
  position: relative;
  padding-left: 0.625rem;
}
.pr-event__c-tag::before {
  position: absolute;
  content: "#";
  display: block;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 0.875rem;
}

.pr-message {
  background: #f7f7f7;
}
.pr-message__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pr-message__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-message__container {
  padding: 7.5rem 0;
}
@media screen and (max-width: 767px) {
  .pr-message__container {
    padding: 3.75rem 0;
  }
}
.pr-message__ttls {
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pr-message__ttls {
    margin-bottom: 3.125rem;
  }
}
.pr-message__read {
  text-align: center;
}
.pr-message__read1 {
  margin-bottom: 0;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .pr-message__read1 {
    font-size: 0.9375rem;
    line-height: auto;
    margin-bottom: 0.3125rem;
  }
}
.pr-message__read2 {
  margin-bottom: 1.25rem;
  font-size: 3.75rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-message__read2 {
    font-size: 2.25rem;
    margin-bottom: 0.9375rem;
  }
}
.pr-message__read3 {
  font-size: 1.3125rem;
  line-height: 1.9047619048;
  margin-bottom: 3.75rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-message__read3 {
    margin-bottom: 1.875rem;
    font-size: 0.9375rem;
  }
}
.pr-message__content {
  background: linear-gradient(rgba(255, 196, 191, 0.5), rgba(206, 236, 233, 0.5));
  padding: 5rem 6.25rem 7.5rem;
}
@media screen and (max-width: 767px) {
  .pr-message__content {
    padding: 2.5rem 0.9375rem 3.125rem;
  }
}
.pr-message__c-ttl {
  text-align: center;
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  margin-bottom: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .pr-message__c-ttl {
    font-size: 1.5rem;
  }
}
.pr-message__c-read {
  text-align: center;
  margin-bottom: 3.75rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-message__c-read {
    font-size: 1.125rem;
    margin-bottom: 1.875rem;
  }
}
.pr-message__c-text {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .pr-message__c-text {
    margin-bottom: 1.5625rem;
  }
}
.pr-message__c-name {
  text-align: right;
  font-size: 0.875rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-message__c-name {
    font-size: 0.75rem;
  }
}
.pr-message__c-name span {
  text-align: right;
  font-size: 1.625rem;
  padding-left: 0.625rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .pr-message__c-name span {
    font-size: 1.25rem;
  }
}

.pr-company__ttls {
  background-image: url("../img/recruit/company.jpg");
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pr-company__ttls {
    margin-bottom: 3.125rem;
  }
}
.pr-company__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pr-company__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-company__contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  margin-bottom: 4.375rem;
}
@media screen and (max-width: 767px) {
  .pr-company__contents {
    gap: 0.625rem;
    grid-template-columns: 1fr;
    margin-bottom: 1.875rem;
  }
}
.pr-company__content {
  background: #f9f9f9;
  aspect-ratio: 590/240;
  padding-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .pr-company__content {
    padding-top: 1.5625rem;
    aspect-ratio: unset;
    padding-bottom: 2.5rem;
    padding: 1.5625rem 0.625rem 2.5rem;
  }
}
.pr-company__content:nth-of-type(1) .pr-company__c-text1 {
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .pr-company__content:nth-of-type(1) .pr-company__c-text1 {
    margin-bottom: 1.5625rem;
  }
}
.pr-company__content:nth-of-type(1) .pr-company__c-text2 {
  font-size: 1.625rem;
}
@media screen and (max-width: 767px) {
  .pr-company__content:nth-of-type(1) .pr-company__c-text2 {
    font-size: 1.125rem;
  }
}
.pr-company__content:nth-of-type(2) .pr-company__c-text1 {
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .pr-company__content:nth-of-type(2) .pr-company__c-text1 {
    margin-bottom: 0.9375rem;
  }
}
.pr-company__content:nth-of-type(2) .pr-company__c-text2 {
  font-size: 1.3125rem;
  line-height: 1.9047619048;
}
@media screen and (max-width: 767px) {
  .pr-company__content:nth-of-type(2) .pr-company__c-text2 {
    font-size: 0.9375rem;
    font-feature-settings: "palt";
  }
}
.pr-company__c-text1 {
  font-size: 0.875rem;
  text-align: center;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .pr-company__c-text1 {
    font-size: 0.75rem;
  }
}
.pr-company__c-text2 {
  font-family: shippori-mincho, sans-serif;
  text-align: center;
  font-weight: 500;
}
.pr-company__i-ttl {
  margin-bottom: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .pr-company__i-ttl {
    margin-bottom: 0.9375rem;
  }
}

.pr-info--1 {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .pr-info--1 {
    margin-bottom: 3.125rem;
  }
}

.pr-info--2 li,
.pr-info--3 li {
  border-bottom: 1px solid #dddddd;
}

.pr-job__ttls {
  background-image: url("../img/recruit/job.jpg");
  margin-bottom: 6.875rem;
}
@media screen and (max-width: 767px) {
  .pr-job__ttls {
    margin-bottom: 3.125rem;
  }
}
.pr-job__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .pr-job__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.pr-job__contents {
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .pr-job__contents {
    margin-bottom: 3.125rem;
  }
}
.pr-job__content {
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pr-job__content {
    margin-bottom: 3.125rem;
  }
}
.pr-job__content:nth-of-type(1) {
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .pr-job__content:nth-of-type(1) {
    margin-bottom: 3.125rem;
  }
}
.pr-job__content:nth-of-type(2) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-job__content:nth-of-type(2) {
    margin-bottom: 3.125rem;
  }
}
.pr-job__c-ttl {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .pr-job__c-ttl {
    margin-bottom: 1.25rem;
  }
}
.pr-job__info {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .pr-job__info {
    flex-direction: column;
  }
}
.pr-job__info--1 {
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .pr-job__info--1 {
    margin-bottom: 1.875rem;
  }
}
.pr-job .small {
  font-size: 0.75rem;
  color: #000;
}
@media screen and (max-width: 767px) {
  .pr-job .small {
    font-size: 0.625rem;
  }
}
.pr-job__step {
  font-size: 1.3125rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  text-align: center;
  padding: 2.5rem 0.625rem;
  background: linear-gradient(90deg, rgba(255, 196, 191, 0.5), rgba(206, 236, 233, 0.5));
}
@media screen and (max-width: 767px) {
  .pr-job__step {
    padding: 1.25rem 0.625rem;
    font-size: 1rem;
  }
}
.pr-job__button {
  max-width: 25rem;
  margin: 0 auto;
  display: block;
  text-align: center;
  padding: 2.5rem 0.625rem;
  background: #000;
  color: #FFF;
  font-size: 1.3125rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  line-height: 1;
  position: relative;
  border-radius: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-job__button {
    max-width: unset;
    padding: 1.5625rem 0.625rem;
    font-size: 1.125rem;
    border-radius: 0.3125rem;
  }
}
.pr-job__button span {
  position: absolute;
  font-size: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .pr-job__button span {
    font-size: 1.25rem;
    right: 0.9375rem;
  }
}

.pr-info--2 {
  width: 50%;
  padding-right: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-info--2 {
    width: 100%;
    padding-right: unset;
  }
}

.pr-info--3 {
  width: 50%;
  padding-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .pr-info--3 {
    padding-left: unset;
    width: 100%;
  }
}

.pr-info--2 .pr-info__item,
.pr-info--3 .pr-info__item {
  padding-left: 1.25rem;
  padding-right: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .pr-info--2 .pr-info__item,
  .pr-info--3 .pr-info__item {
    padding-left: unset;
    padding-right: unset;
  }
}

.pr-info--2 .pr-info__label,
.pr-info--3 .pr-info__label {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .pr-info--2 .pr-info__label,
  .pr-info--3 .pr-info__label {
    width: 100%;
  }
}

.pr-info--2 .pr-info__data {
  margin-top: unset;
  font-feature-settings: "palt";
  width: 73%;
}
@media screen and (max-width: 767px) {
  .pr-info--2 .pr-info__data {
    width: 100%;
  }
}

.pr-info--3 .pr-info__data {
  margin-top: unset;
  font-feature-settings: "palt";
  width: 80%;
}
@media screen and (max-width: 767px) {
  .pr-info--3 .pr-info__data {
    width: 100%;
  }
}

.sd-ttl {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .sd-ttl {
    font-size: 1.75rem;
  }
}

.salon-detail .page-mv__img {
  position: relative;
  padding-top: 39%;
}
.salon-detail .page-mv__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.salon-detail .page-mv__salon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  font-weight: 400;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  color: #FFF;
  background: #141414;
  line-height: 1;
  padding: 2.5rem 6.25rem;
}
@media screen and (max-width: 767px) {
  .salon-detail .page-mv__salon {
    font-size: 1.125rem;
    line-height: 1.3;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding: 1.25rem 0;
  }
}
.salon-detail .page-mv__salon span {
  font-family: "Marcellus", serif;
}

.salon-detail .page-top__ttl {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .salon-detail .page-top__ttl {
    margin-bottom: 0.625rem;
  }
}

.sd-info {
  background-image: url("../img/salon_detail/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 7.5rem 0;
}
@media screen and (max-width: 767px) {
  .sd-info {
    padding: 3.75rem 0;
  }
}
.sd-info__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .sd-info__container {
    flex-direction: column;
    gap: 1.25rem;
    justify-content: unset;
  }
}
.sd-info__img {
  width: 50%;
  padding-right: 2.5rem;
}
@media screen and (max-width: 767px) {
  .sd-info__img {
    width: 100%;
    padding-right: 1.25rem;
  }
}
.sd-info__img div {
  position: relative;
  padding-top: 63.6%;
}
.sd-info__img div img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.sd-info__info {
  width: 50%;
  padding-top: 0.9375rem;
  max-width: 37.5rem;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .sd-info__info {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 1.5625rem;
  }
}
.sd-info__ttl {
  font-size: 1.75rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #000;
  margin-bottom: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .sd-info__ttl {
    font-size: 1.375rem;
    margin-bottom: 1.25rem;
  }
}
.sd-info__ttl a {
  color: #000;
  padding-left: 0.625rem;
}
.sd-info__item {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .sd-info__item {
    flex-direction: column;
    align-items: unset;
  }
}
.sd-info__item--base {
  align-items: baseline;
}
.sd-info__tag {
  font-size: 1.25rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  width: 25%;
}
@media screen and (max-width: 767px) {
  .sd-info__tag {
    font-size: 1.125rem;
    width: 100%;
  }
}
.sd-info__data {
  width: 75%;
  font-size: 1rem;
  letter-spacing: 0.05em;
  font-weight: 200;
  line-height: 1.875;
}
@media screen and (max-width: 767px) {
  .sd-info__data {
    width: 100%;
    font-size: 0.875rem;
  }
}
.sd-info__tel {
  font-size: 2rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
}
@media screen and (max-width: 767px) {
  .sd-info__tel {
    font-size: 1.5rem;
  }
}
.sd-info__tel i {
  padding-right: 0.3125rem;
}
.sd-info__tel span {
  display: block;
  font-size: 0.875rem;
  font-family: "noto-sans-cjk-jp", sans-serif;
}
.sd-info__data--park span {
  font-size: 0.875rem;
  font-family: "noto-sans-cjk-jp", sans-serif;
  font-weight: 200;
  letter-spacing: 0.05em;
}
.sd-info__reserve {
  display: block;
  width: 17.0625rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 200;
  color: #FFF;
  background: #000;
  position: relative;
  padding: 0.9375rem 0;
}
@media screen and (max-width: 767px) {
  .sd-info__reserve {
    width: 100%;
  }
}
.sd-info__reserve::after {
  position: absolute;
  content: "";
  display: block;
  width: 3.5625rem;
  height: 1px;
  background: #FFF;
  top: 50%;
  right: 0;
}
.sd-info__map {
  max-width: 78.125rem;
  padding: 0 25px;
  aspect-ratio: 1200/367;
  margin: 0 auto;
  filter: grayscale(1);
}
@media screen and (max-width: 767px) {
  .sd-info__map {
    max-width: unset;
    width: 100%;
    padding: 0 20px;
    aspect-ratio: 3/2;
  }
}
.sd-info__map iframe {
  height: 100%;
  width: 100%;
}

.sd-staff__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .sd-staff__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.sd-staff__container {
  padding: 6.25rem 0;
}
@media screen and (max-width: 767px) {
  .sd-staff__container {
    padding: 3.125rem 0;
  }
}
.sd-staff__ttl {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .sd-staff__ttl {
    margin-bottom: 1.25rem;
  }
}
.sd-staff__contents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.125rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .sd-staff__contents {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }
}
.sd-staff__img {
  box-shadow: 0.9375rem 0.9375rem 0 #eae7e6;
  margin-bottom: 1.5625rem;
  position: relative;
  padding-top: 100%;
}
@media screen and (max-width: 767px) {
  .sd-staff__img {
    box-shadow: 0.625rem 0.625rem 0 #eae7e6;
    margin-bottom: 1.25rem;
  }
}
.sd-staff__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: top;
}
.sd-staff__img img {
  display: block;
}
.sd-staff__info {
  padding-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .sd-staff__info {
    padding-left: 0.625rem;
  }
}
.sd-staff__role {
  font-size: 1.25rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .sd-staff__role {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.sd-staff__read {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
  align-items: center;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
  border-bottom: 1px solid #eae7e6;
}
@media screen and (max-width: 767px) {
  .sd-staff__read {
    flex-direction: column;
  }
}
.sd-staff__name {
  font-size: 1rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  font-weight: 200;
}
.sd-staff__name a {
  color: #000;
  padding-left: 0.625rem;
}
.sd-staff__name span {
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
  display: block;
}
.sd-staff__year {
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .sd-staff__year {
    text-align: right;
  }
}
.sd-staff__catch {
  font-size: 0.875rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.8571428571;
}

.sd-gallery {
  background: #f1f1f1;
}
.sd-gallery__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .sd-gallery__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.sd-gallery__container {
  padding: 6.25rem 0 10rem;
}
@media screen and (max-width: 767px) {
  .sd-gallery__container {
    padding: 3.125rem 0 5rem;
  }
}
.sd-gallery__ttl {
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .sd-gallery__ttl {
    margin-bottom: 1.5625rem;
  }
}
.sd-gallery__images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .sd-gallery__images {
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
  }
}
.sd-gallery__img {
  padding-top: 130.5%;
  position: relative;
}
.sd-gallery__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.salon .page-top__ttl {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .salon .page-top__ttl {
    margin-bottom: 0.625rem;
  }
}

.page-salon {
  background-image: url("../img/salon/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f1f1f1;
}
@media screen and (max-width: 767px) {
  .page-salon {
    background-size: 250%;
  }
}
.page-salon__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .page-salon__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.page-salon__contents {
  padding: 6.25rem 0 11.25rem;
}
@media screen and (max-width: 767px) {
  .page-salon__contents {
    padding: 3.125rem 0 5.625rem;
  }
}
.page-salon__content {
  margin-bottom: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .page-salon__content {
    margin-bottom: 2.1875rem;
  }
}
.page-salon__content:last-of-type {
  margin-bottom: unset;
}
.page-salon__enttl {
  font-size: 2.625rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .page-salon__enttl {
    font-size: 1.75rem;
    margin-bottom: 0.625rem;
  }
}
.page-salon__body {
  display: flex;
}
@media screen and (max-width: 767px) {
  .page-salon__body {
    flex-direction: column;
    gap: 1.25rem;
  }
}
.page-salon__img {
  width: 50%;
  padding-right: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .page-salon__img {
    width: 100%;
    padding-right: unset;
  }
}
.page-salon__img div {
  position: relative;
  padding-top: 64%;
}
.page-salon__img div img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.page-salon__text {
  width: 50%;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .page-salon__text {
    margin-top: unset;
    width: 100%;
  }
}
.page-salon__name {
  font-size: 1.75rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #000;
  padding-bottom: 0.625rem;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .page-salon__name {
    font-size: 1.375rem;
    padding-bottom: 0.3125rem;
    margin-bottom: 0.625rem;
  }
}
.page-salon__name span {
  font-family: "Marcellus", serif;
}
.page-salon__info {
  padding-left: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .page-salon__info {
    padding: 0 0.9375rem;
  }
}
.page-salon__address {
  font-size: 1.125rem;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 2.2222222222;
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .page-salon__address {
    font-size: 0.875rem;
    margin-bottom: 0.9375rem;
  }
}
.page-salon__tel {
  display: block;
  color: #000;
  font-size: 2rem;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-family: "Marcellus", serif;
  margin-bottom: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .page-salon__tel {
    font-size: 1.25rem;
    margin-bottom: 1.5625rem;
  }
}
.page-salon__tel span {
  font-size: 0.875rem;
  font-weight: 200;
  padding-left: 1.25rem;
}
@media screen and (max-width: 1024px) {
  .page-salon__tel span {
    letter-spacing: 0.01em;
    font-feature-settings: "palt";
    padding-left: 0.9375rem;
  }
}
@media screen and (max-width: 767px) {
  .page-salon__tel span {
    display: block;
    padding-left: unset;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 767px) {
  .page-salon .page-salon__tel span {
    display: block;
    padding-left: unset;
    margin-top: 0.3125rem;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
  }
}
.page-salon__tel i {
  padding-right: 0.3125rem;
}
.page-salon__more {
  margin-left: auto;
}

.service .page-top__ttl {
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .service .page-top__ttl {
    margin-bottom: 0.625rem;
  }
}

.ps-read img {
  object-fit: cover;
  height: 100%;
}
.ps-read picture {
  height: 100%;
  object-fit: cover;
}
.ps-read__container {
  padding: 7.5rem 0;
  display: flex;
  position: relative;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .ps-read__container {
    max-width: 37.5rem;
    margin: 0 auto;
    flex-direction: column;
    padding: 3.75rem 1.25rem;
    gap: 1.875rem;
    align-items: unset;
  }
}
.ps-read__img1 {
  padding-right: 6.875rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .ps-read__img1 {
    width: 100%;
    padding-right: unset;
    aspect-ratio: 3/2;
    overflow: hidden;
  }
}
.ps-read__text {
  width: 50%;
  max-width: 38.75rem;
  padding-right: 1.25rem;
  margin-top: -12.5rem;
}
@media screen and (max-width: 767px) {
  .ps-read__text {
    width: 100%;
    max-width: unset;
    padding-right: unset;
    margin-top: unset;
  }
}
.ps-read__catch {
  font-size: 2.625rem;
  font-weight: 500;
  line-height: 1.4285714286;
  font-family: shippori-mincho, sans-serif;
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .ps-read__catch {
    font-size: 1.375rem;
    margin-bottom: 1.25rem;
  }
}
.ps-read__des {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  font-family: shippori-mincho, sans-serif;
  line-height: 2.2222222222;
}
@media screen and (max-width: 1024px) {
  .ps-read__des {
    font-feature-settings: "palt";
  }
}
@media screen and (max-width: 767px) {
  .ps-read__des {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    font-feature-settings: unset;
  }
}
.ps-read__des:last-of-type {
  margin-bottom: unset;
}
@media screen and (max-width: 767px) {
  .ps-read__des:last-of-type {
    margin-bottom: 0.625rem;
  }
}
.ps-read__img2 {
  position: absolute;
  top: 43%;
  right: 0;
  width: 15%;
  aspect-ratio: 1;
}
@media screen and (max-width: 767px) {
  .ps-read__img2 {
    position: unset;
    width: 45%;
    margin-right: auto;
  }
}
.ps-read__img3 {
  position: absolute;
  aspect-ratio: 330/380;
  width: 18%;
  bottom: -3.75rem;
  left: 50%;
  transform: translateX(50%);
}
@media screen and (max-width: 767px) {
  .ps-read__img3 {
    left: unset;
    bottom: -1.875rem;
    transform: unset;
    right: 0;
    width: 40%;
  }
}

.ps-ele {
  background: #f9f9f9;
}
.ps-ele__inner {
  max-width: 75rem;
  padding: 0 3.75rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .ps-ele__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.ps-ele__container {
  padding: 10rem 0 7.5rem;
  display: flex;
}
@media screen and (max-width: 767px) {
  .ps-ele__container {
    padding: 5rem 0 3.75rem;
    gap: 1.25rem;
    flex-direction: column;
  }
}
.ps-ele__texts {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .ps-ele__texts {
    width: 100%;
  }
}
.ps-ele__top {
  display: flex;
  gap: 0.625rem;
  align-items: flex-end;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .ps-ele__top {
    margin-bottom: 1.25rem;
  }
}
.ps-ele__top-left {
  font-size: 15rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  line-height: 0.73;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: url("../img/service/element1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767px) {
  .ps-ele__top-left {
    line-height: 0.87;
    font-size: 5.625rem;
  }
}
.ps-ele__top-right {
  font-family: "Marcellus", serif;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .ps-ele__top-right {
    font-size: 2.25rem;
  }
}
.ps-ele__top-right span {
  display: block;
  font-size: 1.3125rem;
  line-height: 1.9047619048;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .ps-ele__top-right span {
    font-size: 1.125rem;
  }
}
.ps-ele__bottom {
  font-size: 1.125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 2.2222222222;
  letter-spacing: 0.05em;
  font-feature-settings: "palt";
}
@media screen and (max-width: 767px) {
  .ps-ele__bottom {
    font-size: 1rem;
  }
}
.ps-ele__contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.1875rem 1.875rem;
  width: calc(50% - 8.125rem);
  margin-left: 8.125rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .ps-ele__contents {
    width: 100%;
    margin-left: unset;
    gap: 1.25rem;
  }
}
.ps-ele__contents::after {
  position: absolute;
  content: "";
  display: block;
  border-radius: 50%;
  border: 3px solid #FFF;
  width: 23.75rem;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .ps-ele__contents::after {
    width: 15.625rem;
    border: 2px solid #FFF;
  }
}
.ps-ele__content {
  position: relative;
  z-index: 2;
  border-radius: 50%;
  aspect-ratio: 1;
  background-size: cover;
  background-repeat: no-repeat;
}
.ps-ele__content:nth-of-type(1) {
  background-image: url("../img/service/element2.png");
}
.ps-ele__content:nth-of-type(2) {
  background-image: url("../img/service/element3.png");
}
.ps-ele__content:nth-of-type(3) {
  background-image: url("../img/service/element4.png");
}
.ps-ele__content:nth-of-type(4) {
  background-image: url("../img/service/element5.png");
}
.ps-ele__content-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0.05em;
  width: 90%;
  text-align: center;
}
.ps-ele__content-text1 {
  color: #d7c4ca;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-family: "Marcellus", serif;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .ps-ele__content-text1 {
    font-size: 0.75rem;
    margin-bottom: 0.4375rem;
  }
}
.ps-ele__content-text2 {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.25;
}
@media screen and (max-width: 767px) {
  .ps-ele__content-text2 {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 767px) {
  .ps-service {
    overflow: hidden;
  }
}
.ps-service__inner {
  max-width: 96.875rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .ps-service__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.ps-service__container {
  padding: 6.875rem 0 10.625rem;
}
@media screen and (max-width: 767px) {
  .ps-service__container {
    padding: 2.5rem 0 3.75rem;
  }
}
.ps-service__ttl {
  margin-bottom: 7.5rem;
  font-size: 5.625rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .ps-service__ttl {
    font-size: 2.625rem;
    margin-bottom: 2.5rem;
  }
}
.ps-service__contents {
  max-width: 75rem;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .ps-service__contents {
    max-width: unset;
    margin: unset;
  }
}
.ps-service__contents::after {
  z-index: -1;
  position: absolute;
  height: 108%;
  width: 1px;
  content: "";
  background: #dddddd;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .ps-service__contents::after {
    height: 103%;
  }
}
.ps-service__content {
  display: flex;
  align-items: center;
  position: relative;
  padding: 4.375rem 0;
  gap: 8.125rem;
  margin-bottom: 4.375rem;
}
@media screen and (max-width: 767px) {
  .ps-service__content {
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.875rem 0;
    margin-bottom: 2.5rem;
  }
}
.ps-service__img {
  height: 100%;
  width: 58%;
}
@media screen and (max-width: 767px) {
  .ps-service__img {
    height: auto;
    width: 100%;
  }
}
.ps-service__texts {
  width: 36%;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .ps-service__texts {
    width: 100%;
  }
}
.ps-service__text1 {
  margin-bottom: 1.25rem;
  font-size: 2rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .ps-service__text1 {
    margin-bottom: 0.625rem;
    font-size: 1.5rem;
  }
}
.ps-service__text2 {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.05em;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .ps-service__text2 {
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }
}
.ps-service__text3 {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 2.1428571429;
  text-align: left;
}
.ps-service__bgimg {
  position: absolute;
  top: 0;
  left: -9.375rem;
  height: 100%;
  width: 23.75rem;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .ps-service__bgimg {
    left: -3.125rem;
    width: 50%;
    height: 74vw;
    max-height: 26.875rem;
  }
}
.ps-service__bgimg picture, .ps-service__bgimg img {
  height: 100%;
}
.ps-service__content:nth-of-type(2n) {
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .ps-service__content:nth-of-type(2n) {
    flex-direction: column;
  }
}
.ps-service__content:nth-of-type(2n) .ps-service__bgimg {
  left: unset;
  right: -9.375rem;
}
@media screen and (max-width: 767px) {
  .ps-service__content:nth-of-type(2n) .ps-service__bgimg {
    right: -3.125rem;
  }
}

.ps-pick__img {
  aspect-ratio: 1680/680;
  width: 100%;
}

.ps-pick {
  overflow: hidden;
}
.ps-pick__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .ps-pick__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.ps-pick__container {
  padding: 6.25rem 0 7.5rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .ps-pick__container {
    padding: 1.25rem 0 3.75rem;
  }
}
.ps-pick__top {
  display: flex;
  margin-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__top {
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 2.5rem;
  }
}
.ps-pick__left {
  width: 50%;
  padding-right: 1.25rem;
  text-align: left;
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__left {
    width: 100%;
    padding-right: unset;
    margin-bottom: 1.25rem;
  }
}
.ps-pick__ttl {
  font-weight: 500;
  font-size: 3.75rem;
  font-family: shippori-mincho, sans-serif;
  line-height: 1.6;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__ttl {
    font-size: 2rem;
  }
}
.ps-pick__ttl span {
  display: block;
  font-size: 2.625rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  line-height: 0.85;
}
@media screen and (max-width: 767px) {
  .ps-pick__ttl span {
    font-size: 1.5rem;
  }
}
.ps-pick__left-text {
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .ps-pick__left-text {
    font-size: 0.9375rem;
  }
}
.ps-pick__right {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .ps-pick__right {
    width: 100%;
  }
}
.ps-pick__right-text1 {
  margin-bottom: 0.9375rem;
  font-size: 1.3125rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .ps-pick__right-text1 {
    font-size: 1.125rem;
    margin-bottom: 0.625rem;
  }
}
.ps-pick__right-text2 {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 2.1428571429;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__right-text2 {
    margin-bottom: 0.625rem;
  }
}
.ps-pick__buttons {
  display: flex;
  gap: 0.625rem;
  width: 80%;
}
@media screen and (max-width: 767px) {
  .ps-pick__buttons {
    width: 100%;
  }
}
.ps-pick__button {
  width: 48%;
  text-align: center;
  border-radius: 0.3125rem;
  background: #efefef;
  font-size: 0.9375rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  padding: 0.625rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__button {
    letter-spacing: unset;
    font-size: 0.8125rem;
    padding: 0.625rem 0.5rem;
  }
}
.ps-pick__contents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__contents {
    grid-template-columns: 1fr;
    gap: 1.5625rem;
  }
}
.ps-pick__content {
  border-radius: 0.625rem;
  background: linear-gradient(rgba(255, 196, 191, 0.5), rgba(206, 236, 233, 0.5));
  padding: 3.75rem 3.25rem;
}
@media screen and (max-width: 767px) {
  .ps-pick__content {
    padding: 1.875rem;
    border-radius: 0.3125rem;
  }
}
.ps-pick__content-read {
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 1.875rem;
  font-size: 1.625rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .ps-pick__content-read {
    margin-bottom: 0.9375rem;
    font-size: 1.25rem;
  }
}
.ps-pick__content-read span {
  display: block;
  font-size: 1rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .ps-pick__content-read span {
    font-size: 0.9375rem;
  }
}
.ps-pick__content-text {
  font-size: 0.875rem;
  font-weight: 200;
  line-height: 2.1428571429;
}
@media screen and (max-width: 767px) {
  .ps-pick__content-text {
    line-height: 1.5714285714;
  }
}
.ps-pick__bg-text {
  position: absolute;
  top: 3.125rem;
  left: 8%;
  font-size: 6.25rem;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
  background: linear-gradient(90deg, #f8f8f8, #fdefe3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .ps-pick__bg-text {
    top: 1.25rem;
    width: 100%;
    font-size: 2.5rem;
    left: 68%;
  }
}

.staff .page-top {
  margin-bottom: 11.25rem;
}
@media screen and (max-width: 767px) {
  .staff .page-top {
    margin-bottom: 1.875rem;
  }
}
.staff .page-top__inner {
  position: relative;
}
.staff .page-top__ttl {
  line-height: 1;
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .staff .page-top__ttl {
    margin-bottom: 1.25rem;
  }
}
.staff .page-top__inner::after {
  position: absolute;
  content: "";
  display: block;
  max-width: 78.125rem;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 25px;
  background: #eeeeee;
}
@media screen and (max-width: 767px) {
  .staff .page-top__inner::after {
    max-width: calc(100% - 2.5rem);
    left: 1.25rem;
  }
}

.staff .page-read__img {
  aspect-ratio: 595/400;
  width: 33%;
}
@media screen and (max-width: 767px) {
  .staff .page-read__img {
    width: 100%;
  }
}
.staff .page-read__read {
  margin-top: 4.6875rem;
  letter-spacing: 0.05em;
  padding-right: 8.125rem;
}
@media screen and (max-width: 767px) {
  .staff .page-read__read {
    margin-top: 0.9375rem;
    padding-right: unset;
    font-size: 1.25rem;
  }
}
.staff .page-read__bg-text {
  top: -6.5625rem;
  left: 21%;
}
@media screen and (max-width: 767px) {
  .staff .page-read__bg-text {
    top: -1.25rem;
    z-index: -1;
    left: 60%;
    font-size: 2.5rem;
  }
}

.ps-select {
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .ps-select {
    margin-bottom: 3.75rem;
  }
}
.ps-select__lists {
  display: flex;
  gap: 3.125rem;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .ps-select__lists {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.25rem;
    padding: 0 20px;
    max-width: 37.5rem;
    margin: 0 auto;
  }
}
.ps-select__list {
  width: fit-content;
  border-bottom: 1px solid #000;
}
@media screen and (max-width: 767px) {
  .ps-select__list {
    width: unset;
    text-align: center;
  }
}
.ps-select__list a {
  display: block;
  padding-bottom: 0.625rem;
  line-height: 1;
  color: #000;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 767px) {
  .ps-select__list a {
    font-size: 1.125rem;
    padding-bottom: 0.4375rem;
  }
}

.ps-store:nth-of-type(2n) {
  background: #FFF;
}
.ps-store:nth-of-type(2n) .ps-store__ttl {
  background: #FFF;
}

.ps-store:nth-of-type(2n-1) {
  background: #f1f1f1;
}
.ps-store:nth-of-type(2n-1) .ps-store__ttl {
  background: #f1f1f1;
}

.ps-store__inner {
  max-width: 78.125rem;
  padding: 0 25px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .ps-store__inner {
    max-width: 37.5rem;
    padding: 0 20px;
  }
}
.ps-store__container {
  padding: 3.125rem 0 7.5rem;
}
@media screen and (max-width: 767px) {
  .ps-store__container {
    padding: 1.875rem 0 3.125rem;
  }
}
.ps-store__ttl {
  position: relative;
  font-size: 3.75rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
  line-height: 1;
  display: block;
  width: fit-content;
  transform: rotate(90deg);
  transform-origin: bottom left;
  padding-right: 1.875rem;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .ps-store__ttl {
    font-size: 2.25rem;
    padding-right: 0.625rem;
  }
}
.ps-store__staffs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.4375rem 3.125rem;
  padding-left: 8.75rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .ps-store__staffs {
    grid-template-columns: 1fr;
    gap: 2.5rem;
    padding-left: 3.125rem;
  }
}
.ps-store__staffs::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 2.0625rem;
  height: 100%;
  width: 1px;
  background: #000;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .ps-store__staffs::before {
    left: 0.75rem;
  }
}
.ps-store__img {
  margin-bottom: 2.5rem;
  position: relative;
  padding-top: 125%;
}
@media screen and (max-width: 767px) {
  .ps-store__img {
    margin-bottom: 1.25rem;
  }
}
.ps-store__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ps-store__role {
  width: fit-content;
  background: #000;
  color: #FFF;
  line-height: 1;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-weight: 400;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .ps-store__role {
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    padding: 0.375rem 0.5625rem;
  }
}
.ps-store__name {
  font-size: 1.875rem;
  letter-spacing: 0.05em;
  font-weight: 700;
  font-family: shippori-mincho, sans-serif;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .ps-store__name {
    font-size: 1.5rem;
  }
}
.ps-store__name span {
  color: #aaaaaa;
  font-size: 1rem;
  padding-left: 0.3125rem;
  font-family: shippori-mincho, sans-serif;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .ps-store__name span {
    font-size: 0.9375rem;
  }
}

.top-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: #f9eeed;
}
.top-loading--2 {
  background: #FFF;
  z-index: 99;
  height: 110vh;
  transform: skewY(5deg);
}
.top-loading__ttl {
  overflow: hidden;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 101;
  font-size: 3.125rem;
}
@media screen and (max-width: 1024px) {
  .top-loading__ttl {
    font-size: 4.375rem;
  }
}
@media screen and (max-width: 767px) {
  .top-loading__ttl {
    font-size: 1.5625rem;
  }
}
.top-loading__ttl span {
  font-size: 3.125rem;
  display: block;
  font-family: copperplate, serif;
  letter-spacing: 0.1em;
  opacity: 0;
}
@media screen and (max-width: 1024px) {
  .top-loading__ttl span {
    font-size: 4.375rem;
  }
}
@media screen and (max-width: 767px) {
  .top-loading__ttl span {
    font-size: 1.5625rem !important;
  }
}

.top-mv {
  position: relative;
  height: 100vh;
  min-height: 50rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-mv {
    min-height: unset;
    height: auto !important;
  }
}
@media screen and (max-width: 1024px) {
  .top-mv {
    height: 45vh;
  }
}
.top-mv__img1 {
  position: absolute;
  bottom: 0;
  width: 56%;
  max-width: 60rem;
  aspect-ratio: 960/800;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .top-mv__img1 {
    position: unset;
    aspect-ratio: 640/540;
    width: 90%;
    margin: 5.625rem 0 1.875rem;
  }
}
.top-mv__img1 img {
  display: block;
}
.top-mv__img2 {
  position: absolute;
  width: 38%;
  top: 0;
  right: 0;
  aspect-ratio: 635/590;
  max-width: 39.6875rem;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .top-mv__img2 {
    width: 43%;
    z-index: -1;
    aspect-ratio: 330/485;
  }
}
.top-mv__img2 picture, .top-mv__img2 picture img {
  height: 100%;
}
.top-mv__contents {
  position: absolute;
  left: 50%;
  top: 21%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .top-mv__contents {
    position: unset;
    margin-left: 10vw;
  }
}
.top-mv__read1 {
  font-size: 9.375rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  color: #000;
  line-height: 1;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-mv__read1 {
    font-size: 3.75rem;
    margin-bottom: 0.3125rem;
  }
}
.top-mv__read1:last-of-type {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .top-mv__read1:last-of-type {
    margin-bottom: 1.25rem;
  }
}
.top-mv__read1 span {
  font-size: 9.375rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  color: #000;
  display: block;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .top-mv__read1 span {
    font-size: 3.75rem;
  }
}
.top-mv__read2 {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  font-family: "Marcellus", serif;
  color: #000;
  overflow: hidden;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .top-mv__read2 {
    font-size: 0.625rem;
    margin-bottom: 0.3125rem;
  }
}
.top-mv__read2 span {
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  font-family: "Marcellus", serif;
  color: #000;
  display: block;
}
@media screen and (max-width: 767px) {
  .top-mv__read2 span {
    font-size: 0.625rem;
  }
}
.top-mv__read3 {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  overflow: hidden;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .top-mv__read3 {
    font-size: 0.9375rem;
  }
}
.top-mv__read3 span {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  display: block;
}
@media screen and (max-width: 767px) {
  .top-mv__read3 span {
    font-size: 0.9375rem;
  }
}
.top-mv__scroll-container {
  position: absolute;
  right: 7%;
  bottom: 4%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .top-mv__scroll-container {
    bottom: 29%;
    right: 2%;
  }
}
.top-mv__scroll {
  aspect-ratio: 1;
  height: 12.5rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-mv__scroll {
    height: 7.8125rem;
  }
}
.top-mv__scroll-text {
  animation: scroll 10s infinite linear;
}
.top-mv__scroll-arrow {
  position: absolute;
  width: 0.9375rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .top-mv__scroll-arrow {
    width: 0.875rem;
  }
}

.top-news {
  margin-top: 7.5rem;
  background: #f9f9f9;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-news {
    margin-top: 2.5rem;
  }
}
.top-news__inner {
  width: 90%;
  max-width: 75rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .top-news__inner {
    max-width: 37.5rem;
  }
}
.top-news__container {
  padding: 7.5rem 0 8.75rem;
}
@media screen and (max-width: 767px) {
  .top-news__container {
    padding: 3.75rem 0 4.375rem;
  }
}
.top-news__top {
  position: relative;
}
.top-news__ttl {
  overflow: hidden;
  text-align: center;
  margin-bottom: 5.9375rem;
  display: block;
  line-height: 1;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-family: "Marcellus", serif;
  font-size: 2.625rem;
}
@media screen and (max-width: 767px) {
  .top-news__ttl {
    font-size: 1.3125rem;
    margin-bottom: 1.5625rem;
  }
}
.top-news__ttl span {
  display: block;
  line-height: 1;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-family: "Marcellus", serif;
  font-size: 2.625rem;
}
@media screen and (max-width: 767px) {
  .top-news__ttl span {
    font-size: 1.3125rem;
  }
}
.top-news__view-more {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  color: #000;
  font-family: "Marcellus", serif;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .top-news__view-more {
    position: inherit;
    display: block;
    margin-bottom: 0.9375rem;
  }
}
.top-news__view-more:hover {
  color: #f48654;
}
.top-news__cards {
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .top-news__cards {
    margin-bottom: 1.5625rem;
  }
}
.top-news .swiper {
  overflow: hidden;
}
.top-news .swiper-scrollbar {
  background-color: #edebeb;
  position: unset;
  height: 0.125rem;
}
.top-news .swiper-scrollbar-drag {
  background-color: #000;
  height: 0.125rem;
}

.material-symbols-outlined {
  font-size: 1.125rem;
  vertical-align: sub;
  padding-right: 0.1875rem;
}
@media screen and (max-width: 767px) {
  .material-symbols-outlined {
    font-size: 0.875rem;
  }
}

.top-concept {
  position: relative;
  padding-bottom: 5.625rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-concept {
    padding-bottom: unset;
  }
}
.top-concept__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-concept__inner {
    width: 100%;
  }
}
.top-concept__container {
  padding: 9.375rem 0 6.25rem;
}
@media screen and (max-width: 767px) {
  .top-concept__container {
    padding: 3.75rem 0 7.5rem;
  }
}
.top-concept__contents {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .top-concept__contents {
    gap: 2.5rem;
    flex-direction: column-reverse;
  }
}
.top-concept__content {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .top-concept__content {
    width: 90%;
  }
}
.top-concept__content:nth-of-type(1) {
  margin: 0 auto;
  max-width: 37.5rem;
}
.top-concept__img {
  aspect-ratio: 2/3;
  max-height: 37.5rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-concept__img {
    aspect-ratio: 670/540;
    margin-right: auto;
  }
}
@media screen and (max-width: 767px) {
  .top-concept__img img {
    zoom: 120%;
  }
}
.top-concept__ttl {
  margin-top: 1.875rem;
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .top-concept__ttl {
    margin-top: unset;
    margin-bottom: 1.6rem;
  }
}
.top-concept__read {
  font-size: 2rem;
  letter-spacing: 0.1em;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  line-height: 1.875;
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .top-concept__read {
    font-size: 1.125rem;
    margin-bottom: 2.2rem;
  }
}
.top-concept__text {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
  margin-bottom: 3.75rem;
}
@media screen and (max-width: 767px) {
  .top-concept__text {
    margin-bottom: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  .top-concept__view-more {
    margin-left: auto;
  }
}
.top-concept__bg-text {
  position: absolute;
  bottom: -5%;
  left: 15%;
  background: linear-gradient(90deg, #f8f8f8, #fdefe3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .top-concept__bg-text {
    bottom: 4%;
  }
}
.top-concept__bg-img {
  position: absolute;
  top: 0;
  height: 90%;
  width: 42vw;
  right: 0;
  z-index: -2;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-concept__bg-img {
    width: 100%;
    aspect-ratio: 750/540;
    height: auto;
    overflow: -webkit-paged-y;
  }
}

.top-salon {
  background: #f1f1f1;
  z-index: -2;
}
@media screen and (max-width: 767px) {
  .top-salon {
    overflow: hidden;
  }
}
.top-salon__inner {
  position: relative;
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .top-salon__inner {
    max-width: 37.5rem;
  }
}
.top-salon__container {
  padding: 9.375rem 0 5.625rem;
}
@media screen and (max-width: 767px) {
  .top-salon__container {
    padding: 3.75rem 0 5rem;
  }
}
.top-salon__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .top-salon__top {
    flex-direction: column;
    margin-bottom: 5.3125rem;
  }
}
.top-salon__ttl {
  line-height: 1;
}
.top-salon__read {
  font-size: 2rem;
  font-family: shippori-mincho, sans-serif;
  margin-bottom: 0.625rem;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .top-salon__read {
    letter-spacing: 0.1em;
    font-size: 1rem;
    margin-bottom: 0.3125rem;
  }
}
.top-salon__read2 {
  font-size: 0.875rem;
  font-family: "noto-sans-cjk-jp", sans-serif;
  display: block;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .top-salon__read2 {
    text-align: left;
    letter-spacing: 0.05em;
    font-size: 0.625rem;
  }
}
.top-salon__cards {
  max-width: 56.25rem;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
}
@media screen and (max-width: 767px) {
  .top-salon__cards {
    max-width: unset;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.625rem;
    row-gap: 1.875rem;
    margin-bottom: 0.625rem;
  }
}
.top-salon__view-more {
  margin-left: auto;
  transform: translateY(-100%);
}
@media screen and (max-width: 767px) {
  .top-salon__view-more {
    transform: unset;
  }
}
.top-salon__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 57.5rem;
  height: 100%;
  background: #f1f1f1;
  overflow: hidden;
  transform: translate(-25%);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .top-salon__bg {
    width: 29.375rem;
    transform: translate(-5.625rem, -1.75rem);
  }
}
.top-salon__bg-container {
  position: relative;
}
.top-salon__bg-point {
  position: absolute;
  aspect-ratio: 1;
  width: 0.8125rem;
  border-radius: 50%;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point {
    width: 0.625rem;
  }
}
.top-salon__point-wave::before {
  position: absolute;
  content: "";
  display: block;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  width: 1.5625rem;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50%;
  background: radial-gradient(#f9d8c9 0% 100%);
  animation: point-map 4s ease-in-out infinite;
}
@media screen and (max-width: 767px) {
  .top-salon__point-wave::before {
    width: 0.875rem;
  }
}
.top-salon__point-wave::after {
  position: absolute;
  content: "";
  display: block;
  aspect-ratio: 1;
  z-index: 2;
  left: 50%;
  top: 50%;
  width: 0.8125rem;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50%;
  background: radial-gradient(#f48654 0 30%, #f7bfa5 30% 100%);
}
@media screen and (max-width: 767px) {
  .top-salon__point-wave::after {
    width: 0.5rem;
  }
}
.top-salon__bg-point--1 {
  top: 25rem;
  left: 23.75rem;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point--1 {
    top: 13.125rem;
    left: 11.875rem;
  }
}
.top-salon__bg-point--2 {
  top: 25.625rem;
  left: 21.875rem;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point--2 {
    top: 13.4375rem;
    left: 11.25rem;
  }
}
.top-salon__bg-point--3 {
  top: 26.5625rem;
  left: 27.5rem;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point--3 {
    top: 13.875rem;
    left: 13.75rem;
  }
}
.top-salon__bg-point--4 {
  top: 26.25rem;
  left: 24.6875rem;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point--4 {
    top: 13.75rem;
    left: 12.3125rem;
  }
}
.top-salon__bg-point--5 {
  top: 27.5rem;
  left: 23.125rem;
}
@media screen and (max-width: 767px) {
  .top-salon__bg-point--5 {
    top: 14.375rem;
    left: 11.5625rem;
  }
}

.salon-card {
  display: block;
}
.salon-card__img {
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .salon-card__img {
    margin-bottom: 0.3125rem;
  }
}
.salon-card__img div {
  position: relative;
  padding-top: 64%;
}
.salon-card__img div img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.salon-card__name {
  font-size: 1.3125rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  margin-bottom: 0.625rem;
  color: #000;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .salon-card__name {
    font-size: 1rem;
    margin-bottom: 0.3125rem;
  }
}
.salon-card__address {
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  line-height: 1.25;
  color: #000;
  font-family: "Marcellus", serif;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .salon-card__address {
    font-size: 0.625rem;
  }
}

.salon-card:hover .salon-card__name {
  color: #f48654;
}
.salon-card:hover .salon-card__address {
  color: #f48654;
}

.top-service__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .top-service__inner {
    max-width: 37.5rem;
  }
}
.top-service__container {
  padding: 7.5rem 0 8.125rem;
}
@media screen and (max-width: 767px) {
  .top-service__container {
    padding: 5rem 0 3.4375rem;
  }
}
.top-service__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.875rem;
  margin-bottom: 5.625rem;
}
@media screen and (max-width: 767px) {
  .top-service__top {
    flex-direction: column;
    align-items: unset;
    margin-bottom: 1.875rem;
  }
}
.top-service__top-left {
  display: flex;
  gap: 1.875rem;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .top-service__top-left {
    flex-direction: column;
    gap: 0.9375rem;
    align-items: unset;
  }
}
.top-service__ttl {
  line-height: 1;
}
.top-service__read {
  font-size: 2rem;
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .top-service__read {
    font-size: 1rem;
  }
}
.top-service__read2 {
  font-size: 0.875rem;
  font-family: "noto-sans-cjk-jp", sans-serif;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .top-service__read2 {
    font-size: 0.625rem;
  }
}
.top-service__contents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.4375rem;
}
@media screen and (max-width: 767px) {
  .top-service__contents {
    grid-template-columns: 1fr 1fr;
    gap: 0.9375rem;
    margin-bottom: 1.875rem;
  }
}
.top-service__content {
  position: relative;
  aspect-ratio: 300/520;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-service__content {
    aspect-ratio: 1;
  }
}
.top-service__content:nth-of-type(2n) {
  transform: translateY(3.125rem);
}
@media screen and (max-width: 767px) {
  .top-service__content:nth-of-type(2n) {
    transform: unset;
  }
}
.top-service__content-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.75rem;
  font-family: "Marcellus", serif;
  letter-spacing: 0.05em;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .top-service__content-text {
    font-size: 1.875rem;
  }
}
.top-service__content-text2 {
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  color: #FFF;
  font-family: poppins, sans-serif;
}
@media screen and (max-width: 767px) {
  .top-service__content-text2 {
    font-size: 0.625rem;
    bottom: 5%;
  }
}
@media screen and (max-width: 767px) {
  .top-service__view-more {
    margin-left: auto;
  }
}

.top-pick__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .top-pick__inner {
    width: 100%;
  }
}
.top-pick__container {
  padding-bottom: 7.5rem;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .top-pick__container {
    display: block;
    padding-bottom: 3.75rem;
  }
}
.top-pick__img {
  width: 45%;
  max-width: 31.25rem;
  aspect-ratio: 1;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-pick__img {
    width: 100%;
    aspect-ratio: 75/50;
    max-width: unset;
  }
}
@media screen and (max-width: 767px) {
  .top-pick__img img {
    margin-top: -0.625rem;
  }
}
.top-pick__text {
  padding: 2.5rem 2.6875rem 4.375rem 6.25rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 767px) {
  .top-pick__text {
    font-feature-settings: unset;
    padding: 1.25rem 1.875rem 3.75rem;
    margin: 0 auto;
  }
}
.top-pick__text4 {
  transform: translateX(-1.25rem) rotate(90deg);
  transform-origin: left top;
  width: fit-content;
  font-size: 1.3125rem;
  letter-spacing: 0.05em;
  font-family: "Marcellus", serif;
  padding-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .top-pick__text4 {
    font-size: 0.625rem;
    transform: translateX(0) rotate(90deg);
    padding-left: unset;
  }
}
.top-pick__text1 {
  font-size: 2.625rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .top-pick__text1 {
    font-size: 1.875rem;
    text-align: center;
    margin-bottom: unset;
  }
}
.top-pick__text2 {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .top-pick__text2 {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.9375rem;
  }
}
.top-pick__text3 {
  font-size: 0.875rem;
  line-height: 14/30;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 767px) {
  .top-pick__text3 {
    line-height: 2;
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .top-pick__view-more {
    margin-left: auto;
  }
}

.webp .top-pick__text {
  background-image: url("../img/top/service-bg.jpg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.no-webp .top-pick__text {
  background-image: url("../img/top/service-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.top-product {
  height: 47.5rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-product {
    height: unset;
    width: 100%;
    aspect-ratio: 1;
  }
}
.top-product:hover .top-product__contents {
  transition: all 0.3 ease-in-out;
  width: 34.375rem;
}
@media screen and (max-width: 767px) {
  .top-product:hover .top-product__contents {
    width: 72%;
  }
}
.top-product__bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-product__bg picture, .top-product__bg picture img {
    height: 110%;
    width: 110%;
  }
}
.top-product__contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31.25rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .top-product__contents {
    width: 70%;
    max-width: 25rem;
  }
}
.top-product__text1 {
  font-family: copperplate, serif;
  font-size: 1.125rem;
  letter-spacing: 0.12em;
  line-height: 1;
  margin-bottom: 0.625rem;
  font-weight: 300;
}
@media screen and (max-width: 767px) {
  .top-product__text1 {
    font-size: 0.875rem;
    margin-bottom: 0.3125rem;
  }
}
.top-product__text2 {
  font-family: "Marcellus", serif;
  font-size: 5.625rem;
  letter-spacing: 0.05em;
  line-height: 1;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .top-product__text2 {
    font-size: 2.8125rem;
    margin-bottom: 0.4375rem;
  }
}
.top-product__text3 {
  font-family: "noto-sans-cjk-jp", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .top-product__text3 {
    font-size: 0.75rem;
    margin-bottom: 0.9375rem;
  }
}
.top-product__view-more {
  color: #FFF;
  margin: 0 auto;
}
.top-product__view-more .view-more__line {
  background: #FFF;
}

.top-gallery {
  overflow: hidden;
  padding: 8.125rem 0;
  background: #f9f9f9;
}
@media screen and (max-width: 767px) {
  .top-gallery {
    padding: 5.625rem 0;
  }
}
.top-gallery__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-gallery__inner {
    max-width: 37.5rem;
  }
}
.top-gallery__ttl {
  line-height: 0.73;
  max-width: 93.75rem;
  width: 100%;
  margin: 0 auto;
  color: #FFF;
}
@media screen and (max-width: 767px) {
  .top-gallery__ttl {
    padding-left: 0.625rem;
    white-space: nowrap;
  }
}
.top-gallery__view-more {
  position: absolute;
  top: -7.5rem;
  right: 0;
}
@media screen and (max-width: 767px) {
  .top-gallery__view-more {
    top: -4.375rem;
  }
}
.top-gallery__cards {
  display: flex;
  margin-bottom: 5rem;
}
@media screen and (max-width: 767px) {
  .top-gallery__cards {
    margin-bottom: 2.5rem;
  }
}
.top-gallery .swiper {
  overflow: hidden;
}
.top-gallery .swiper-scrollbar {
  background-color: #edebeb;
  position: unset;
  height: 0.125rem;
}
.top-gallery .swiper-scrollbar-drag {
  background-color: #000;
  height: 0.125rem;
}

.gallery-card__img {
  padding-top: 148.5%;
  position: relative;
}
.gallery-card__img img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.top-staff {
  height: 58vw;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .top-staff {
    height: unset;
    display: block;
  }
}
.top-staff__inner {
  max-width: 75rem;
  margin: 0 auto;
  width: 90%;
}
@media screen and (max-width: 767px) {
  .top-staff__inner {
    max-width: 37.5rem;
  }
}
.top-staff__contents {
  width: 100%;
  display: flex;
}
@media screen and (max-width: 767px) {
  .top-staff__contents {
    display: block;
  }
}
.top-staff__content {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .top-staff__content {
    width: 100%;
  }
}
.top-staff__ttl {
  line-height: 1;
  margin-bottom: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .top-staff__ttl {
    margin-bottom: 1.25rem;
  }
}
.top-staff__read {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.6666666667;
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .top-staff__read {
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
  }
}
.top-staff__text {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  font-family: "noto-sans-cjk-jp", sans-serif;
  line-height: 2.1428571429;
  margin-bottom: 1.875rem;
}
@media screen and (max-width: 767px) {
  .top-staff__text {
    margin-bottom: 1.5625rem;
  }
}
.top-staff__view-more {
  margin-left: auto;
  margin-bottom: 3.125rem;
}
.top-staff__img {
  position: absolute;
}
@media screen and (max-width: 767px) {
  .top-staff__img {
    position: unset;
  }
}
.top-staff__img--1 {
  top: 50%;
  left: 0;
  transform: translateY(-52%);
  width: 42vw;
  aspect-ratio: 725/700;
  overflow: hidden;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .top-staff__img--1 {
    width: 100%;
    top: unset;
    left: unset;
    transform: unset;
    aspect-ratio: 75/50;
    z-index: auto;
    margin-bottom: 2.5rem;
  }
}
.top-staff__img--2 {
  top: 0;
  right: 0;
  aspect-ratio: 530/300;
  width: 31vw;
  overflow: hidden;
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .top-staff__img--2 {
    width: 70vw;
    margin-left: auto;
  }
}
.top-staff__img--2 picture, .top-staff__img--2 picture img {
  height: 130%;
}
.top-staff__bg-ttl {
  position: absolute;
  bottom: 6.25rem;
  left: 50%;
  white-space: nowrap;
  background: linear-gradient(90deg, #f8f8f8, #fdefe3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .top-staff__bg-ttl {
    position: unset;
    left: unset;
    margin-left: 30vw;
    width: fit-content;
  }
}

.top-journal {
  overflow: hidden;
  margin-top: -27%;
  margin-bottom: 9.375rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-journal {
    margin-top: -55%;
    margin-bottom: 3.75rem;
  }
}
.top-journal__bg {
  width: 100%;
  aspect-ratio: 1680/560;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-journal__bg {
    aspect-ratio: 3/2;
  }
}
@media screen and (max-width: 767px) {
  .top-journal__bg picture, .top-journal__bg picture img {
    height: 120%;
  }
}
.top-journal__inner {
  max-width: 75rem;
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .top-journal__inner {
    max-width: 37.5rem;
  }
}
.top-journal__ttl {
  display: flex;
  align-items: center;
  font-size: 5.625rem;
  font-family: "Marcellus", serif;
  color: #FFF;
  margin-bottom: 4.375rem;
  letter-spacing: 0.05em;
  line-height: 1;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-journal__ttl {
    font-size: 2.8125rem;
    margin-bottom: 1.5625rem;
  }
}
.top-journal__ttl .ttl {
  font-size: 5.625rem;
  font-family: "Marcellus", serif;
}
@media screen and (max-width: 767px) {
  .top-journal__ttl .ttl {
    font-size: 2.8125rem;
  }
}
.top-journal__ttl span {
  display: block;
}
.top-journal__logo {
  vertical-align: middle;
  padding-right: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .top-journal__logo {
    padding-right: 0.9375rem;
  }
}
.top-journal__cards {
  display: flex;
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .top-journal__cards {
    margin-bottom: 1.25rem;
  }
}
.top-journal .swiper {
  overflow: hidden;
}
.top-journal .swiper-scrollbar {
  background-color: #edebeb;
  position: unset;
  height: 0.125rem;
}
.top-journal .swiper-scrollbar-drag {
  background-color: #000;
  height: 0.125rem;
}

.top-recruit {
  height: 46vw;
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 767px) {
  .top-recruit {
    height: auto;
    width: 100%;
    aspect-ratio: 1;
    margin-bottom: 3.75rem;
  }
}
.top-recruit__inner {
  max-width: 96.25rem;
  height: 100%;
  padding: 0 1.25rem;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-recruit__inner {
    max-width: 40rem;
  }
}
.top-recruit__bg {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-recruit__bg {
    width: 100%;
  }
}
.top-recruit__bg picture, .top-recruit__bg picture img {
  height: 120%;
}
.top-recruit__contents {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFF;
}
.top-recruit__ttl {
  margin-bottom: 2.5rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .top-recruit__ttl {
    margin-bottom: 1.25rem;
  }
}
.top-recruit__read {
  font-weight: 500;
  font-family: shippori-mincho, sans-serif;
  font-size: 2.25rem;
  margin-bottom: 1.875rem;
  letter-spacing: 0.1em;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .top-recruit__read {
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: 0.9375rem;
  }
}
.top-recruit__read--small {
  font-size: 1.5rem;
  font-family: shippori-mincho, sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .top-recruit__read--small {
    font-size: 0.875rem;
  }
}
.top-recruit__text {
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 2.1428571429;
  margin-bottom: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .top-recruit__text {
    font-size: 0.75rem;
    margin-bottom: 0.9375rem;
    text-align: left;
    padding: 0 0.9375rem;
  }
}
.top-recruit__view-more {
  color: #FFF;
  margin: 0 auto;
}
.top-recruit__view-more .view-more__line {
  background: #FFF;
}