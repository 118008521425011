@use "../utility" as *;
.product{
    picture,
    img{
        height: 100%;
        object-fit: cover;
    }
}

.product{
    .page-top__container{
        padding-top: rem(230);
        @include mq{
            padding-top: rem(100);
        }
    }
    .page-top__ttl{
        @include mq{
            margin-bottom: unset;
        }
    }
    .page-top__breads{
        margin-top: rem(20);
        @include mq{
            margin-top: rem(10);
        }
    }
}

.pp-read{
    background-image: url("../img/concept/style-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            padding: 0 $padding-sp;
            max-width: rem(600);
        }
    }
    &__container{
        padding: rem(110) 0 ;
        @include mq{
            padding: rem(50) 0 ;
        }
    }
    &__ttl{
        text-align: center;
        margin-bottom: rem(20);
        font-family: $fourth-font-family;
        font-size: rem(42);
        font-weight: $normal;
        letter-spacing: 0.1em;
        @include mq{
            font-size: rem(26);
            margin-bottom: rem(20);
            z-index: 1; 
            position: relative;
        }
    }
    &__read{
        text-align: center;
        font-size: rem(14);
        font-weight: $regular;
        line-height: calc(30/14);
        font-weight: $demilight;
        @include mq{
            text-align: left;
            z-index: 1; 
            position: relative;
        }
    }
}
.pp-select{
    margin: rem(160) 0;
    @include mq{
        margin: rem(70) 0;
    }
    &__lists{
        display: flex;
        gap: rem(50);
        justify-content: center;
        @include mq{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(20);
            padding: 0 $padding-sp;
            max-width: rem(600);
            margin: 0 auto;
        }
    }
    &__list{
        width: fit-content;
        border-bottom: 1px solid $black;
        @include mq{
            width: unset;
            text-align: center;
            white-space: nowrap;
        }
    }
    &__list a{
        display: block;
        padding-bottom: rem(10);
        line-height: 1;
        color: $black;
        font-size: rem(24);
        font-weight: $regular;
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(18);
            padding-bottom: rem(7);
        }
    }
}

.pp-product{
    margin-bottom: rem(150);
    @include mq{
        margin-bottom: rem(60);
    }
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            padding: 0 $padding-sp;
            max-width: rem(600);
        }
    }
    &__content{
        margin-bottom: rem(200);
        @include mq{
            margin-bottom: rem(100);
        }
    }
    &__content:last-of-type{
        margin-bottom: unset;
        @include mq{
            margin: unset;
        }
    }
    &__c-top{
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(80);
        @include mq{
            flex-direction: column;
            margin-bottom: rem(30);
        }
    }
    &__c-texts{
        width: 42%;
        padding: rem(90) rem(40) rem(100);
        background-image: url("../img/product/product-bg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        @include mq{
            width: 100%;
            margin: 0 auto ;
            padding: rem(50) rem(15);
        }
    }
    &__c-ttl{
        margin-bottom: rem(20);
        font-size: rem(48);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        text-align: center;
        line-height: 1.5;
        @include mq{
            margin-bottom: rem(15);
            font-size:rem(24) ;
        }
    }
    &__c-read{
        margin-bottom: rem(20);
        font-size: rem(24);
        font-family: $fourth-font-family;
        // letter-spacing: 0.1em;
        font-weight: $normal;
        text-align: center;
        @include mq{
            font-size: rem(18);
            margin-bottom:rem(10);
        }
    }
    &__c-text{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(25/14);
        margin-bottom: rem(40);
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(20);
        }
    }
    &__c-link{
        display: block;
        font-size: rem(16);
        font-family: $third-font-family;
        font-weight: $regular;
        letter-spacing: 0.05em;
        color: $black;
        border-bottom: 1px solid $black;
        width: fit-content;
        margin:0 auto;
        padding: rem(8) 0;
        @include mq{
            font-size: rem(14);
        }
    }
    &__c-link i{
        padding-left: rem(10);
    }
    &__c-img{
        width: 58%;
        margin-top:rem(80) ;
        aspect-ratio: 70/48;
        @include mq{
            margin-top: unset;
            width: 100%;
        }
    }
    &__c-bottom{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap:rem(40) ;

        @include mq{
            grid-template-columns: 1fr;
            gap: rem(35);
        }
    }
    &__ci-img{
        aspect-ratio: 1;
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(10);
        }
    }
    &__ci-name{
        font-family: $fourth-font-family;
        font-size: rem(24);
        font-weight: $normal;
        font-feature-settings: 'palt';
        text-align: center;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(20);
        }
    }

}