@use "../utility" as *;
// loading
.top-loading{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background:#f9eeed ;    
    &--2{
        background: $white;
        z-index: 99;
        height: 110vh;
        transform: skewY(5deg);
    }
    &__ttl{
        overflow: hidden;
        position: fixed;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%,-50%);
        z-index: 101;
        font-size: rem(50);
        @include mq("tb"){
            font-size: rem(70);
        }
        @include mq{
            font-size: rem(25);
        }
        
    }
    &__ttl span{
        font-size: rem(50);
        display: block;
        font-family: $second-font-family;
        letter-spacing: 0.1em;
        opacity: 0;
        @include mq(tb){
            font-size: rem(70);
        }
        @include mq{
            font-size: rem(25) !important;
        }
        
    }
}

// mv

.top-mv{
    position: relative;
    height: 100vh;
    min-height: rem(800);
    overflow: hidden;
    @include mq{
        min-height: unset;
        height: auto !important;
    }
    @include mq(tb){
        height: 45vh;
    }
    
    &__img1{
        position: absolute;
        bottom: 0;
        width: 56%;
        max-width: rem(960);
        aspect-ratio:960/800;
        z-index: 1;
        @include mq{
            position: unset;
            aspect-ratio: 640/540;
            width: 90%;
            margin: rem(90) 0 rem(30);
        }
    }
    &__img1 img{
        display: block;
    }
    &__img2{
        position: absolute;
        width: 38%;
        top: 0;
        right: 0;
        aspect-ratio: 635/590;
        max-width: rem(635);
        z-index: 0;
        @include mq{
            width: 43%;
            z-index: -1;
            aspect-ratio: 330/485;
        }
    }
    &__img2 picture,
    &__img2 picture img{
        height: 100%;
    }
    &__contents{
        position: absolute;
        left: 50%;
        top: 21%;
        z-index: 1;
        @include mq{
            position: unset;
            margin-left: 10vw;
        }
    }
    &__read1{
        font-size: rem(150);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        color: $black;
        line-height: 1;
        overflow: hidden;
        @include mq{
            font-size: rem(60);
            margin-bottom: rem(5);
        }
    }
    &__read1:last-of-type{
        margin-bottom: rem(40);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__read1 span{
        font-size: rem(150);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        color: $black;
        display: block;
        line-height: 1;
        @include mq{
            font-size: rem(60);
        }
    }
    &__read2{
        font-size: rem(12);
        letter-spacing: 0.1em;
        font-family: $third-font-family;
        color: $black;
        overflow: hidden;
        margin-bottom: rem(10);
        @include mq{
            font-size: rem(10);
            margin-bottom: rem(5);
        }
    }
    &__read2 span{
        font-size: rem(12);
        letter-spacing: 0.1em;
        font-family: $third-font-family;
        color: $black;
        display: block;
        @include mq{
            font-size: rem(10);
        }
    }
    &__read3{
        font-size: rem(24);
        font-family: $fourth-font-family;
        overflow: hidden;
        font-weight: $normal;
        @include mq{
            font-size: rem(15);
        }
    }
    &__read3 span{
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        display: block;
        @include mq{
            font-size: rem(15);
        }
    }
    &__scroll-container{
        position: absolute;
        right: 7%;
        bottom: 4%;
        z-index: 1;
        @include mq{
            bottom: 29%;
            right: 2%;
        }
    }
    &__scroll{
        aspect-ratio: 1;
        height: rem(200);
        position: relative;
        @include mq{
            height: rem(125);
        }
    }
    &__scroll-text{
        animation: scroll 10s infinite linear;
    }
    &__scroll-arrow{
        position: absolute;
        width: rem(15);
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include mq{
            width: rem(14);
        }
    }
}


// news&topic
.top-news{
    margin-top: rem(120);
    background: $gray;
    overflow: hidden;
    @include mq{
        margin-top: rem(40);
    }
    &__inner{
        width: 90%;
        max-width: rem(1200);
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
        }
    }
    &__container{
        padding: rem(120) 0 rem(140);
        @include mq{
            padding: rem(60) 0 rem(70);
        }
    }
    &__top{
        position: relative;
    }
    &__ttl{
        overflow: hidden;
        text-align: center;
        margin-bottom: rem(95);
        display: block;
        line-height: 1;
        letter-spacing: 0.05em;
        font-weight: $regular;
        font-family: $third-font-family;
        font-size: rem(42);
        @include mq{
            font-size: rem(21);
            margin-bottom: rem(25);
        }
    }
    &__ttl span{
        display: block;
        line-height: 1;
        letter-spacing: 0.05em;
        font-weight: $regular;
        font-family: $third-font-family;
        font-size: rem(42);
        @include mq{
            font-size: rem(21);
        }
    }
    &__view-more{
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: rem(14);
        letter-spacing: 0.05em;
        color: $black;
        font-family: $third-font-family;
        transition:all 0.3s ease-in-out;
        @include mq{
            position: inherit;
            display: block;
            margin-bottom: rem(15);
        }
    }
    &__view-more:hover{
        color:$hover ;
    }
    &__cards{
        margin-bottom: rem(50);
        @include mq{
            margin-bottom: rem(25);
        }
    }
    & .swiper{
        overflow:hidden;
    }
    & .swiper-scrollbar{
        background-color: $gray2;
        position: unset;
        height: rem(2);
    }
    & .swiper-scrollbar-drag{
        background-color: $black;
        height: rem(2);
        
    }
}
.material-symbols-outlined{
    font-size: rem(18);
    vertical-align: sub;
    padding-right: rem(3);
    @include mq{
        font-size: rem(14);
    }
}

// concept
.top-concept{
    position: relative;
    padding-bottom: rem(90);
    overflow: hidden;
    @include mq{
        padding-bottom: unset;
    }
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        position: relative;
        @include mq{
            width: 100%;
        }
    }
    &__container{
        padding: rem(150) 0 rem(100);
        @include mq{
            padding: rem(60) 0 rem(120);
        }
    }
    &__contents{
        display: flex;
        align-items: flex-start;
        @include mq{
            gap: 2.5rem;
            flex-direction: column-reverse;
        }
    }
    &__content{
        width: 50%;
        @include mq{
            width: 90%;
        }
    }
    &__content:nth-of-type(1){
        margin: 0 auto;
        max-width: rem(600);
    }
    &__img{
        aspect-ratio: 2/3;
        max-height: rem(600);
        overflow: hidden;
        @include mq{
            aspect-ratio: 670/540;
            margin-right: auto;
        }
    }
    &__img img{
        @include mq{
            zoom: 120%;
        }
    }
    &__ttl{
        margin-top: rem(30);
        margin-bottom: rem(50);
        @include mq{
            margin-top: unset;
            margin-bottom: 1.6rem;
        }
    }
    &__read{
        font-size: rem(32);
        letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(60/32);
        margin-bottom: rem(25);
        @include mq{
            font-size: rem(18);
            margin-bottom: 2.2rem;
        }
    }
    &__text{
        font-size: rem(14);
        letter-spacing: 0.05em;
        line-height: calc(30/14);
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    &__view-more{
        @include mq{
            margin-left: auto;
        }
    }
    &__bg-text{
        position: absolute;
        bottom: -5%;
        left: 15%;
        background: linear-gradient(90deg,#f8f8f8,#fdefe3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
        z-index: -1;
        @include mq{
            bottom: 4%;
        }
    }
    &__bg-img{
        position: absolute;
        top: 0;
        height: 90%;
        width: 42vw;
        right: 0;
        z-index: -2;
        overflow: hidden;
        @include mq{
            width: 100%;
            aspect-ratio:750/540 ;
            height: auto;
            overflow: -webkit-paged-y;
        }
    }
}

// salon
.top-salon{
    background: #f1f1f1;
    z-index: -2;
    @include mq{
        overflow: hidden;
    }
    &__inner{
        position: relative;
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        z-index: 1;
        @include mq{
            max-width: rem(600);
        }
    }
    &__container{
        padding: rem(150) 0 rem(90);
        @include mq{
            padding: rem(60) 0 rem(80);
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: rem(50);
        @include mq{
            flex-direction: column;
            margin-bottom: rem(85);
        }
    }
    &__ttl{
        line-height: 1;
    }
    &__read{
        font-size: rem(32);
        font-family: $fourth-font-family;
        margin-bottom: rem(10);
        font-weight: $normal;
        @include mq{
            letter-spacing: 0.1em;
            font-size: rem(16);
            margin-bottom: rem(5);
        }
    }
    &__read2{
        font-size: rem(14);
        font-family: $base-font-family;
        display: block;
        text-align: right;
        @include mq{
            text-align: left;
            letter-spacing: 0.05em;
            font-size: rem(10);
        }
    }
    &__cards{
        max-width: rem(900);
        margin-left: auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(30);
        @include mq{
            max-width: unset;
            grid-template-columns: 1fr 1fr;
            column-gap: rem(10);
            row-gap:rem(30);
            margin-bottom: rem(10);

        }
    }
    &__view-more{
        margin-left: auto;
        transform: translateY(-100%);
        @include mq{
            transform: unset;
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width:rem(920);
        height: 100%;
        background: #f1f1f1;
        overflow: hidden;
        transform: translate(-25%);
        z-index: -1;
        @include mq{
            width: rem(470);
            transform: translate(rem(-90),rem(-28));
        }
    }
    &__bg-container{
        position: relative;
    }
    &__bg-point{
        position: absolute;
        aspect-ratio: 1;
        width: rem(13);
        border-radius: 50%;
        z-index: 2;
        @include mq{
            width: rem(10);
        }
    }
    &__point-wave::before{
        position: absolute;
        content: "";
        display: block;
        aspect-ratio: 1;
        top: 50%;
        left: 50%;
        width: rem(25);
        transform: translate(-50%,-50%);
        z-index: 1;
        border-radius: 50%;
        background: radial-gradient(#f9d8c9 0% 100%);
        animation:point-map 4s ease-in-out infinite ;
        @include mq{
            width: rem(14);
        }
    }
    &__point-wave::after{
        position: absolute;
        content: "";
        display: block;
        aspect-ratio: 1;
        z-index: 2;
        left: 50%;
        top: 50%;
        width: rem(13);
        transform: translate(-50%,-50%);
        z-index: 1;
        border-radius: 50%;
        background: radial-gradient(#f48654 0 30%,#f7bfa5 30% 100%);
        @include mq{
            width: rem(8);
        }
    }
    
    &__bg-point--1{
        top: rem(400);
        left: rem(380);
        @include mq{
            top: rem(210);
            left: rem(190);
        }
    }
    &__bg-point--2{
        top: rem(410);
        left: rem(350);
        @include mq{
            top: rem(215);
            left: rem(180);
        }
    }
    &__bg-point--3{
        top: rem(425);
        left: rem(440);
        @include mq{
            top: rem(222);
            left: rem(220);
        }
    }
    &__bg-point--4{
        top: rem(420);
        left: rem(395);
        @include mq{
            top: rem(220);
            left: rem(197);
        }
    }
    &__bg-point--5{
        top: rem(440);
        left: rem(370);
        @include mq{
            top: rem(230);
            left: rem(185);
        }
    }

}
.salon-card{
    display: block;
    &__img{
        // aspect-ratio: 280/180;
        margin-bottom: rem(10);
        @include mq{
            margin-bottom: rem(5);
        }
        div {
            position: relative;
            padding-top: 64%;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__name{
        font-size: rem(21);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        margin-bottom: rem(10);
        color: $black;
        transition: all 0.3s ease-in-out;
        @include mq{
            font-size: rem(16);
            margin-bottom: rem(5);
        }
    }
    &__address{
        font-size: rem(12);
        letter-spacing: 0.05em;
        line-height: calc(15/12);
        color: $black;
        font-family: $third-font-family;
        transition: all 0.3s ease-in-out;
        @include mq{
            font-size: rem(10);
        }

    }
}
.salon-card:hover{
    .salon-card__name{
        color: $hover;
    }
    .salon-card__address{
        color: $hover;
    }
}

// service
.top-service{
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
        }
    }
    &__container{
        padding: rem(120) 0 rem(130);
        @include mq{
            padding: rem(80) 0 rem(55);
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: rem(30);
        margin-bottom: rem(90);
        @include mq{
            flex-direction: column;
            align-items: unset;
            margin-bottom: rem(30);
        }
    }
    &__top-left{
        display: flex;
        gap: rem(30);
        align-items: flex-end;
        @include mq{
            flex-direction: column;
            gap: rem(15);
            align-items: unset;
        }
    }
    &__ttl{
        line-height: 1;
    }
    &__read{
        font-size: rem(32);
        font-weight: $normal;
        font-family: $fourth-font-family;
        line-height: 1;
        @include mq{
            font-size: rem(16);
        }
    }
    &__read2{
        font-size: rem(14);
        font-family: $base-font-family;
        margin-top: rem(10);
        @include mq{
            font-size: rem(10);
        }
    }
    &__contents{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: rem(7);
        @include mq{
            grid-template-columns: 1fr 1fr;
            gap: rem(15);
            margin-bottom: rem(30);
        }
    }
    &__content{
        position: relative;
        aspect-ratio: 300/520;
        overflow: hidden;
        @include mq{
            aspect-ratio: 1;
        }
    }
    &__content:nth-of-type(2n){
        transform: translateY(rem(50));
        @include mq{
            transform: unset;
        }
    }
    &__content-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: rem(60);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        color: $white;
        @include mq{
            font-size: rem(30);
        }
    }
    &__content-text2{
        position: absolute;
        bottom: rem(40);
        left: 50%;
        transform: translateX(-50%);
        font-size: rem(14);
        color: $white;
        font-family: $fifth-font-family;
        @include mq{
            font-size: rem(10);
            bottom: 5%;
        }
    }
    &__view-more{
        @include mq{
            margin-left: auto;
        }
    }
}

// pickup
.top-pick{
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        @include mq{
            width: 100%;
        }
    }
    &__container{
        padding-bottom: rem(120);
        display: flex;
        align-items: flex-end;
        @include mq{
            display: block;
            padding-bottom: rem(60);
        }
    }
    &__img{
        width: 45%;
        max-width: rem(500);
        aspect-ratio: 1;
        overflow: hidden;
        @include mq{
            width: 100%;
            aspect-ratio: 75/50;
            max-width: unset;
        }
    }
    &__img img{
        @include mq{
            margin-top: rem(-10);
        }
    }
    &__text{
        padding: rem(40) rem(43) rem(70) rem(100);
        font-feature-settings: "palt";
        @include mq{
            font-feature-settings: unset;
            padding: rem(20) rem(30) rem(60);
            margin: 0 auto;
        }
    }
    &__text4{
        transform: translateX(rem(-20)) rotate(90deg);
        transform-origin: left top;
        width: fit-content;
        font-size: rem(21);
        letter-spacing: 0.05em;
        font-family: $third-font-family;
        padding-left: rem(10);
        @include mq{
            font-size: rem(10);
            transform: translateX(0) rotate(90deg);
            padding-left: unset;
        }
    }
    &__text1{
        font-size: rem(42);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        margin-bottom: rem(20);
        @include mq{
            font-size: rem(30);
            text-align: center;
            margin-bottom: unset;
        }
    }
    &__text2{
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        margin-bottom: rem(20);
        @include mq{
            font-size: rem(16);
            text-align: center;
            margin-bottom: rem(15);
        }
    }
    &__text3{
        font-size: rem(14);
        line-height: 14/30;
        margin-bottom: rem(40);
        @include mq{
            line-height:2;
            margin-bottom: rem(20);
        }
    }
    &__view-more{
        @include mq{
            margin-left: auto;
        }
    }
}
.webp .top-pick__text{
    background-image: url("../img/top/service-bg.jpg.webp") ;
    background-size: cover;
    background-repeat: no-repeat;
}
.no-webp .top-pick__text {
    background-image: url("../img/top/service-bg.jpg") ;
    background-size: cover;
    background-repeat: no-repeat;
}

// product
.top-product{
    height: rem(760);
    position: relative;
    @include mq{
        height: unset;
        width: 100%;
        aspect-ratio: 1;
    }
    &:hover{
        .top-product__contents{
            transition:all 0.3 ease-in-out;
        width: rem(550);
        @include mq{
            width: 72%;
        }
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        overflow: hidden;
    }
    &__bg picture,
    &__bg picture img{
        @include mq{
            height: 110%;
            width: 110%;
        }
    }
    &__contents{
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: rem(500);
        aspect-ratio: 1;
        border-radius: 50%;
        background: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        text-align: center;
        transition:all 0.3s ease-in-out;
        @include mq{
            width: 70%;
            max-width: rem(400);
        }
    }
    &__contents:hover{
        
    }
    &__text1{
        font-family: $second-font-family;
        font-size: rem(18);
        letter-spacing: 0.12em;
        line-height: 1;
        margin-bottom: rem(10);
        font-weight: $light;
        @include mq{
            font-size: rem(14);
            margin-bottom: rem(5);
        }
    }
    &__text2{
        font-family:$third-font-family ;
        font-size: rem(90);
        letter-spacing: 0.05em;
        line-height: 1;
        margin-bottom: rem(15);
        @include mq{
            font-size: rem(45);
            margin-bottom: rem(7);
        }
    }
    &__text3{
        font-family: $base-font-family;
        font-size: rem(14);
        letter-spacing: 0.05em;
        line-height: calc(30/14);
        margin-bottom: rem(30);
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(15);
        }
    }
    &__view-more{
        color: $white;
        margin: 0 auto;
    }
    &__view-more .view-more__line{
        background: $white;
        
    }
}

// gallery
.top-gallery{
    overflow: hidden;
    padding: rem(130) 0;
    background: $gray;
    @include mq{
        padding: rem(90) 0;
    }
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        position: relative;
        @include mq{
            max-width: rem(600);
        }
    }
    &__ttl{
        line-height: 0.73;
        max-width: rem(1500);
        width: 100%;
        margin: 0 auto;
        color: $white;
        @include mq{
            padding-left: rem(10);
            white-space: nowrap;
        }
    }
    &__view-more{
        position: absolute;
        top: rem(-120);
        right:0;
        @include mq{
            top: rem(-70);
        }
    }
    &__cards{
        display: flex;
        margin-bottom: rem(80);
        @include mq{
            margin-bottom: rem(40);
        }
    }
    & .swiper{
        overflow:hidden;
    }
    & .swiper-scrollbar{
        background-color: $gray2;
        position: unset;
        height: rem(2);
    }
    & .swiper-scrollbar-drag{
        background-color: $black;
        height: rem(2);
        
    }
}
.gallery-card{
    &__img{
        // aspect-ratio:350/520 ;
        padding-top: 148.5%;
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    // &__img picture{
    //     height: 100%;
    // }
    // &__img img{
    //     height: 100%;
    //     object-fit: cover;
    // }
}

// staff
.top-staff{
    height: 58vw;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    @include mq{
        height: unset;
        display: block;
    }
    &__inner{
        max-width: rem(1200);
        margin: 0 auto;
        width: 90%;
        @include mq{
            max-width: rem(600);
        }
    }
    &__contents{
        width: 100%;
        display: flex;
        @include mq{
            display: block;
        }
    }
    &__content{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    &__ttl{
        line-height: 1;
        margin-bottom: rem(45);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__read{
        font-size: rem(24);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        font-weight: $normal;
        line-height: calc(40/24);
        margin-bottom: rem(25);
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(15);
        }
    }
    &__text{
        font-size: rem(14);
        letter-spacing: 0.05em;
        font-family: $base-font-family;
        line-height: calc(30/14);
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(25);
        }
    }
    &__view-more{
        margin-left: auto;
        margin-bottom: rem(50);
    }
    &__img{
        position: absolute;
        @include mq{
            position: unset;
        }
    }
    &__img--1{
        top: 50%;
        left: 0;
        transform: translateY(-52%);
        width: 42vw;
        aspect-ratio: 725/700;
        overflow: hidden;
        z-index: 0;
        @include mq{
            width: 100%;
            top: unset;
            left: unset;
            transform: unset;
            aspect-ratio: 75/50;
            z-index: auto;
            margin-bottom: rem(40);
        }
    }
    &__img--2{
        top:0;
        right: 0;
        aspect-ratio: 530/300;
        width: 31vw;
        overflow: hidden;
        z-index: 0;
        @include mq{
            width: 70vw;
            margin-left: auto;
        }
    }
    &__img--2 picture,
    &__img--2 picture img{
        height: 130%;
    }
    &__bg-ttl{
        position: absolute;
        bottom: rem(100);
        left: 50%;
        white-space: nowrap;
        background: linear-gradient(90deg,#f8f8f8,#fdefe3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: -1;
        @include mq{
            position: unset;
            left: unset;
            margin-left: 30vw;
            width: fit-content;
        }
    }
}

// journal
.top-journal{
    overflow: hidden;
    margin-top:-27%;
    margin-bottom: rem(150);
    position: relative;
    @include mq{
        margin-top: -55%;
        margin-bottom: rem(60);
    }
    &__bg{
        width: 100%;
        aspect-ratio:1680/560 ;
        overflow: hidden;
        @include mq{
            aspect-ratio: 3/2;
        }
    }
    &__bg picture,
    &__bg picture img{
        @include mq{
            height: 120%;
        }
    }
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin:0 auto;
        @include mq{
            max-width: rem(600);
        }
    }
    &__ttl{
        display: flex;
        align-items: center;
        font-size: rem(90);
        font-family: $third-font-family;
        color: $white;
        margin-bottom: rem(70);
        letter-spacing: 0.05em;
        line-height: 1;
        overflow: hidden;
        @include mq{
            font-size: rem(45);
            margin-bottom: rem(25);
        }
    }
    &__ttl .ttl{
        font-size: rem(90);
        font-family: $third-font-family;
        @include mq{
            font-size: rem(45);
        }
    }
    &__ttl span{
        display: block;
    }
    &__logo{
        vertical-align: middle;
        padding-right: rem(25);
        @include mq{
            padding-right: rem(15);
        }
    }
    &__cards{
        display: flex;
        margin-bottom: rem(25);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    & .swiper{
        overflow:hidden;
    }
    & .swiper-scrollbar{
        background-color: $gray2;
        position: unset;
        height: rem(2);
    }
    & .swiper-scrollbar-drag{
        background-color: $black;
        height: rem(2);
        
    }
}

// recruit
.top-recruit{
    height: 46vw;
    margin-bottom: rem(120);
    @include mq{
        height: auto;
        width: 100%;
        aspect-ratio: 1;
        margin-bottom: rem(60);
    }
    &__inner{
        max-width: rem(1540);
        height: 100%;
        padding:0 rem(20);
        margin: 0 auto;
        position: relative;
        @include mq{
            max-width: rem(640);
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        height: 100%;
        overflow: hidden;
        @include mq{
            width: 100%;
        }
    }
    &__bg picture,
    &__bg picture img{
        height: 120%;
    }
    &__contents{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
        text-align: center;
        color: $white;
    }
    &__ttl{
        margin-bottom: rem(40);
        line-height: 1;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__read{
        font-weight: $normal;
        font-family: $fourth-font-family;
        font-size: rem(36);
        margin-bottom: rem(30);
        letter-spacing: 0.1em;
        line-height: 1.4;
        @include mq{
            font-size: rem(18);
            line-height: 1.4;
            margin-bottom: rem(15);
        }
    }
    
    &__read--small{
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(14);
        }
    }
    &__text{
        font-size: rem(14);
        letter-spacing: 0.05em;
        line-height: calc(30/14);
        margin-bottom: rem(45);
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(15);
            text-align: left;
            padding: 0 rem(15);
        }
    }
    &__view-more{
        color: $white;
        margin: 0 auto;
    }
    &__view-more .view-more__line{
        background: $white;
    }
}