@charset "UTF-8";

// インナー幅
$inner:1680px;

// padding
$padding-pc:25px;
$padding-sp:20px;

//フォント
$base-font-family:"noto-sans-cjk-jp", sans-serif;
$second-font-family:copperplate, serif;
$third-font-family:'Marcellus', serif;
$fourth-font-family:shippori-mincho, sans-serif;
$fifth-font-family:poppins, sans-serif;



// フォントウェイト
$demilight:200;
$light:300;
$regular:400;
$normal:500;
$bold:700;
// カラー
$baseColor:#000;
$white:#FFF;
$black:#000;
$black2:#141414;
$gray:#f9f9f9;
$gray2:#edebeb;
$gray3:#999999;
$gray4:#acacac;
$gray5:#dddddd;
$gray6:#f1f1f1;
$gray7:#eae7e6;
$gray8:#eeeeee;
$gray9:#aaaaaa;
$gray10:#efefef;
$gray11:#646464;
$gray12:#fafafa;
$hover:#f48654;
$pink:#d7c4ca;
$pink2:#ffccc6;
$pink3:#fff9f8;
$pink4:#ea9d93;
$pink5:#f6beb7;
$pink6:#fff9f8;
$pink7:#faedeb;
$orange:#eba9a1;
$green:#e0f1ee;


// メディアクエリ＆ブレイクポイント(pcファースト)
$breakpoints: (
	md: 767,
	tb:1024,
);
$mediaquerys: (
	md: "screen and (max-width: #{map-get($breakpoints,'md')}px)",
	tb: "screen and (max-width: #{map-get($breakpoints,'tb')}px)",
);

@mixin mq($mediaquery: md){
	@media #{map-get($mediaquerys,$mediaquery)}{
		@content;
	}
}


