@use "../utility" as *;
.wp-content{
    &__text{
        display: flex;
        gap: rem(25);
        align-items: center;
        margin-bottom: rem(40);
        @include mq{
            gap: rem(15);
            margin-bottom: rem(25);
            align-items: unset;
            flex-direction: column;
        }
    }
    &__date{
        display: flex;
        gap: rem(10);
        letter-spacing: 0.05em;
        align-items: center;
        @include mq{
            gap: rem(5);
        }
    }
    &__year-month{
        line-height: 1;
        font-size: rem(18);
        font-family: $third-font-family;
        @include mq{
            font-size: rem(16);
        }
    }
    &__day{
        line-height: 1;
        font-family: $third-font-family;
        font-size: rem(40);
        @include mq{
            font-size: rem(26);
        }
    }
    &__title{
        font-size: rem(36);
        letter-spacing: 0.05em;
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(24);
        }
    }
    &__info{
        display: flex;
        align-items: center;
        gap: rem(20);
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(45);
        }
    }
    &__cat{
        font-size: rem(16);
        font-weight: $regular;
        letter-spacing: 0.05em;
        color: $white;
        padding: rem(10) rem(13);
        line-height: 1;
        background: $black;
    }
    &__tags{
        display: flex;
        gap: rem(15);
    }
    &__tag{
        display: block;
        font-size: rem(18);
        font-weight: $regular;
        color: $black;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(16);
        }
    }
    &__tag::before{
        content: "#";
        color:$gray9 ;
        padding-right: rem(3);
    }
    &__editor{
        margin-bottom:rem(120);
        @include mq{
            margin-bottom: rem(40);
        }
    }
    &__prev{
        display: block;
        margin: 0 auto;
        width: fit-content;
        font-size: rem(16);
        font-family: $fourth-font-family;
        color: $black;
        letter-spacing: 0.1em;
        padding-bottom: rem(15);
        border-bottom: 1px solid $black;
        margin-bottom: rem(140);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__prev span{
        padding-right: rem(10);
        font-size: rem(23);
        transform: translateY(rem(3));
        @include mq{
            font-size: rem(20);
            vertical-align: revert;
            padding-right: rem(5);
        }
    }
}