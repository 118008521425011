@use "../utility" as *;

.bg-ttl{
    font-size: rem(160);
    font-weight: $regular;
    letter-spacing: 0.05em;
    font-family: $third-font-family;
    color: $black;
    @include mq{
        font-size: rem(50);
    }
}