@use "sass:math";
@use "../utility" as *;

@keyframes point-map{
    0%{
        clip-path: circle(30%);
        opacity: 0.05;
    }
    30%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        clip-path: circle(100%);
    }
}

@keyframes scroll{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes viewMore-hover{
    0%{
        left: 0;
    }
    50%{
        left: 100%;
    }
    51%{
        left: 0;
        width: 0;
    }
    100%{
        left: 0;
        width: 100%;
    }
}

@keyframes is-fade{
    0%{
        opacity: 0;
        transform: translateY(rem(30));
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes drawer{
    0%{
        clip-path: inset(0% 0% 0% 0%);
    }
    100%{
        clip-path: inset(0% 0% 100% 0%);

    }
}



@keyframes hamburger-opening{
    0%{
        clip-path: inset(0% 0% 0% 0%);
    }
    50%{
        clip-path: inset(0% 0% 0% 100%);
    }
    51%{
        clip-path: inset(0% 100% 0% 0%);
    }
    100%{
        clip-path: inset(0% 0% 0% 0%);
    }
    
}
@keyframes hamburger-closing{
    0%{
        clip-path: inset(0% 0% 0% 0%);
    }
    50%{
        clip-path: inset(0% 0% 0% 100%);
    }
    51%{
        clip-path: inset(0% 100% 0% 0%);
    }
    100%{
        clip-path: inset(0% 0% 0% 0%);
    }
    
}


