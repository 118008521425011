@use "../utility" as *;

.logo{
    font-family: $second-font-family;
    letter-spacing: 0.16em;
    line-height: 1;
    font-size: rem(34);
    font-weight: $regular;
    @include mq{
        font-size: rem(20);
    }
}