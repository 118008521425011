@use "../utility" as *;
// 固定ページで共通するパーツ

// 固定ページ上部
.page-top{
    &__inner{
        max-width: rem(1250);
        padding:0 $padding-pc rem(90);
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp rem(50);
        }
    }
    &__container{
        padding-top: rem(185);
        @include mq{
            padding-top: rem(100);
        }
    }
    &__ttl{
        font-size: rem(90);
        font-weight: $regular;
        font-family: $third-font-family;
        margin-bottom: rem(5);
        line-height: 1.2em;
        // letter-spacing: 0.05em;
        @include mq(tb){
            margin-bottom: rem(20);
        }
        @include mq{
            font-size: rem(42);
            margin-bottom: rem(10);
        }
    } 
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(20);
        @include mq{
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
        }
    }
    &__read{
        font-size: rem(16);
        font-weight: $normal;
        font-family:$fourth-font-family;
        // letter-spacing: 0.1em;
        @include mq{
            font-size: rem(16);
            margin-bottom: rem(20);
        }
    }
    &__breads{
        display: flex;
        gap: rem(10);
        @include mq{
            flex-wrap: wrap;
        }
    }
    &__bread{
        font-size: rem(12);
        font-family: $third-font-family;
        font-weight: $regular;
        color: $gray4;
        letter-spacing: 0.1em;
    }
    
    &__bread:not(:last-of-type){
        padding:0 rem(10);
        margin: 0 rem(20);
        position: relative;
        @include mq{
            padding: 0 rem(10) 0 0;
            margin: 0 rem(16) 0 0;
        }
    }
    &__bread:not(:last-of-type)::after{
        position: absolute;
        content: "";
        display: block;
        width:rem(24);
        height:1px;
        background: $gray5;
        top: 50%;
        left: 100%;
        transform: translate(rem(8),-50%);
        @include mq{
            width: rem(20);
            transform: translate(rem(0),-50%);

        }
    }
    &__bread:last-of-type{
        pointer-events: none;
        padding-left: rem(10);
        margin-left: rem(20);
        color:$black;
        @include mq{
            padding-left: rem(8);
            margin-left: rem(5);
        }
    }
    &__bread:nth-of-type(n+3){
        @include mq{
            padding-left: 0;
            margin-left: 0;
            display: block;
            width: 100%;
        }
    }
}

.page-top--flex{
    .page-top__container{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include mq{
            display: block;
        }
    }
    .page-top__ttl{
        line-height: 1;
        margin-bottom: unset;
        @include mq{
            line-height: unset;
        }
    }
}

// 固定ページMV
.page-mv{
    aspect-ratio: 1680/660;
    position: relative;
    @include mq{
        aspect-ratio: 3/2;
    }
    &__img{
        height: 100%;
        width: 100%;
    }
    &__img picture,
    &__img img{
        object-fit: cover;
        height: 100%;
    }
}

// 固定ページリード文
.page-read{
    position: relative;
    margin-bottom: rem(100);
    @include mq{
        margin-bottom: rem(50);
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: $padding-pc;
        @include mq{
            flex-direction: column;
            max-width: rem(600);
            padding: 0 $padding-sp;
            margin: 0 auto;
            justify-content: unset;
            align-items: unset;
            gap: rem(20);
        }
    }
    &__read{
        font-size: rem(32);
        font-family: $fourth-font-family;
        font-weight: $regular;
        letter-spacing: 0.1em;
        line-height: calc(60/32);
        padding-right: rem(70);
        @include mq{
            font-size: rem(18);
            padding-right: unset;
            letter-spacing: 0.05em;
        }
    }
    &__bg-text{
        position: absolute;
        line-height: 1.3;
        background: linear-gradient(90deg,#f8f8f8,#fdefe3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include mq{
            white-space: nowrap;
        }
    }
}

// ページネーション
.pagination{
    width: fit-content;
    display: flex;
    gap: rem(29);
    @include mq{
        gap: rem(10);
    }
    .page-numbers{
        display: block;
        padding: rem(3);
        font-size: rem(14);
        font-family: $fourth-font-family;
        font-weight: $bold;
        line-height: 1;
        color: $black;
        @include mq{
            padding: rem(6) rem(6) rem(3);
        }
    }
    .current{
        border-bottom: 1px solid $black;
    }
    
    span{
        vertical-align: middle;
        @include mq{
            vertical-align: unset;
        }
    }
}

// サイドバー
.page-side{
    width: 22%;
        @include mq{
            width: 100%;
        }
    &__content{
        margin-bottom: rem(70);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__name{
        font-size: rem(24);
        font-family: $third-font-family;
        font-weight: $regular;
        letter-spacing: 0.05em;
        padding:0 0 rem(15) ;
        margin-bottom: rem(20);
        border-bottom: 1px solid $gray8;
    }
    &__item a{
        display: block;
        font-size: rem(14);
        font-weight: $regular;
        letter-spacing: 0.05em;
        color: $black;
        padding: rem(10) 0 ;
    }
    &__tags{
        display: flex;
        gap: rem(15);
        flex-wrap: wrap;
    }
    &__tag a{
        font-size: rem(14);
        font-weight: $regular;
        letter-spacing: 0.05em;
        color: $black;
        position: relative;
    }
    &__tag::before{
        content: "#";
        padding-right: rem(3);
        color: $gray9;
    }
}

.js-sidebar{
    .page-side__name{
        position: relative;
        cursor: pointer;
    }
    .page-side__name::after{
        position: absolute;
        content: "";
        display: block;
        top: 40%;
        right: 0;
        transform: translateY(-50%) rotate(180deg);
        width: rem(12);
        height: rem(7);
        background-image: url("../img/common/side-arrow.png");
        background-size: cover;
        background-repeat: no-repeat;
        transition: 0.4s all ease-in-out;
    }
    .page-side__items{
        clip-path: inset(0% 0% 100% 0%);
        transition: 0.4s all ease-in-out;
    }
    .page-side__item a{
        line-height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        transition: 0.3s all ease-in-out;
    }
    .page-side__tags{
        clip-path: inset(0% 0% 100% 0%);
        transition: 0.4s all ease-in-out;
        opacity: 0;
    }
    .page-side__tag{
        line-height: 0;
        padding: 0;
        transition: 0.4s all ease-in-out;
        overflow: hidden;
    }
}
.js-sidebar.is-open{
    .page-side__items{
        clip-path: inset(0% 0% 0% 0%);
    }
    .page-side__item a{
        line-height: 1.2;
        opacity: 1;
        overflow: hidden;
        padding: rem(10) 0 ;
    }
    .page-side__name::after{
        transform: translateY(-50%) rotate(0deg);
    }
    .page-side__tags{
        clip-path: inset(0% 0% 0% 0%);
        opacity: 1;
    }
    .page-side__tag{
        line-height: 1.2;
    }
}