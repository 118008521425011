@use "../utility" as *;
// エディター用CSS
.wp-editor {
    p {
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(30 / 14);
        margin: rem(20) 0;
    }
    h1 {
        font-size: rem(30);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        margin: rem(50) 0 rem(35);
        line-height: 1.8;
        @include mq {
            font-size: rem(24);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
    h2 {
        font-size: rem(24);
        font-weight: $normal;
        font-family: $fourth-font-family;
        margin: rem(50) 0 rem(35);
        padding: rem(10) rem(15);
        background: $gray6;
        line-height: 1.8;
        @include mq {
            font-size: rem(20);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
    h3 {
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        margin: rem(50) 0 rem(35);
        padding-bottom: rem(10);
        border-bottom: 1px solid $gray8;
        line-height: 1.8;
        @include mq {
            font-size: rem(20);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
    h4 {
        font-size: rem(20);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        color: $white;
        width: fit-content;
        padding: rem(10) rem(15);
        background: $black;
        margin: rem(50) 0 rem(35);
        line-height: 1.8;
        @include mq {
            font-size: rem(18);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
    h5 {
        font-family: $fourth-font-family;
        width: fit-content;
        font-size: rem(20);
        font-weight: $normal;
        padding-bottom: rem(10);
        letter-spacing: 0.1em;
        border-bottom: 1px solid $black;
        line-height: 1.8;
        margin: rem(50) 0 rem(35);
        @include mq {
            font-size: rem(18);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
    h6 {
        font-size: rem(20);
        font-weight: $normal;
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        margin: rem(50) 0 rem(35);
        @include mq {
            font-size: rem(18);
        }
        span {
            font-family: $fourth-font-family;
        }
    }
}

#toc_container {
    width: 100% !important;
    font-size: 100% !important;
    border: none !important;
    background: #f1f1f1 !important;
    padding: 42px 57px 30px !important;
    .toc_title {
        font-size: rem(18);
        font-family: $fourth-font-family;
        font-weight: normal;
        text-align: left !important;
        margin-bottom: 35px;
    }
    ul {
        li {
            a {
                font-size: rem(16);
                color: #000;
                letter-spacing: 0.05em;
                line-height: 1.4em;
                display: block;
                margin-bottom: 10px;
                &:hover {
                    text-decoration: none !important;
                }
                span {
                    color: #aaaaaa;
                }
            }
        }
        ul {
            margin-left: 15px !important;
        }
    }
    @include mq {
        padding: 25px 15px 20px !important;
        .toc_title {
            font-size: rem(14);
            margin-bottom: 25px;
        }
        ul {
            li {
                a {
                    font-size: rem(12);
                }
            }
        }
    }
}
