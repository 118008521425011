@use "../utility" as *;

.header {
	&.blur {
		filter: blur(8px);
	}
}

.header__logo{
    position: absolute;
    top: rem(55);
    left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    z-index: 10;
    line-height: 1;
    @include mq{
        top: rem(30);
    }
    & a{
        color: $black;
        font-weight: $regular;
    }
}

// js関係
.is-open.hamburger{
    animation: hamburger-opening 1s ease-in-out;
    .hamburger__line:nth-of-type(1){
        top: rem(10);
        left: rem(-2);
        transform: rotate(45deg) translateY(rem(1));
        width: rem(50);
        @include mq{
            left: 0;
            transform: rotate(45deg);
            width: rem(25);
            top: rem(6);
        }
    }
    .hamburger__line:nth-of-type(2){
        opacity: 0;
    }
    .hamburger__line:nth-of-type(3){
        top: rem(10);
        left: 0;
        transform: rotate(-45deg);
        width: rem(50);
        @include mq{
            top: rem(6);
            width: rem(25);
        }

    }
}

.is-close.is-moving.hamburger{
    animation: hamburger-closing 1s ease-in-out;
}


.header__hamburger{
    position: fixed;
    top:rem(45) ;
    right: rem(75);
    z-index: 10;
    @include mq{
        top: rem(33);
        right: rem(30);
    }
}


.hamburger.is-moving{
    pointer-events: none;
    cursor: pointer;
}
.hamburger{
    width: rem(50);
    height: rem(25);
    position: relative;
    cursor: pointer;
    overflow: hidden;
    @include mq{
        width: rem(25);
        height: rem(13);
    }
    
    &__line{
        display: block;
        height: 1px;
        background: $black;
        position: absolute;
        left: 0;
    }
    &__line:nth-of-type(1){
        width: rem(45);
        top: 0;
        transition-delay: 0.5s;
        @include mq{
            width: rem(22);
        }
    }
    &__line:nth-of-type(2){
        width: rem(50);
        left: 0;
        top: rem(10);
        opacity: 1;
        transition-delay: 0.5s;
        @include mq{
            width: rem(25);
            top: rem(6);
        }
    }
    &__line:nth-of-type(3){
        transition-delay: 0.5s;
        width: rem(30);
        top: rem(20);
        @include mq{
            width: rem(15);
            top: rem(12);
        }
    }
    
}

// drawer
.drawer.is-active{
    clip-path: inset(0% 0% 0% 0%);
    visibility: visible;
}
.drawer.is-close{
    clip-path: inset(0% 0% 100% 0%);
    visibility: visible;
}

.drawer{
    visibility: hidden;
    transition: all 0.7s ease-in-out;
    position: fixed;
    clip-path: inset(100% 0% 0% 0%);
    z-index: 5;
    background-image: url("../img/common/drawer-bg.jpg");
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
    &__inner{
        max-width: rem(1200);
        width: 90%;
        margin: 0 auto;
        position: relative;
        @include mq{
            max-width: rem(600);
        }
        
    }
    &__container{
        width: 100%;
        margin-top: 25%;
        margin-bottom: 5%;
    }
    &__contents{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: rem(180);
        @include mq{
            margin-bottom: rem(30);
            flex-direction: column;
        }
    }
    &__content:nth-of-type(1){
        width: 75%;
        @include mq{
            width: 100%;
        }
    }
    &__content:nth-of-type(2){
        width: 10%;
        @include mq{
            width: 100%;
        }
    }
    &__menus{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap:rem(40) ;
        @include mq{
            grid-template-columns: 1fr 1fr;
            gap: rem(40);
            margin-bottom: rem(50);
        }
    }
    &__menu a{
        font-size: rem(42);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        color: $black;
        white-space: nowrap;
        transition: all 0.3s ease-in-out;
        @include mq{
            font-size: rem(22);
        }
    }
    &__menu a:hover{
        color: $hover;
    }
    &__sns-icon{
        display: block;
        margin-bottom: rem(10);
        font-size: rem(14);
        letter-spacing: 0.05em;
        color: $black;
        color: $black;
        font-family: $third-font-family;
    }
    &__copy{
        font-size: rem(12);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(10);
        }
    }
}

.drawer{
    & .fa-facebook{
        padding-right: rem(10);
        padding-bottom: rem(5);
        font-size: rem(24);
        vertical-align: middle;

    }
    & .fa-instagram{
        padding-right: rem(10);
        padding-bottom: rem(5);
        font-size: rem(24);
        vertical-align: middle;
        margin-left: rem(2);

    }
}



