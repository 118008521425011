@use "../utility" as *;
// このページでの共通パーツ
.sd-ttl{
    text-align: center;
    font-size: rem(40);
    font-family: $third-font-family;
    font-weight: $regular;
    letter-spacing: 0.05em;
    @include mq{
        font-size: rem(28);
    }
}
//======================= 

.salon-detail{
    .page-mv__img {
        position: relative;
        padding-top: 39%;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .page-mv__salon{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: rem(32);
        font-weight: $regular;
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        color: $white;
        background: $black2;
        line-height: 1;
        padding: rem(40) rem(100);
        @include mq{
            font-size: rem(18);
            line-height: 1.3;
            width: 70%;
            margin: 0 auto;
            text-align: center;
            padding: rem(20) 0;
        }
    }
    .page-mv__salon span{
        font-family: $third-font-family;
    }
}

.salon-detail{
    .page-top__ttl{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(10);
        }
    }
}

// info
.sd-info{
    background-image: url("../img/salon_detail/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: rem(120) 0;
    @include mq{
        padding: rem(60) 0 ;
    }
    &__container{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @include mq{
            flex-direction: column;
            gap: rem(20);
            justify-content: unset;
        }
    }
    &__img{
        width: 50%;
        padding-right:rem(40);
        // aspect-ratio: 800/510;
        @include mq{
            width: 100%;
            padding-right: rem(20);
        }
        div {
            position: relative;
            padding-top: 63.6%;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__info{
        width: 50%;
        padding-top: rem(15);
        max-width: rem(600);
        margin-bottom: rem(40);
        @include mq{
            width: 100%;
            padding:0 $padding-sp;
            margin: 0 auto rem(25);
        }
    }
    &__ttl{
        font-size: rem(28);
        font-weight: $regular;
        font-family: $third-font-family;
        letter-spacing: 0.1em;
        border-bottom: 1px solid $black;
        margin-bottom: rem(35);
        @include mq{
            font-size: rem(22);
            margin-bottom: rem(20);
        }
    }
    &__ttl a{
        color: $black;
        padding-left: rem(10);
    }
    &__item{
        display: flex;
        align-items: center;
        margin-bottom: rem(20);
        @include mq{
            flex-direction: column;
            align-items: unset;
        }
    }
    &__item--base{
        align-items: baseline;
    }
    &__tag{
        font-size: rem(20);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        width: 25%;
        @include mq{
            font-size: rem(18);
            width: 100%;
        }
    }
    &__data{
        width: 75%;
        font-size: rem(16);
        letter-spacing: 0.05em;
        font-weight: $demilight;
        line-height: calc(30/16);
        @include mq{
            width: 100%;
            font-size: rem(14);
        }
    }
    &__tel{
        font-size: rem(32);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        line-height: 1.3;
        color: $black;
        @include mq{
            font-size: rem(24);
        }
    }
    &__tel i{
        padding-right: rem(5);
    }
    &__tel span{
        display: block;
        font-size: rem(14);
        font-family: $base-font-family;
    }
    &__data--park span{
        font-size: rem(14);
        font-family: $base-font-family;
        font-weight: $demilight;
        letter-spacing: 0.05em;
    }
    &__reserve{
        display: block;
        width: rem(273);
        text-align: center;
        font-size: rem(16);
        font-weight: $demilight;
        color: $white;
        background: $black;
        position: relative;
        padding: rem(15) 0;
        @include mq{
            width: 100%;
        }
    }
    &__reserve::after{
        position: absolute;
        content: "";
        display: block;
        width: rem(57);
        height: 1px;
        background: $white;
        top: 50%;
        right: 0;
    }
    &__map{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        aspect-ratio: 1200/367;
        margin: 0 auto;
        filter: grayscale(1);
        @include mq{
            max-width: unset;
            width: 100%;
            padding:0 $padding-sp;
            aspect-ratio: 3/2;
        }
    }
    &__map iframe{
        height: 100%;
        width: 100%;
    }
}

// staff
.sd-staff{
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(100) 0;
        @include mq{
            padding: rem(50) 0;
        }
    }
    &__ttl{
        margin-bottom: rem(40);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__contents{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: rem(50) rem(40);
        @include mq{
            grid-template-columns: 1fr;
            gap: rem(40);
        }
    }
    &__img{
        // aspect-ratio:1;
        box-shadow:rem(15) rem(15) 0  $gray7;
        margin-bottom: rem(25);
        position: relative;
        padding-top: 100%;
        @include mq{
            box-shadow:rem(10) rem(10) 0  $gray7;
            margin-bottom: rem(20);
        }
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-position: top;
        }
    }
    &__img img{
        display: block;
    }
    &__info{
        padding-left: rem(15);
        @include mq{
            padding-left: rem(10);
        }
    }
    &__role{
        font-size: rem(20);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        margin-bottom: rem(10);
        text-align: center;
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__read{
        display: flex;
        justify-content: space-between;
        gap: rem(10);
        align-items: center;
        padding-bottom: rem(10);
        margin-bottom: rem(10);
        border-bottom: 1px solid $gray7;
        @include mq{
            flex-direction: column;
        }
    }
    &__name{
        font-size: rem(16);
        letter-spacing: 0.05em;
        line-height: 1.5;
        font-weight: $demilight;
    }
    &__name a{
        color: $black;
        padding-left: rem(10);
    }
    &__name span{
        font-size: rem(14);
        font-family: $third-font-family;
        font-weight: $regular;
        display: block;
    }
    &__year{
        font-size: rem(16);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        @include mq{
            text-align: right;
        }
    }

    &__catch{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(26/14);
    }
}
.sd-gallery{
    background: $gray6;
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp;
        }
    }
    &__container{
        padding: rem(100) 0 rem(160);
        @include mq{
            padding: rem(50) 0 rem(80);
        }
    }
    &__ttl{
        margin-bottom: rem(50);
        @include mq{
            margin-bottom: rem(25);
        }
    }
    &__images{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(45);
        @include mq{
            grid-template-columns: 1fr 1fr;
            gap: rem(20);
        }
    }
    &__img{
        // aspect-ratio: 370/470;
        padding-top: 130.5%;
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}