@use "../utility" as *;
.salon{
    .page-top__ttl{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(10);
        }
    }
}
.page-salon{
    background-image: url("../img/salon/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-color:$gray6;
    @include mq{
        background-size:250%;
    }
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin:0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp;
        }
    }
    &__contents{
        padding: rem(100) 0 rem(180);
        @include mq{
            padding: rem(50) 0 rem(90);
        }
    }
    &__content{
        margin-bottom: rem(55);
        @include mq{
            margin-bottom: rem(35);
        }
    }
    &__content:last-of-type{
        margin-bottom: unset;
    }
    &__enttl{
        font-size: rem(42);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        margin-bottom: rem(15);
        @include mq{
            font-size: rem(28);
            margin-bottom: rem(10);
        }
    }
    &__body{
        display: flex;

        @include mq{
            flex-direction: column;
            gap: rem(20);
        }
    }
    &__img {
        width: 50%;
        padding-right: rem(35);
        // aspect-ratio:600/365 ;
        @include mq {
            width: 100%;
            padding-right: unset;
        }
        div {
            position: relative;
            padding-top: 64%;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__text{
        width: 50%;
        margin-top: rem(25);
        @include mq{
            margin-top: unset;
            width: 100%;
        }
    }
    &__name{
        font-size: rem(28);
        font-family: $fourth-font-family;
        font-weight: $regular;
        letter-spacing: 0.1em;
        border-bottom: 1px solid $black;
        padding-bottom: rem(10);
        margin-bottom: rem(15);
        @include mq{
            font-size: rem(22);
            padding-bottom: rem(5);
            margin-bottom: rem(10);
        }
    }
    &__name span{
        font-family: $third-font-family;
    }
    &__info{
        padding-left: rem(35);
        @include mq{
            padding:0 rem(15);
        }
    }
    &__address{
        font-size: rem(18);
        font-weight:$demilight ;
        letter-spacing: 0.05em;
        line-height: calc(40/18);
        margin-bottom: rem(25);
        @include mq{
            font-size: rem(14);
            margin-bottom: rem(15);
        }
    }
    &__tel{
        display: block;
        color: $black;
        font-size: rem(32);
        letter-spacing: 0.05em;
        font-weight: $regular;
        font-family: $third-font-family;
        margin-bottom: rem(55);
        @include mq{
            font-size: rem(20);
            margin-bottom: rem(25);
        }
    }
    &__tel span{
        font-size: rem(14);
        font-weight: $demilight;
        padding-left: rem(20);
        @include mq(tb){
            letter-spacing: 0.01em;
            font-feature-settings: "palt";
            padding-left: rem(15);
        }
        @include mq{
            display: block;
            padding-left: unset;
            margin-top: rem(5);
            font-size: rem(12);
            letter-spacing: 0.05em;
        }
    }
    // なぜか1024の優先度が高いので、詳細度を上げてこちらに記載
    & .page-salon__tel span{
        @include mq{
            display: block;
            padding-left: unset;
            margin-top: rem(5);
            font-size: rem(12);
            letter-spacing: 0.05em;
        }
    }
    &__tel i{
        padding-right: rem(5);
    }
    &__more{
        margin-left: auto;
    }
}