@use "../utility" as *;

.concept{
    picture,
    img{
        height: 100%;
        object-fit: cover;
    }
    .page-top__ttl{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(10);
        }
    }
}

.pc-read{
    overflow: hidden;
    &__inner{
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
            margin: 0 auto;
        }
    }
    &__container{
        padding:rem(140) 0 rem(180) ;
        position: relative;
        @include mq{
            padding: rem(60) 0 rem(80) ;
        }
    }
    &__content{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        @include mq{
            align-items: unset;
            justify-content: unset;
            flex-direction:column-reverse ;
            gap: rem(30);
        }
    }
    &__text{
        margin-top: rem(45);
        width: 50%;
        padding-right:rem(100) ;
        max-width: rem(600);
        @include mq{
            margin-top: unset;
            padding-right:unset ;
            width: 100%;
            max-width: unset;
        }
    }
    &__ttl span{
        font-family: $fourth-font-family;
        font-size: rem(24);
        font-weight: $normal;
        display: block;
        line-height: 1.5em;
        margin-bottom: rem(5);
        @include mq{
            font-size: rem(18);
            margin-bottom: unset;
        }
    }
    &__ttl{
        font-family: $fourth-font-family;
        font-size: rem(42);
        font-weight: $normal;
        line-height: 1.5em;
        margin-bottom: rem(40);
        // letter-spacing: 0.1em;
        @include mq{
            margin-bottom: rem(20);
            font-size: rem(26);
        }
    }
    &__text1{
        font-size: rem(18);
        font-family: $fourth-font-family;
        font-weight: $normal;
        // letter-spacing: 0.05em;
        line-height: calc(40/18);
        @include mq{
            font-size: rem(14);
            margin-bottom: rem(30);
        }
    }
    &__img{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    &__content:nth-of-type(2){
        margin: rem(-190) auto 0;
        max-width: rem(1250);
        padding: 0 $padding-pc;
        align-items: center;
        justify-content: center;
        @include mq{
            max-width: unset;
            padding: unset;
            justify-content: unset;
            flex-direction: column;
            align-items: unset;
            margin-top: unset;
        }
    }
    &__img2{
        padding-right:rem(170) ;
        max-width: rem(600);
        width: 50%;
        @include mq{
            padding-right: unset;
            width: 60%;
            max-width: unset;
        }
    }
    &__text2{
        margin-top: rem(110);
        font-size: rem(18);
        width: 50%;
        font-weight: $normal;
        // letter-spacing: 0.05em;
        font-family: $fourth-font-family;
        line-height: calc(40/18);
        @include mq{
            font-size: rem(14);
            margin-bottom: rem(30);
        }
        @include mq{
            font-size: rem(14);
            width: 100%;
            margin-top: unset;
        }
    }
    &__bg-text{
        position: absolute;
        width: 100%;
        left: 15%;
        bottom: rem(45);
        font-family: $third-font-family;
        font-size: rem(160);
        font-weight: $regular;
        background: linear-gradient(90deg,#f8f8f8,#fdefe3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include mq{
            width: fit-content;
            width: 130%;
            left: 70%;
            bottom: rem(30);
        }
    }
}

.pc-style{
    overflow: hidden;
    position: relative;
    background-image: url("../img/concept/style-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            padding: 0 $padding-sp;
            max-width: rem(600);
        }
    }
    &__container{
        padding: rem(110) 0 rem(240);
        @include mq{
            padding: rem(50) 0 rem(70);
        }
    }
    &__ttl{
        text-align: center;
        margin-bottom: rem(20);
        font-family: $fourth-font-family;
        font-size: rem(42);
        font-weight: $normal;
        letter-spacing: 0.1em;
        @include mq{
            font-size: rem(26);
            margin-bottom: rem(20);
            z-index: 1; 
            position: relative;
        }
    }
    &__read{
        text-align: center;
        font-size: rem(14);
        font-weight: $regular;
        line-height: calc(30/14);
        font-weight: $demilight;
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(30);
            text-align: left;
            z-index: 1; 
            position: relative;

        }
    }
    &__contents{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(110) rem(65);
        @include mq{
            grid-template-columns: 1fr;
            gap: rem(40);
        }
    }
    &__content:nth-of-type(2n){
        transform: translateY(rem(90));
        @include mq{
            transform: translateY(0);
        }
    }
    &__content{
        position: relative;
    }
    &__c-bgttl{
        // background: linear-gradient(90deg,#fcf0ee,#fdf1ed);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: $white;
        opacity: 0.3;
        position: relative;
        z-index: 0;
        font-size: rem(120);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        line-height: 0.7;
        margin-left: rem(-9);
        @include mq{
            font-size: rem(60);
            margin-left: rem(-4);
        }
    }
    &__c-img{
        margin-bottom: rem(20);
        position: relative;
        z-index: 1;
        @include mq{
            margin-bottom: rem(15);
        }
    }
    &__c-ttl{
        margin-bottom: rem(5);
        font-size: rem(21);
        font-family:$fourth-font-family ;
        // letter-spacing: 0.1em;
        font-weight: $normal;
        @include mq{
            margin-bottom: rem(10);
            font-size: rem(18);
        }
    }
    &__c-text{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.03em;
        line-height: calc(24/14);
        position:relative;
        z-index: 3;
    }
    &__c-bgtext{
        position: absolute;
        top: rem(96);
        left: rem(-8);
        transform: rotate(90deg);
        transform-origin: top left;
        font-size: rem(12);
        font-family: $third-font-family;
        font-weight: $regular;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(10);
            top: rem(48);
            left: rem(-3);
        }
    }
    &__bg-text{
        font-size: rem(150);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        color: $white;
        position: absolute;
        top: rem(-12);
        left: 100%;
        width: 110%;
        line-height: 0.8;
        transform: rotate(90deg);
        transform-origin:top left;
        z-index: 0;
        @include mq{
            font-size: rem(60);
            top: rem(-3);
            width: 200%;
        }
    }
}

.pc-approach{
    &__container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include mq{
            flex-direction: column;
        }
    }
    &__left{
        width: 50%;
        height: 100%;
        padding-right: rem(130);
        position: sticky;
        top: 0;
        @include mq{
            width: 100%;
            padding-right: unset;
            height: 0;
            z-index: -1;
            opacity: 0.15;
        }
    }
    &__fixedimg{
        height: 100vh;
        @include mq{
            height: 100vh;
        }
    }
    &__main{
        margin: rem(90) 0 rem(50);
        max-width: rem(620);
        width: 50%;
        @include mq{
            max-width: rem(600);
            margin: rem(40) auto 0;
            padding: 0 $padding-sp;
            width: 100%;
        }
    }
    &__contents{
        padding-left: rem(20);
        @include mq{
            padding-left: unset;
        }
    }
    &__ttl{
        margin-bottom: rem(15);
        font-size: rem(90);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(42);
            margin-bottom: rem(10);
        }
    }
    &__read{
        margin-bottom: rem(20);
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        line-height: calc(40/24);
        @include mq{
            margin-bottom: rem(15);
            font-size: rem(20);
        }
    }
    &__read2{
        margin-bottom: rem(110);
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(30/14);
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(60);
        }
    }
    &__content{
        margin-bottom: rem(75);
        position: relative;
        @include mq{
            margin-bottom: rem(60);
        }
    }   
    &__c-step{
        position: absolute;
        top: rem(-15);
        left: rem(-15);
        aspect-ratio: 1;
        width: rem(100);
        text-align: center;
        font-size:rem(18);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        color: $white;
        line-height: rem(100);
        border-radius: 50%;
        background: linear-gradient(#ffc4bf,#ceece9);
        z-index: 2;
        @include mq{
            font-size: rem(14);
            width: rem(80);
            line-height: rem(80);
        }
    }
    &__c-img{
        aspect-ratio: 580/400;
        margin-bottom: rem(15);
        @include mq{
            margin-bottom: rem(16);
        }
    }
    &__c-ttl span{
        font-size: rem(26);
        font-weight: $normal;
        transform: translateY(rem(4));
        display: inline-block;
        padding-right: rem(15);
        letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        background: linear-gradient(90deg,#ffc4bf,#ceece9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include mq{
            transform: unset;
            padding-right: rem(10);
            width: fit-content;
            display: block;
            font-size: rem(22);
            margin-bottom: rem(10);
        }
    }
    &__c-ttl{
        // letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        font-weight: $normal;
        font-size: rem(16);
        line-height: 1.3em;
        margin-bottom: rem(20);
        @include mq{
            font-size: rem(16);
            margin-bottom: rem(15);
            line-height: 1.4em;
        }
    }
    &__c-text{
        font-size: rem(14);
        font-weight: $demilight;
        // letter-spacing: 0.05em;
        line-height: calc(24/14);
    }
}