@use "../utility" as *;
// staffページタイトル
.staff{
    .page-top{
        margin-bottom: rem(180);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    .page-top__inner{
        position: relative;
    }
    .page-top__ttl{
        line-height: 1;
        margin-bottom: 0;
        @include mq(tb){
            margin-bottom: rem(20);
        }
    }
    .page-top__inner::after{
        position: absolute;
        content: "";
        display: block;
        max-width: rem(1250);
        width: 100%;
        height: 1px;
        bottom: 0;
        left: $padding-pc;
        background: $gray8;
        @include mq{
            max-width: calc(100% - rem(40));
            left: rem(20);
        }
    }
}

// read
.staff{
    .page-read__img{
        aspect-ratio: 595/400;
        width: 33%;
        @include mq{
            width: 100%;
        }
    }
    .page-read__read{
        margin-top: rem(75);
        letter-spacing: 0.05em;
        padding-right: rem(130);
        @include mq{
            margin-top: rem(15);
            padding-right: unset;
            font-size: rem(20);
            
        }
    }
    .page-read__bg-text{
        top: rem(-105);
        left: 21%;
        @include mq{
            top: rem(-20);
            z-index: -1;
            left:60%;
            font-size: rem(40);
        }
    }
}

//select
.ps-select{
    margin-bottom: rem(120);
    @include mq{
        margin-bottom: rem(60);
    }
    &__lists{
        display: flex;
        gap: rem(50);
        justify-content: center;
        @include mq{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: rem(20);
            padding: 0 $padding-sp;
            max-width: rem(600);
            margin: 0 auto;
        }
    }
    &__list{
        width: fit-content;
        border-bottom: 1px solid $black;
        @include mq{
            width: unset;
            text-align: center;
        }
    }
    &__list a{
        display: block;
        padding-bottom: rem(10);
        line-height: 1;
        color: $black;
        font-size: rem(24);
        font-weight: $regular;
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(18);
            padding-bottom: rem(7);
        }
    }    
}

// store
.ps-store:nth-of-type(2n) {
    background: $white;
    .ps-store__ttl {
        background: $white;
    }
}
.ps-store:nth-of-type(2n-1) {
    background: $gray6;
    .ps-store__ttl {
        background: $gray6;
    }
}
.ps-store{
    &__inner{
        max-width: rem(1250);
        padding:0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(50) 0 rem(120);
        @include mq{
            padding: rem(30) 0 rem(50);
        }
    }
    &__ttl{
        position: relative;
        font-size: rem(60);
        font-weight: $regular;
        letter-spacing: 0.05em;
        font-family: $third-font-family;
        line-height: 1;
        display: block;
        width: fit-content;
        transform: rotate(90deg);
        transform-origin: bottom left;
        padding-right: rem(30);
        z-index: 2;
        @include mq{
            font-size: rem(36);
            padding-right: rem(10);
        }
    }
    
    &__staffs{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(55) rem(50);
        padding-left: rem(140);
        position: relative;
        @include mq{
            grid-template-columns:1fr;
            gap:rem(40);
            padding-left: rem(50);
        }
    }
    &__staffs::before{
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: rem(33);
        height: 100%;
        width: 1px;
        background:$black;
        z-index: 1;
        @include mq{
            left: rem(12);
        }
    }
    &__img {
        // aspect-ratio: 320/400;
        margin-bottom: rem(40);
        position: relative;
        padding-top: 125%;
        @include mq {
            margin-bottom: rem(20);
        }
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__role{
        width: fit-content;
        background:$black;
        color: $white;
        line-height: 1;
        font-size: rem(14);
        letter-spacing: 0.05em;
        font-weight: $regular;
        padding: rem(10);
        margin-bottom: rem(20);
        @include mq{
            margin-bottom: rem(10);
            font-size: rem(12);
            padding: rem(6) rem(9);
        }
    }
    &__name{
        font-size: rem(30);
        letter-spacing: 0.05em;
        font-weight: $bold;
        font-family: $fourth-font-family;
        line-height: 1;
        @include mq{
            font-size: rem(24);
        }
    }
    &__name span{
        color: $gray9;
        font-size:rem(16) ;
        padding-left: rem(5);
        font-family: $fourth-font-family;
        vertical-align: middle;
        @include mq{
            font-size: rem(15);
        }
    }
}
