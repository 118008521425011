@use "../utility" as *;

.recruit{
    picture,
    img{
        height: 100%;
        object-fit: cover;
    }
}

// 共通パーツ
.noto{
    font-size: rem(14);
    // letter-spacing: 0.05em;
    line-height: calc(30/14);
    font-weight: $demilight;
}
.noto span{
    color: $pink4;
}

.mission-top{
    background-image: url("../img/recruit/mission1-1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    @include mq{
        background-position:75% 50%;
    }
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(110) 0 rem(170);
        color: $white;
        text-align: center;
        @include mq{
            padding: rem(55) 0 rem(80);
        }
    }
    &__num{
        font-size: rem(14);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(5);
        }
    }
    &__ttl{
        margin-bottom: rem(10);
        font-size: rem(62);
        // letter-spacing: 0.1em;
        font-weight: $normal;
        color: $white;
        font-family: $fourth-font-family;
        @include mq{
            margin-bottom: rem(5);
            font-size: rem(36);
        }
    }
    &__read{
        padding-bottom: rem(30);
        margin-bottom: rem(40);
        border-bottom: 1px solid $white;
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        // letter-spacing: 0.1em;
        color: $white;
        @include mq{
            font-size: rem(18);
            padding-bottom: rem(15);
            margin-bottom: rem(25);
        }
    }
    &__text{
        color: $white;
        font-size: rem(14);
        // letter-spacing: 0.05em;
        font-weight: $demilight;
        line-height: 2.2;
        @include mq{
            text-align: left;
            line-height: 1.8;
        }
    }
}

.pr-ttl1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(15);
    padding-bottom: rem(20);
    border-bottom:2px solid $black;
    @include mq{
        flex-direction: column;
        padding-bottom: rem(10);
        align-items: unset;
    }
    &__ttl{
        font-size: rem(80);
        font-weight: $regular;
        font-family: $third-font-family;
        line-height: 1;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(38);
            letter-spacing: unset;
        }
    }
    &__read{
        text-align: right;
        @include mq{
            text-align: center;
        }
    }
}

.pr-ttl2{
    background-size: cover;
    background-repeat: no-repeat;
    &__content{
        padding: rem(160) 0;
        @include mq{
            padding: rem(80) 0;
        }
    }
    &__ttl{
        text-align: center;
        font-size: rem(32);
        font-family: $third-font-family;
        font-weight: $regular;
        margin-bottom: rem(5);
        color: $white;
        @include mq{
            margin-bottom: rem(5);
            font-size: rem(24);
        }
    }
    &__subttl{
        text-align: center;
        font-size: rem(14);
        font-family: $fourth-font-family;
        font-weight: $normal;
        color: $white;
        @include mq{
            font-size: rem(12);
        }
    }
}

.pr-ttl3{
    padding-bottom: rem(20);
    font-size: rem(32);
    font-weight: $normal;
    line-height: 1;
    font-family: $fourth-font-family;
    border-bottom: 1px solid $black;
    @include mq{
        font-size: rem(24);
        padding-bottom: rem(10);
    }
}

.pr-info{
    &__item{
        display: flex;
        align-items: flex-start;
        gap: rem(15);
        padding: rem(18) 0;
        @include mq{
            align-items: unset;
            flex-direction: column;
            padding: rem(15) 0;
            gap: rem(10);
        }
    }
    &__label{
        width: 10%;
        font-size: rem(16);
        font-family: $fourth-font-family;
        font-weight: $bold;
        line-height: 1.5;
        @include mq{
            width: 100%;
            font-size: rem(15);
        }
    }
    &__data{
        width: 85%;
        @include mq{
            width: 100%;
        }
    }
    & .noto{
        line-height: 1.7;
        @include mq{
            font-size: rem(13);
        }
    }
    & b{
        font-weight: $bold;
    }
}
// ==============

.recruit{
    .page-top__ttl{
        margin-bottom: rem(20);
        @include mq{
            margin-bottom: rem(10);
        }
    }
    .page-top__inner{
        padding:0 rem(25) rem(105);
        @include mq{
            padding: 0 rem(20) rem(50);
        }
    }
    .page-top__read{
        font-feature-settings: 'palt';
    }
    .page-top__container{
        padding-top: rem(205);
        @include mq{
            padding-top: rem(100);
        }
    }
    .page-top__content{
        align-items: flex-end;
        @include mq{
            align-items: unset;
        }
    }
    .page-mv{
        @include mq{
            aspect-ratio: 50/22;
        }
    }
}

.pr-read{
    background-image: url("../img/recruit/mission1-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 center;
    &__inner{
        max-width:rem(1250) ;
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding:rem(165) 0 ;
        display: flex;
        align-items: flex-start;
        @include mq{
            padding: rem(55) 0 rem(80);
            flex-direction: column;
            gap: rem(20);
        }
    }
    &__left{
        width: 52%;
        padding-right: rem(55);
        @include mq{
            padding-right: unset;
            width: 100%;
        }
    }
    &__ttl{
        margin-bottom: rem(25);
        font-size: rem(42);
        line-height: calc(60/42);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: -0.02em;
        @include mq{
            font-size: rem(25);
            margin-bottom: rem(15);
        }
    }
    &__read{
        font-size: rem(24);
        line-height: calc(40/24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        // letter-spacing: 0.1em;
        @include mq{
            font-size: rem(18);
        }
    }
    &__right{
        width: 52%;
        @include mq{
            width: 100%;
        }
    }
    &__text{
        font-size: rem(18);
        font-family: $fourth-font-family;
        font-weight: $regular;
        line-height: calc(40/18);
        @include mq{
            font-size: rem(14);
            font-feature-settings: 'palt';
        }
    }
}



.pr-mission1{
    background: $pink3;
    &__inner{
        padding: 0 $padding-pc;
        margin: 0 auto;
        max-width: rem(1250);
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding-bottom: rem(120);
        @include mq{
            padding-bottom: rem(60);
        }
    }
    &__top{
        padding: rem(115) 0 rem(70);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        @include mq{
            flex-direction: column;
            gap: rem(30);
            align-items: unset;
            justify-content: unset;
            padding: rem(50) 0;
        }
    }
    &__t-img{
        width: 50%;
        padding:0 rem(30);
        @include mq{
            width: 100%;
            padding: unset;
        }
    }
    &__t-img picture{
        max-width: rem(465);
        margin-left: auto;
        display: block;
        @include mq{
            max-width: unset;
            margin-left:unset;
        }
    }
    &__t-contents{
        width: 50%;
        padding: 0 rem(40);
        @include mq{
            width: 100%;
            padding: unset;
        }
    }
    &__t-content{
        margin-bottom: rem(35);
        @include mq{
            background: #fff;
            padding: 20px;
            margin-bottom: rem(10);
        }
    }
    &__t-ttl span{
        font-size: rem(14);
        font-family: $third-font-family;
        letter-spacing: 0.1em;
        display: inline-block;
        padding-right: rem(12);
        background: linear-gradient( #ffc4bf , #ceece9 );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include mq{
            font-size: rem(12);
            padding-right: rem(7);
        }
    }
    &__t-ttl{
        margin-bottom: rem(12);
        font-size: rem(24);
        line-height: 1;
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        @include mq{
            font-size: rem(20);
            margin-bottom: rem(10);
        }
    }
    &__t-text{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(30/14);
    }

    &__middle{
        background-image: url("../img/recruit/mission1-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        padding: rem(100) rem(10) rem(90);
        @include mq{
            padding: rem(50) rem(10) rem(40);
        }
    }
    &__m-read{
        margin-bottom: rem(15);
        font-size: rem(32);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        line-height: calc(40/32);
        @include mq{
            font-size: rem(22);
            margin-bottom: rem(15);
            letter-spacing: unset;
            line-height: 1.5;
        }
    }
    &__m-text{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(30/14);
        @include mq{
            font-size: rem(12);
            text-align: left;
        }
    }

    &__bottom{
        padding: rem(60) 0 rem(130);
        background:$white;
        @include mq{
            padding: rem(30) 0 rem(60);
        }
    }
    &__b-content{
        display: flex;
        align-items: flex-start;
        margin: 0 rem(80);
        position: relative;
        @include mq{
            margin: 0 rem(10);
            flex-direction: column;
            align-items: unset;
        }
    }
    &__b-content::after{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 1px;
        display: block;
        content: "";
        background: $gray5;
        top: 0;
        left: rem(72);
        @include mq{
            display: none;
        }
    }
    &__b-content:nth-of-type(3)::after{
        display: none;
    }
    &__b-content:nth-of-type(1){
        padding-bottom: rem(60);
        @include mq{
            padding-bottom: rem(50);
        }
    }
    &__b-content:nth-of-type(2){
        padding-bottom: rem(90);
        @include mq{
            padding-bottom: rem(50);
        }
    }
    &__b-num{
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        width: rem(150);
        background: $pink2;
        border-radius: 50%;
        position: relative;
        z-index: 2;
        @include mq{
            width: rem(100);
            margin: 0 auto;
        }
    }
    &__b-num p{
        color: $white;
        font-size: rem(32);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        text-align: center;
        font-weight: $normal;
        line-height: 1;
        @include mq{
            font-size: rem(22);
        }

    }
    &__b-num p span{
        font-size: rem(14);
        font-family: $third-font-family;
        color: $white;
        font-weight: $regular;
        display: block;
        text-align: center;
        margin-bottom: rem(15);
        line-height: 1;
        letter-spacing: 0.06em;
        @include mq{
            margin-bottom: rem(5);
            font-size: rem(12);
        }
    }
    &__b-body{
        padding-left: rem(25);
        width: 90%;
        @include mq{
            padding-left: unset;
            width: 100%;

        }
    }
    &__b-body--1{
        margin-top: rem(30);
        @include mq{
            margin-top: rem(15);
        }
    }
    &__b-body--2{
        margin-top: rem(30);
        @include mq{
            margin-top: rem(15);
        }
    }
    &__b-body--3{
        margin-top: rem(15);
    }
    &__b-top1{
        margin-bottom: rem(5);
        font-size: rem(32);
        font-weight: $normal;
        // letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        line-height: 1.3;
        @include mq{
            letter-spacing: unset;
            margin-bottom: rem(10);
            font-size: rem(22);
        }
    }
    &__b-top2{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
    }
}

.pr-stage1{
    display: flex;
    align-items: flex-start;
    margin:rem(60) 0 rem(50);
    @include mq{
        align-items: unset;
        flex-direction: column;
        gap: rem(20);
        margin-top: rem(30);
        margin-bottom:rem(25);
    }
    &__img{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    &__text{
        width: 50%;
        padding-left: rem(45);
        @include mq{
            width: 100%;
            padding-left: unset;
        }
    }
    &__text1{
        padding: rem(9) rem(10);
        font-size: rem(16);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        font-weight: $normal;
        color: $orange;
        width: fit-content;
        border: 1px solid $orange;
        margin-bottom: rem(20);
        line-height: 1;
        @include mq{
            margin-bottom: rem(10);
            font-size: rem(15);
        }
    }
    &__text2{
        margin-bottom: rem(7);
        font-size: rem(21);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        font-weight: $normal;
        line-height: calc(36/21);
        @include mq{
            letter-spacing: unset;
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__text3{
        font-size: rem(14);
        line-height: calc(30/14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
    }
    &__s-contents{
        background: $gray;
        padding: rem(20) 0 rem(30);
        margin-bottom: rem(10);
        @include mq{
            padding: rem(15) 0;
        }
    }
    &__s-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(15);
        padding: 0 rem(30) rem(25);
        border-bottom:1px solid $gray10;
        @include mq{
            justify-content: unset;
            align-items: unset;
            flex-direction: column;
            padding: 0 rem(15) rem(15);
        }
    }
    &__s-ttl{
        font-size: rem(21);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        font-weight: $normal;
        @include mq{
            font-size: rem(18);
            letter-spacing: unset;
        }
    }
    &__s-items{
        display: flex;
        gap: rem(15);
        @include mq{
            flex-direction: column;
            gap: rem(10);
        }
    }
    &__s-item{
        position: relative;
        padding-left: rem(15);
        font-size: rem(16);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        @include mq{
            font-size: rem(15);
            padding-left: rem(13);
        }
    }
    &__s-item::before{
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        aspect-ratio: 1;
        width: rem(13);
        border-radius: 50%;
        // background: #000;
        @include mq{
            width: rem(10);
        }
    }
    &__s-item:nth-of-type(1)::before{
        background:#f6cbc7 ;
    }
    &__s-item:nth-of-type(2)::before{
        background:#b3d9d2 ;
    }
    &__s-item:nth-of-type(3)::before{
        background:#c7eff6 ;
    }
    &__s-dates{
        display: flex;
        gap: rem(35);
        max-width: rem(860);
        justify-content: center;
        margin: 0 auto;
        padding-top: rem(30);
        @include mq{
            max-width: unset;
            padding:rem(15) rem(10) 0;
            gap: rem(10);
        }
    }
    &__s-date{
        text-align: center;
        aspect-ratio: 1;
        width: rem(45);
        border-radius: 50%;
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: rem(45);
        // background: #000;
        @include mq{
            width: rem(30);
            font-size: rem(18);
            line-height: rem(30);
        }
    }
    &__s-date:nth-of-type(1),
    &__s-date:nth-of-type(2){
        background: #f6cbc7;
    }
    &__s-date:nth-of-type(3),
    &__s-date:nth-of-type(4),
    &__s-date:nth-of-type(5){
        background: #b3d9d2;
    }
    &__s-date:nth-of-type(6),
    &__s-date:nth-of-type(7){
        background: #c7eff6;
    }
    &__attention{
        font-size: rem(12);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        text-align: right;
        @include mq{
            font-size: rem(10);
        }
    }
}

.pr-stage2{
    margin-top: rem(55);
    @include mq{
        margin-top: rem(25);
    }
    &__contents{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(20);
        @include mq{
            grid-template-columns: 1fr;
        }
    }
    &__content:nth-of-type(1){
        background: #faedeb;
    }
    &__content:nth-of-type(2){
        background: #e0f1ee;
    }
    &__c-img{
        width: 100%;
        margin-bottom: rem(40);
        aspect-ratio: 420/250;
        @include mq{
            margin-bottom: rem(25);
        }
    }
    &__c-ttl{
        margin-bottom: rem(10);
        text-align: center;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__c-ttl span{
        display: block;
        font-size: rem(14);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        font-weight: $normal;
        text-align: center;
        line-height: 1.5;
        @include mq{
            font-size: rem(12);
        }
    }
    &__c-texts{
        padding:0 rem(35) rem(40);
        @include mq{
            padding:0 rem(15) rem(20);
        }
    }
    &__c-ttl{
        font-size: rem(21);
        font-weight: $normal;
        letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        margin-bottom: rem(20);
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__c-text{
        font-size: rem(14);
        font-weight: $demilight;
        line-height: calc(30/14);
        letter-spacing:0.05em;
        @include mq{
            // font-size: rem(12);
        line-height: calc(22/14);
        }
    }
}
.pr-stage3{
    margin-top: rem(30);
    @include mq{
        margin-top: rem(20);
    }
    &__content{
        display:flex;
        align-items: center;
        @include mq{
            flex-direction:column;
            gap: rem(20);
            align-items: unset;
        }
    }
    &__c-img{
        aspect-ratio: 1;
        width: 50%;
        @include mq{
            // aspect-ratio: 3/2;
            padding-top: 68%;
            width: 100%;
            position: relative;
            aspect-ratio: initial;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__c-body{
        padding-left: rem(40);
        width: 50%;
        @include mq{
            padding-left: unset;
            width: 100%;
        }
    }
    &__c-ttl{
        margin-bottom: rem(10);
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        @include mq{
            font-size: rem(18);
        }
    }
    &__c-text{
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        margin-bottom: rem(25);
        line-height: 2.1;
        @include mq{
            margin-bottom: rem(15);
        }
    }
    &__c-bottom{
        background:#f6beb7;
        text-align: center;
        color: $white;
        font-size: rem(15);
        font-weight: $normal;
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        padding:rem(25) 0;
        @include mq{
            font-size: rem(14);
            padding:rem(15) 0;
        }
    }
    &__c-bottom span{
        display: block;
        margin-bottom: rem(15);
        font-size: rem(21);
        font-weight: $normal;
        letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        @include mq{
            margin-bottom: rem(10);
        }
    }
}

.pr-mission2{
    background:$pink6 ;
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        padding-bottom: rem(130);
        @include mq{
            padding: 0 $padding-sp rem(60);
            max-width: rem(600);
        }
    }
    &__top{
        background-image: url("../img/recruit/mission2-1.jpg");
    }
}

.pr-point1{
    &__container{
        margin: rem(120) 0 rem(60);
        background: $white;
        display: flex;
        align-items: center;
        @include mq{
            margin: rem(60) 0 rem(30);
            flex-direction: column;
            gap: rem(20);
        }
    }
    &__img{
        padding-right: rem(20);
        width: 50%;
        @include mq{
            width: 100%;
            padding-right: unset;
        }
    }
    &__texts{
        width: 50%;
        padding-left: rem(20);
        @include mq{
            width: 100%;
            padding-left: unset;
        }
    }
    &__top{
        margin-bottom: rem(25);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__text span{
        color: $pink4;
    }
}
.point-container{
    padding: rem(80);
    background: $white;
    @include mq{
        padding: rem(30) rem(10) rem(25);
    }
}
.point-top{
    display: flex;
    align-items: center;
    gap: rem(13);
    @include mq{
        gap: rem(10);
    }
    &__num{
        aspect-ratio: 1;
        width: rem(100);
        text-align: center;
        color:$white ;
        font-size: rem(18);
        font-weight: $normal;
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        line-height: rem(100);
        border-radius: 50%;
        background: linear-gradient(#ffc4bf,#ceece9);
        @include mq{
            font-size: rem(14);
            line-height: rem(65);
            width: rem(65);
        }
    }
    // &__read{
    //     width: 90%;
    //     @include mq{
    //         width: 89%;
    //     }
    // }
    &__ttl span{
        margin-top: rem(5);
        display: block;
        font-size: rem(21);
        font-family: $fourth-font-family;
        // letter-spacing: 0.1em;
        font-weight: $normal;
        @include mq{
            font-size: rem(16);
            margin-top: rem(3);
        }
    }
    &__ttl{
        font-size: rem(32);
        font-family: $fourth-font-family;
        // letter-spacing: 0.1em;
        font-weight: $normal;
        line-height: 1.2;
        width: calc(100% - rem(115));
        @include mq{
            font-size: rem(20);
            width: 75%;
        }
    }
}

.pr-point2{
    &__top{
        margin-bottom:rem(40) ;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__container{
        display: flex;
        align-items: center;
        margin-bottom: rem(50);
        @include mq{
            flex-direction: column-reverse;
            align-items: unset;
            margin-bottom: rem(30);
            gap: rem(20);
        }
    }
    &__circle{
        position: absolute;
        top:rem(-60);
        right:rem(-50);
        aspect-ratio: 1;
        width: rem(160);
        background: $pink5;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq{
            top: rem(-20);
            right: rem(-20);
            width: rem(100);
        }
    }
    &__texts{
        width: 50%;
        padding-right: rem(20);
        @include mq{
            padding-right: unset;
            width: 100%;
        }
    }
    &__c-text{
        font-family: $fourth-font-family;
        font-size: rem(16);
        font-weight: $bold;
        color: $white;
        line-height:calc(20/16) ;
        @include mq{
            font-size: rem(12);
        }
    }
    &__c-text span{
        line-height: 1;
        font-size: rem(42);
        font-family: $fourth-font-family;
        font-weight: $bold;
        color: $white;
        @include mq{
            font-size: rem(28);
        }
    }
    &__text{
        margin-bottom: rem(15);
        @include mq{
            margin-bottom: rem(10);
        }
    }
    &__bottom{
        background: $pink5;
        padding: rem(15) rem(30) rem(15) rem(20);
        display: flex;
        align-items: center;
        gap: rem(15);
        @include mq{
            padding: rem(10) rem(15) rem(10) rem(10);
        }
    }
    &__b-num{
        aspect-ratio: 1;
        width: rem(80);
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @include mq{
            width: rem(70);
        }
    }
    &__b-num p{
        text-align: center;
        font-size: rem(16);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        color:$pink5;
        line-height: calc(20/16);
        @include mq{
            font-size: rem(14);
        }
    }
    &__b-text{
        width: 90%;
        color: $white;
        line-height: calc(22/14);
        @include mq{
            font-size: rem(14);
        }
    }
    &__b-text .small{
        font-size: rem(12);
        color: $white;
        @include mq{
            // font-size: rem(10);
        }
    }
    &__img{
        width: 50%;
        padding-left: rem(20);
        aspect-ratio:520/365 ;
        position: relative;
        @include mq{
            width: 100%;
            padding-left: unset;
            aspect-ratio:500/365 ;
        }
    }
}

.pr-point3{
    &__top{
        margin-bottom:rem(30) ;
        @include mq{
            margin-bottom: rem(15);
        }
    }
    &__container{
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    &__contents{
        display: flex;
        align-items: center;
        margin-bottom: rem(65);
        @include mq{
            align-items: unset;
            flex-direction: column;
            margin-bottom: rem(30);
            gap: rem(20);
        }
    }
    &__img{
        aspect-ratio:520/365;
        width: 50%;
        padding-right: rem(20);
        @include mq{
            aspect-ratio: 500/365;
            padding-right: unset;
            width: 100%;
        }
    }
    &__texts{
        width: 50%;
        padding-left: rem(20);
        @include mq{
            padding-left: unset;
            width: 100%;
        }
    }
    &__items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(40);
        @include mq{
            grid-template-columns: 1fr;
            gap: rem(20);
        }
    }
    &__item{
        padding: rem(30) rem(10) rem(40);
        @include mq{
            padding: rem(15) rem(10) rem(20);
        }
    }
    &__item:nth-of-type(1){
        background:$pink7;
    }
    &__item:nth-of-type(2){
        background:$green;
    }
    &__i-ttl{
        text-align: center;
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        margin-bottom: rem(20);
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__i-items{
        display: flex;
        flex-wrap: wrap;
        gap:rem(10) rem(5);
        justify-content: center;
    }
    &__i-item{
        width: fit-content;
        padding:rem(10) rem(20) ;
        font-weight: $normal;
        border-radius: rem(100);
        background: $white;
        line-height: 1;
        text-align: center;
        @include mq{
            padding: rem(10);
        }

    }
}

.pr-point4{
    &__container{
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    &__top{
        margin-bottom:rem(40) ;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    & .point-top__ttl{
        letter-spacing: -0.02em;
        @include mq(tb){
            letter-spacing: -0.05em;
        }
        @include mq{
            font-feature-settings: unset;
        }
    }
    &__contents{
        margin-bottom:rem(60) ;
        display: flex;
        align-items: center;
        @include mq{
            margin-bottom: rem(30);
            align-items: unset;
            flex-direction: column-reverse;
            gap: rem(20);
        }
    }
    &__texts{
        width: 52%;
        padding-right: rem(20);
        @include mq{
            width: 100%;
            padding-right: unset;
        }
    }
    &__img{
        width: 50%;
        padding-left: rem(15);
        @include mq{
            width: 100%;
            padding-left: unset;
        }
    }
    &__items{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(10);
        @include mq{
            grid-template-columns: 1fr;
        }
    }
    &__item{
        background:$pink7;
        text-align: center;
        padding: rem(25) 0;
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: 1;
        @include mq{
            font-size: rem(15);
            padding: rem(10) 0;
        }
    }
}

.pr-point5{
    &__contents{
        margin-bottom: rem(50);
        display: flex;
        align-items: center;
        @include mq{
            flex-direction: column;
            gap: rem(20);
            margin-bottom: rem(25);
        }
    }
    &__container{
        padding-bottom: rem(100);
        @include mq{
            padding-bottom: rem(50);
        }
    }
    &__img{
        width: 50%;
        padding-right: rem(20);
        @include mq{
            width: 100%;
            padding-right: unset;
        }
    }
    &__texts{
        width: 50%;
        padding-left: rem(20);
        @include mq{
            width: 100%;
            padding-left: unset;
        }
    }
    &__top{
        margin-bottom:rem(40) ;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__items{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: rem(10);
        @include mq{
            grid-template-columns: 1fr 1fr;
        }
    }
    &__item{
        padding:rem(40) rem(10) ;
        background: $pink7;
        @include mq{
            padding: rem(20) rem(10);
        }
    }
    &__i-circle{
        aspect-ratio: 1;
        border-radius: 50%;
        margin: 0 auto;
        height: rem(100);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background:#e7aea6 ;
        margin-bottom: rem(12);
        @include mq{
            height: rem(80) !important;
            margin-bottom: rem(15);
        }
        @include mq(tb){
            height: rem(140);
        }
    }
    &__i-c-text{
        margin-top: rem(5);
        font-size: rem(13);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(17/13);
        color: $white;
        @include mq{
            font-size: rem(11);
        }
    }
    &__i-c-text span{
        font-family: $third-font-family;
        font-size: rem(24);
        font-weight: $regular;
        display: block;
        line-height: calc(28/24);
        color: $white;
        line-height: 1;
        margin-top: rem(5);
        @include mq{
            font-size: rem(20);
        }
    }
    &__i-text1{
        margin-bottom: rem(14);
        font-size: rem(21);
        font-family: $fourth-font-family;
        line-height: 1;
        font-weight: $normal;
        text-align: center;
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__i-text2{
        text-align: center;
        @include mq{
            line-height: 1.6em;
            font-size: rem(12);
            text-align: left;
        }
    }
    &__i-text2 .small{
        font-size: rem(12);
        color: $black;
        display: block;
        @include mq{
            margin-top: rem(7);
            font-size: rem(10);
        }
    }
    &__item--green{
        background: #e0f1ee;
    }
    &__item--green .pr-point5__i-circle{
        background: #81c3b7;
    }
}

.pr-inter{
    overflow: hidden;
    margin-bottom: rem(140);
    @include mq{
        margin-bottom: rem(70);
    }
    &__inner{
        max-width: rem(1250);
        padding:0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp;
        }
    }
    &__container{
        padding-top: rem(100);
        @include mq{
            padding-top: rem(50);
        }
    }
    &__ttls{
        margin-bottom: rem(90);
        @include mq{
            margin-bottom: rem(45);
        }
    }
    &__contents{
        margin-bottom: rem(35);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    &__content{
        padding: rem(90) rem(120);
        background: linear-gradient(rgba(255,196,191,0.5),rgba(206,236,233,0.5));
        border-radius: rem(10);
        height: auto;
        @include mq{
            padding: rem(40) rem(15);
            border-radius: rem(5);
        }
    }
    &__c-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(15);
        margin-bottom: rem(30);
        position: relative;
        @include mq{
            flex-direction: column;
            gap: rem(15);
            margin-bottom: rem(15);
        }
    }
    &__c-top::after{
        position: absolute;
        top:0;
        left: rem(-35);
        display: block;
        content: "";
        width: rem(60);
        aspect-ratio: 60/45;
        background-image: url("../img/recruit/staff-com.png");
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
        @include mq{
            width: rem(20);
            left: rem(-10);
        }
    }
    &__c-read{
        font-size: rem(32);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(50/32);
        position: relative;
        z-index: 2;
        @include mq{
            font-size: rem(20);
        }
    }
    &__c-profile{
        display: flex;
        align-items: center;
        gap: rem(30);
        @include mq{
            gap: rem(15);
        }
    }
    &__c-name{
        font-family: $fourth-font-family;
        font-weight: $normal;
        font-size: rem(15);
        line-height: 1;
        @include mq{
            font-size: rem(14);
        }
    }
    &__c-name span{
        display: inline-block;
        padding-left: rem(10);
        font-size: rem(24);
        font-family: $fourth-font-family;
        @include mq{
            font-size: rem(20);
        }
    }
    &__c-img{
        aspect-ratio: 1;
        width: rem(130);
        border-radius: 50%;
        @include mq{
            width: rem(80);
        }
    }
    & .swiper-pagination{
        position: unset;
    }
    & .swiper-pagination-bullet{
        aspect-ratio: 1;
        width: rem(10);
        height: unset;
        background: #d6e6e3;
        opacity: 1;
    }
    & .swiper-pagination-bullet-active{
        background:#81c3b7 ;
    }
}


.pr-event{
    &__inner{
        max-width: rem(1465);
        padding-left: $padding-pc;
        margin-left: auto;
        @include mq{
            max-width: unset;
            padding: unset;
            margin: 0 auto;
        }
    }
    &__container{
        padding-bottom: rem(120);
        display: flex;
        gap: rem(15);
        align-items: flex-start;
        @include mq{
            flex-direction: column;
            gap: rem(30);
            padding-bottom: rem(60);
        }
    }
    &__top{
        width: 25%;
        @include mq{
            width: 100%;
            padding: 0 $padding-sp;
            margin: 0 auto;
            max-width: rem(600);
        }
    }
    &__ttl{
        margin-bottom: rem(30);
        width: fit-content;
        font-size: rem(60);
        font-family: $third-font-family;
        font-weight: $regular;
        line-height: 1;
        background: linear-gradient(90deg, #ffc4bf , #ceece9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include mq{
            font-size: rem(36);
            margin-bottom: rem(15);
        }
    }
    &__read{
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(32/21);
        @include mq{
            font-size: rem(18);
        }
    }
    &__read span{
        font-size: rem(16);
        font-family: $fourth-font-family;
        display: block;
        @include mq{
            font-size: rem(14);
        }
    }
    &__wrapper{
        width: 75%;
        overflow: hidden;
        @include mq{
            width: 100%;
            padding-left: rem(20);
        }
    }
    &__c-img{
        aspect-ratio: 1;
        margin-bottom: rem(15);
        @include mq{
            margin-bottom: rem(8);
        }
    }
    &__c-tags{
        display: flex;
        flex-wrap: wrap;
        gap: rem(15);
        @include mq{
            gap: rem(10);
        }
    }
    &__c-tag{
        position: relative;
        padding-left: rem(10);
    }
    &__c-tag::before{
        position: absolute;
        content: "#";
        display: block;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: rem(14);
    }
}

.pr-message{
    background: #f7f7f7;
    &__inner{
        max-width: rem(1250);
        padding:  0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp;
        }
    }
    &__container{
        padding: rem(120) 0 ;
        @include mq{
            padding: rem(60) 0;
        }
    }
    &__ttls{
        margin-bottom: rem(100);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__read{
        text-align: center;
    }
    &__read1{
        margin-bottom: 0;
        font-size: rem(16);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: 1;
        @include mq{
            font-size: rem(15);
            line-height: auto;
            margin-bottom: rem(5);
        }
    }
    &__read2{
        margin-bottom: rem(20);
        font-size: rem(60);
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(36);
            margin-bottom: rem(15);
        }
    }
    &__read3{
        font-size: rem(21);
        line-height: calc(40/21);
        margin-bottom: rem(60);
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            margin-bottom: rem(30);
            font-size: rem(15);
        }
    }
    &__content{
        background: linear-gradient(rgba(255,196,191,0.5),rgba(206,236,233,0.5));
        padding: rem(80) rem(100) rem(120);
        @include mq{
            padding: rem(40) rem(15) rem(50);
        }
    }
    &__c-ttl{
        text-align: center;
        font-size: rem(32);
        font-family: $fourth-font-family;
        font-weight: $normal;
        margin-bottom: rem(5);
        @include mq{
            font-size:rem(24);
        }
    }
    &__c-read{
        text-align: center;
        margin-bottom: rem(60);
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(30);
        }
    }
    &__c-text{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(25);
        }
    }
    &__c-name{
        text-align: right;
        font-size: rem(14);
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(12);
        }
    }
    &__c-name span{
        text-align: right;
        font-size: rem(26);
        padding-left: rem(10);
        font-weight: $normal;
        font-family: $fourth-font-family;
        display: inline-block;
        @include mq{
            font-size: rem(20);
        }
    }
}

.pr-company{
    &__ttls{
        background-image: url("../img/recruit/company.jpg");
        margin-bottom: rem(100);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin:0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__contents{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(20);
        margin-bottom: rem(70);
        @include mq{
            gap: rem(10);
            grid-template-columns: 1fr;
            margin-bottom: rem(30);
        }
    }
    &__content{
        background: $gray;
        aspect-ratio: 590/240;
        padding-top: rem(50);
        @include mq{
            padding-top: rem(25);
            aspect-ratio: unset;
            padding-bottom: rem(40);
            padding: rem(25) rem(10) rem(40);
        }
    }
    &__content:nth-of-type(1) .pr-company__c-text1{
        margin-bottom: rem(50);
        @include mq{
            margin-bottom: rem(25);
        }
    }
    &__content:nth-of-type(1) .pr-company__c-text2{
        font-size: rem(26);
        @include mq{
            font-size: rem(18);
        }
    }

    &__content:nth-of-type(2) .pr-company__c-text1{
        margin-bottom: rem(25);
        @include mq{
            margin-bottom: rem(15);
        }
    }
    &__content:nth-of-type(2) .pr-company__c-text2{
        font-size: rem(21);
        line-height: calc(40/21);
        @include mq{
            font-size: rem(15);
            font-feature-settings: 'palt';
        }
    }
    &__c-text1{
        font-size: rem(14);
        text-align: center;
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(12);
        }
    }
    &__c-text2{
        font-family: $fourth-font-family;
        text-align: center;
        font-weight: $normal;
    }
    &__i-ttl{
        margin-bottom: rem(35);
        @include mq{
            margin-bottom: rem(15);
        }
    }
}

.pr-info--1{
    margin-bottom: rem(120);
    @include mq{
        margin-bottom: rem(50);
    }
}
.pr-info--2 li,
.pr-info--3 li{
    border-bottom: 1px solid $gray5;
}

.pr-job{
    &__ttls{
        background-image: url("../img/recruit/job.jpg");
        margin-bottom: rem(110);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__inner{
        max-width: rem(1250);
        padding:0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__contents{
        margin-bottom: rem(100);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__content{
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__content:nth-of-type(1){
        margin-bottom: rem(80);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__content:nth-of-type(2){
        margin-bottom: rem(40);
        @include mq{
            margin-bottom: rem(50);
        }
    }
    &__c-ttl{
        margin-bottom:rem(40) ;
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__info{
        display: flex;
        align-items: flex-start;
        @include mq{
            flex-direction: column;
        }
    }
    &__info--1{
        margin-bottom: rem(60);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    & .small{
        font-size: rem(12);
        color: $black;
        @include mq{
            font-size: rem(10);
        }
    }
    &__step{
        font-size: rem(21);
        font-weight: $normal;
        font-family: $fourth-font-family;
        text-align: center;
        padding: rem(40) rem(10);
        background: linear-gradient(90deg,rgba(255,196,191,0.5),rgba(206,236,233,0.5));
        @include mq{
            padding: rem(20) rem(10);
            font-size: rem(16);
        }
    }
    &__button{
        max-width: rem(400);
        margin: 0 auto;
        display: block;
        text-align: center;
        padding: rem(40) rem(10);
        background:$black ;
        color: $white;
        font-size: rem(21);
        font-weight: $normal;
        font-family: $fourth-font-family;
        line-height: 1;
        position: relative;
        border-radius: rem(10);
        @include mq{
            max-width: unset;
            padding: rem(25) rem(10);
            font-size: rem(18);
            border-radius: rem(5);
        }
    }
    &__button span{
        position: absolute;
        font-size: rem(24);
        top: 50%;
        transform: translateY(-50%);
        right: rem(25);
        @include mq{
            font-size: rem(20);
            right: rem(15);
        }
    }
}

.pr-info--2{
    width: 50%;
    padding-right: rem(10);
    @include mq{
        width: 100%;
        padding-right: unset;
    }
}
.pr-info--3{
    width: 50%;
    padding-left: rem(10);
    @include mq{
        padding-left: unset;
        width: 100%;
    }
}

.pr-info--2 .pr-info__item,
.pr-info--3 .pr-info__item{
    padding-left: rem(20);
    padding-right: rem(15);
    @include mq{
        padding-left: unset;
        padding-right: unset;
    }
}

.pr-info--2 .pr-info__label,
.pr-info--3 .pr-info__label{
    width: 25%;
    @include mq{
        width: 100%;
    }
}
.pr-info--2 .pr-info__data{
    margin-top: unset;
    font-feature-settings: 'palt';
    width: 73%;
    @include mq{
        width: 100%;
    }
}
.pr-info--3 .pr-info__data{
    margin-top: unset;
    font-feature-settings: 'palt';
    width: 80%;
    @include mq{
        width: 100%;
    }
}