@use "../utility" as *;

.view-more{
    font-size: rem(24);
    letter-spacing: 0.05em;
    font-family: $third-font-family;
    color: $black;
    padding-bottom: rem(15);
    line-height: 1;
    display: block;
    width: fit-content;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    @include mq{
        font-size: rem(18);
        padding-bottom: rem(7);
    }
    &__line{
        position: absolute;
        bottom: rem(1);
        left: 0;
        width: 100%;
        height: rem(1);
        background: $black;
    }
}
.view-more:hover{
    .view-more__line{
        animation:viewMore-hover 0.5s ease-in-out;
    }
}
