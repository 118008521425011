@use "../utility" as *;

.card1{
    display: block;
    max-width: rem(500);
    color: $black;
    transition: all 0.3s ease-in-out;
    &__img{
        aspect-ratio: 500/336;
        margin-bottom: rem(15);
        @include mq{
            margin-bottom: rme(10);
        }
    }
    &__img picture{
        height: 100%;
    }
    &__img picture img{
        height: 100%;
    }
    &__text{
        display: flex;
        gap: rem(15);
        align-items: flex-start;
        transition: all 0.3s ease-in-out;
        @include mq{
            gap: rem(8);
        }
    }
    &__date{
        display: flex;
        gap: rem(5);
        letter-spacing: 0.05em;
        align-items: center;
        @include mq{
            gap: rem(3);
        }
    }
    &__year-month{
        line-height: 1;
        font-size: rem(14);
        font-family: $third-font-family;
        @include mq{
            font-size: rem(12);
        }
    }
    &__day{
        line-height: 1;
        font-family: $third-font-family;
        font-size: rem(30);
        @include mq{
            font-size: rem(24);
        }
    }
    &__title{
        font-size: rem(18);
        letter-spacing: 0.1em;
        font-family: $fourth-font-family;
        font-weight: $normal;
        @include mq{
            font-size: rem(15);
        }
    }
    &__info{
        margin-top: rem(15);
        display: flex;
        align-items: center;
        gap: rem(20);
    }
    &__cat{
        font-size: rem(14);
        font-weight: $regular;
        letter-spacing: 0.05em;
        color: $white;
        padding: rem(7) rem(7) rem(8);
        line-height: 1;
        background: $black;
    }
    &__tags{
        display: flex;
        gap: rem(15);
    }
    &__tag{
        display: block;
        font-size: rem(16);
        font-weight: $regular;
        color: $black;
        letter-spacing: 0.05em;
    }
    &__tag::before{
        content: "#";
        color:$gray9 ;
        padding-right: rem(3);
    }
}
.card1:hover{
    .card1__text{
        color: $hover;
    }
}