@use "../utility" as *;

.service{
    .page-top__ttl{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(10);
        }
    }
}
.ps-read{
    img{
        object-fit: cover;
        height: 100%;
    }
    picture{
        height: 100%;
        object-fit: cover;
    }
    &__container{
        padding: rem(120) 0;
        display: flex;
        position: relative;
        align-items: center;
        @include mq{
            max-width: rem(600);
            margin: 0 auto;
            flex-direction: column;
            padding: rem(60) rem(20);
            gap: rem(30);
            align-items: unset;
        }
    }
    &__img1{
        padding-right:rem(110) ;
        width: 50%;
        @include mq{
            width: 100%;
            padding-right: unset;
            aspect-ratio: 3/2;
            overflow: hidden;
        }
    }
    &__text{
        width: 50%;
        max-width: rem(620);
        padding-right: rem(20);
        margin-top:rem(-200);
        @include mq{
            width: 100%;
            max-width: unset;
            padding-right: unset;
            margin-top: unset;
        }
    }
    &__catch{
        font-size: rem(42);
        // letter-spacing: 0.03em;
        font-weight: $normal;
        line-height: calc(60/42);
        font-family: $fourth-font-family;
        margin-bottom: rem(60);
        @include mq{
            font-size: rem(22);
            margin-bottom: rem(20);
        }
    }
    &__des{
        margin-bottom: rem(40);
        font-size: rem(18);
        // letter-spacing: 0.05em;
        font-weight: $regular;
        font-family: $fourth-font-family;
        line-height: calc(40/18);
        @include mq(tb){
            font-feature-settings: 'palt';
        }
        @include mq{
            font-size: rem(14);
            margin-bottom: rem(20);
            font-feature-settings: unset;
        }

    }
    &__des:last-of-type{
        margin-bottom: unset;
        @include mq{
            margin-bottom: rem(10);
        }
    }
    &__img2{
        position: absolute;
        top: 43%;
        right: 0;
        width:15% ;
        aspect-ratio: 1;
        @include mq{
            position: unset;
            width: 45%;
            margin-right: auto;
        }
    }
    &__img3{
        position: absolute;
        aspect-ratio:330/380 ;
        width: 18%;
        bottom: rem(-60);
        left: 50%;
        transform: translateX(50%);
        @include mq{
            left: unset;
            bottom: rem(-30);
            transform: unset;
            right: 0;
            width: 40%;
        }
    }
}

.ps-ele{
    background: $gray;
    &__inner{
        max-width: rem(1200);
        padding: 0 rem(60);
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding:rem(160) 0 rem(120) ;
        display: flex;
        @include mq{
            padding: rem(80) 0 rem(60);
            gap: rem(20);
            flex-direction: column;
        }
    }
    &__texts{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    
    &__top{
        display: flex;
        gap: rem(10);
        align-items: flex-end;
        margin-bottom: rem(40);
        @include mq{
            margin-bottom: rem(20);
        }
    }
    &__top-left{
        font-size: rem(240);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        line-height: 0.73;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background: url("../img/service/element1.png");
        background-size: contain;
        background-repeat: no-repeat;
        @include mq{
        line-height: 0.87;
        font-size: rem(90);
        }
    }
    &__top-right{
        font-family: $third-font-family;
        font-size: rem(60);
        // letter-spacing: 0.05em;
        font-weight: $regular;
        line-height: 1.2;
        @include mq{
            font-size: rem(36);
        }
    }
    &__top-right span{
        display: block;
        font-size:rem(21) ;
        line-height: calc(40/21);
        font-family: $third-font-family;
        @include mq{
            font-size: rem(18);
        }
    }
    &__bottom{
        font-size: rem(18);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(40/18);
        letter-spacing: 0.05em;
        font-feature-settings: 'palt';
        @include mq{
            font-size: rem(16);
        }
    }
    &__contents{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:rem(35) rem(30);
        width: calc(50% - rem(130));
        margin-left: rem(130);
        position: relative;
        @include mq{
            width: 100%;
            margin-left: unset;
            gap: rem(20);
        }
    }
    &__contents::after{
        position: absolute;
        content: "";
        display: block;
        border-radius: 50%;
        border:3px solid $white ;
        width: rem(380);
        aspect-ratio: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
        @include mq{
            width: rem(250);
            border:2px solid $white;
        }
    }
    &__content{
        position: relative;
        z-index: 2;
        border-radius: 50%;
        aspect-ratio: 1;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__content:nth-of-type(1){
        background-image: url("../img/service/element2.png");
    }
    &__content:nth-of-type(2){
        background-image: url("../img/service/element3.png");
    }
    &__content:nth-of-type(3){
        background-image: url("../img/service/element4.png");
    }
    &__content:nth-of-type(4){
        background-image: url("../img/service/element5.png");
    }
    
    &__content-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        letter-spacing: 0.05em;
        width: 90%;
        text-align: center;
    }
    &__content-text1{
        color: $pink;
        margin-bottom: rem(10);
        font-size: rem(14);
        font-family: $third-font-family;
        font-weight: $regular;
        @include mq{
            font-size: rem(12);
            margin-bottom: rem(7);
        }
    }
    &__content-text2{
        font-size: rem(24);
        font-family: $fourth-font-family;
        font-weight: $normal;
        line-height: calc(30/24);
        @include mq{
            font-size: rem(18);
        }
    }
}

.ps-service{
    @include mq{
        overflow: hidden;
    }
    &__inner{
        max-width: rem(1550);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(110) 0 rem(170);
        @include mq{
            padding: rem(40) 0 rem(60);
        }
    }
    &__ttl{
        margin-bottom: rem(120);
        font-size: rem(90);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        text-align: center;
        @include mq{
            font-size: rem(42);
            margin-bottom: rem(40);
        }
    }
    &__contents{
        max-width: rem(1200);
        margin: 0 auto;
        position: relative;
        @include mq{
            max-width: unset;
            margin: unset;
        }
    }
    &__contents::after{
        z-index: -1;
        position: absolute;
        height: 108%;
        width: 1px;
        content: "";
        background: $gray5;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        @include mq{
            height: 103%;
        }
    }
    &__content{
        display: flex;
        align-items: center;
        position: relative;
        padding: rem(70) 0;
        gap: rem(130);
        margin-bottom: rem(70);
        @include mq{
            flex-direction: column;
            gap: rem(20);
            padding:rem(30) 0;
            margin-bottom: rem(40);
        }
    }
    &__img{
        height: 100%;
        width: 58%;
        @include mq{
            height: auto;
            width: 100%;
        }
    }
    &__texts{
        width: 36%;
        position: relative;
        z-index: 1;
        @include mq{
            width: 100%;
        }
    }
    &__text1{
        margin-bottom: rem(20);
        font-size: rem(32);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        text-align: center;
        @include mq{
            margin-bottom: rem(10);
            font-size: rem(24);
        }
    }
    &__text2{
        margin-bottom: rem(20);
        font-size: rem(24);
        font-family: $fourth-font-family;
        letter-spacing: 0.05em;
        font-weight: $normal;
        line-height: calc(36/24);
        text-align: center;
        @include mq{
            font-size: rem(20);
            margin-bottom: rem(10);
        }
    }
    &__text3{
        font-size: rem(14);
        font-weight: $demilight;
        line-height: calc(30/14);
        text-align: left;
    }
    &__bgimg{
        position: absolute;
        top: 0;
        left: rem(-150);
        height: 100%;
        width: rem(380);
        z-index: -1;
        @include mq{
            left:rem(-50);
            width:50%;
            height: 74vw;
            max-height: rem(430);
        }
    }
    &__bgimg picture,
    &__bgimg img{
        height: 100%;
    }
    &__content:nth-of-type(2n){
        flex-direction: row-reverse;
        @include mq{
            flex-direction: column;
        }
    }
    &__content:nth-of-type(2n) .ps-service__bgimg{
        left: unset;
        right: rem(-150);
        @include mq{
            right: rem(-50);
        }
    }
}

.ps-pick__img{
    aspect-ratio: 1680/680;
    width: 100%;
}

.ps-pick{
    overflow: hidden;
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(100) 0 rem(120);
        position: relative;
        @include mq{
            padding: rem(20) 0 rem(60);
        }
    }
    &__top{
        display: flex;
        margin-bottom: rem(100);
        @include mq{
            flex-direction: column;
            gap: rem(20);
            margin-bottom: rem(40);
        }
    }
    &__left{
        width: 50%;
        padding-right: rem(20);
        text-align: left;
        margin-top: rem(40);
        @include mq{
            width: 100%;
            padding-right: unset;
            margin-bottom: rem(20);
        }
    }
    &__ttl{
        font-weight: $normal;
        font-size: rem(60);
        font-family: $fourth-font-family;
        line-height: calc(80/50);
        margin-bottom: rem(10);
        @include mq{
            font-size: rem(32);
        }
    }
    &__ttl span{
        display: block;
        font-size: rem(42);
        font-weight: $normal;
        font-family: $fourth-font-family;
        line-height: 0.85;
        @include mq{
            font-size: rem(24);
        }
    }
    &__left-text{
        font-size: rem(16);
        font-family: $fourth-font-family;
        // letter-spacing: 0.1em;
        font-weight: $normal;
        @include mq{
            font-size: rem(15);
        }
    }
    &__right{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    &__right-text1{
        margin-bottom: rem(15);
        font-size: rem(21);
        font-family: $fourth-font-family;
        font-weight: $normal;
        // letter-spacing: 0.1em;
        @include mq{
            font-size: rem(18);
            margin-bottom: rem(10);
        }
    }
    &__right-text2{
        font-size: rem(14);
        font-weight: $demilight;
        // letter-spacing: 0.05em;
        line-height: calc(30/14);
        margin-bottom: rem(20);
        @include mq{
            margin-bottom: rem(10);
        }
    }
    &__buttons{
        display: flex;
        gap: rem(10);
        width: 80%;
        @include mq{
            width: 100%;
        }
    }
    &__button{
        width: 48%;
        text-align: center;
        border-radius: rem(5);
        background: $gray10;
        font-size: rem(15);
        font-family: $fourth-font-family;
        letter-spacing: 0.1em;
        padding: rem(10);
        @include mq{
            letter-spacing: unset;
            font-size: rem(13);
            padding: rem(10) rem(8);
        }
    }
    &__contents{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(45);
        @include mq{
            grid-template-columns: 1fr;
            gap: rem(25);
        }
    }
    &__content{
        border-radius: rem(10);
        background: linear-gradient(rgba(255,196,191,0.5),rgba(206,236,233,0.5));
        padding: rem(60) rem(52);
        @include mq{
            padding: rem(30);
            border-radius: rem(5);
        }
    }
    &__content-read{
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        margin-bottom: rem(30);
        font-size: rem(26);
        text-align: center;
        @include mq{
            margin-bottom: rem(15);
            font-size: rem(20);
        }
    }
    &__content-read span{
        display: block;
        font-size: rem(16);
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.1em;
        line-height: 1.5;
        @include mq{
            font-size: rem(15);
        }
    }
    &__content-text{
        font-size: rem(14);
        font-weight: $demilight;
        // letter-spacing: 0.05em;
        line-height: calc(30/14);
        @include mq{
        line-height: calc(22/14);
        }
    }
    &__bg-text{
        position: absolute;
        top: rem(50);
        left: 8%;
        font-size: rem(100);
        letter-spacing: 0.05em;
        font-family: $third-font-family;
        background: linear-gradient(90deg,#f8f8f8,#fdefe3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: -1;
        
        @include mq{
            top: rem(20);
            width: 100%;
            font-size: rem(40);
            left: 68%;
        }
    }
}