@use "../utility" as *;
.news{
    .page-top{
        margin-bottom: rem(120);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    .page-top__ttl{
        line-height: 1;
        margin-bottom: 0;
        @include mq(tb){
            margin-bottom: rem(20);
        }
        @include mq{
            line-height: auto;
            margin-bottom: rem(40) !important;
        }
    }
    .page-top__inner{
        position: relative;
    }
    .page-top__inner::after{
        position: absolute;
        content: "";
        display: block;
        max-width: rem(1200);
        width: 100%;
        height: 1px;
        bottom: 0;
        left: $padding-pc;
        background: $gray8;
        @include mq{
            max-width: calc(100% - rem(40));
            left: rem(20);
        }
    }
}
.pn-body{
    max-width: rem(1250);
    padding: 0 $padding-pc;
    margin:0 auto;
    display: flex;
    @include mq{
        max-width: rem(600);
        display: block;
        padding: 0 $padding-sp;
    }
    &__news{
        padding-right: rem(80);
        width: 78%;
        @include mq{
            width: 100%;
            padding-right: unset;
        }
    }
    &__pages{
        margin: 0 auto;
        padding-bottom: rem(140);
        @include mq{
            padding-bottom: rem(70);
        }
    }
}

.pn-news{
    &__cards{
        margin-bottom:rem(75) ;
    }
    &__card{
        display: flex;
        gap: rem(40);
        align-items: flex-start;
        width: 100%;
        max-width: unset;
        padding-bottom: rem(40);
        margin-bottom: rem(40);
        border-bottom: 1px solid $gray8;
        @include mq{
            flex-direction: column;
            gap: rem(20);
        }
    }
    & .card1__img{
        aspect-ratio:200/135;
        max-width: rem(200);
        margin-bottom: unset;
        @include mq{
            max-width: unset;
            width: 100%;
        }
    }
    & .card1__title{
        font-size: rem(28);
        line-height: 1.2;
        @include mq{
            font-size: rem(20);
        }
    }
    & .card1__text{
        align-items: flex-start;
    }
    & .card1__year-month{
        @include mq{
            font-size: rem(11);
        }
    }
    & .card1__info{
        margin-top: rem(25);
        gap: rem(30);
        @include mq{
            margin-top: rem(15);
            gap: rem(15);
        }
    }
    & .card1__year-month{
        font-size: rem(16);
    }
    & .card1__day{
        font-size: rem(32);
        @include mq{
            font-size: rem(24);
        }
    }

}
