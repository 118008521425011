@use "../utility" as *;

.section-ttl{
    white-space: nowrap;
    line-height: 1;
    overflow: hidden;
    font-size: rem(90);
        display: block;
        letter-spacing: 0.05em;
        font-family: $third-font-family;
    & span{
        font-size: rem(90);
        display: block;
        letter-spacing: 0.05em;
        font-family: $third-font-family;
        @include mq{
            font-size: rem(45);
        }
    }
}