@use "../utility" as *;
// ブログ
.blog{
    .page-top{
        margin-bottom: rem(120);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    .page-top__ttl{
        line-height: 1;
        margin-bottom: 0;
        @include mq(tb){
            margin-bottom: rem(20);
        }
        @include mq{
            line-height: auto;
            margin-bottom: rem(20);
        }
    }
    .page-top__inner{
        position: relative;
    }
    .page-top__inner::after{
        position: absolute;
        content: "";
        display: block;
        max-width: rem(1200);
        width: 100%;
        height: 1px;
        bottom: 0;
        left: $padding-pc;
        background: $gray8;
        @include mq{
            max-width: calc(100% - rem(40));
            left: rem(20);
        }
    }
    .page-top__ttl .katsuki{
        font-size: rem(48);
        font-family: $second-font-family;
        font-weight: $light;
        letter-spacing: 0.12em;
        vertical-align: middle;
        display: inline-block;
        padding-right: rem(10);
        @include mq{
            font-size: rem(28);
            letter-spacing: 0.1em;
        }
    }
}

.pb-body{
    max-width: rem(1250);
    padding:0 $padding-pc;
    display: flex;
    margin: 0 auto;
    @include mq{
        max-width: rem(600);
        display: block;
        padding: 0 $padding-sp;
    }
    &__blog{
        padding-right: rem(80);
        width: 78%;
        @include mq{
            width: 100%;
            padding-right: unset;
        }
    }
    
    &__pages{
        margin: 0 auto;
        padding-bottom: rem(140);
        @include mq{
            padding-bottom: rem(70);
        }
    }
}

// ブログ
.pb-blog{
    &__cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(80) rem(60);
        margin-bottom: rem(80);
        @include mq{
            grid-template-columns: 1fr;
            gap: rem(40);
            margin-bottom: rem(40);
        }
    }
    & .card1__title{
        font-feature-settings: "palt";
        letter-spacing: 0.03em;
    }
}