@use "../utility" as *;
.menu{
    .page-top__ttl{
        margin-bottom: rem(30);
        @include mq{
            margin-bottom: rem(12);
        }
    }
    .page-top__content{
        @include mq{
            gap:unset;
        }
    }
}
.pm-menu{
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin: 0 auto;
        @include mq{
            max-width: rem(600);
            padding: 0 $padding-sp;
        }
    }
    &__container{
        padding: rem(140) 0 rem(100);
        @include mq{
            padding: rem(60) 0;
        }
    }
    &__ttl{
        font-size: rem(48);
        font-family: $third-font-family;
        text-align: center;
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(30);
            margin-bottom: unset;
        }
    }
    &__ttl-at{
        font-size: rem(14);
        font-family: $base-font-family;
        font-weight: $demilight;
        display: block;
        text-align: right;
        margin-bottom: rem(90);
        margin-top: rem(-40);
        @include mq{
            text-align: center;
            margin-top: unset;
            margin-bottom: rem(35);
            font-size: rem(12);
        }
    }
    &__c-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:rem(38) ;
        border-bottom: 1px solid $black;
        @include mq{
            padding-bottom: rem(5);
            margin-bottom: rem(30);
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
        }
    }
    &__ttl2{
        font-size: rem(28);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(22);
        }
    }
    &__ttl2 span{
        font-size:rem(20) ;
        font-family: $fourth-font-family;
        font-weight: $normal;
        letter-spacing: 0.05em;
        padding-left: rem(10);
        @include mq{
            font-size: rem(18);
        }
    }
    &__read{
        font-size: rem(14);
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(12);
            text-align: right;
        }
    }
    &__content{
        margin-bottom: rem(65);
        @include mq{
            margin-bottom: rem(40);
        }
    }
    &__lists{
        display: flex;
        flex-wrap: wrap;
        gap: rem(20) 0;
        margin-bottom: rem(45);
        @include mq{
            flex-direction: column;
            gap: rem(25);
        }
    }
    &__list{
        width: 50%;
        padding:0 rem(25);
        display: flex;
        gap: rem(15);
        justify-content: space-between;
        @include mq{
            width: 100%;
            padding:unset;
        }
    }
    &__l-name{
        font-size: rem(16);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        @include mq{
            font-size: rem(14);
        }
    }
    &__l-name span{
        margin-top: rem(5);
        display: block;
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        line-height: calc(21/14);
        color: $gray11;
        @include mq{
            font-size: rem(11);
            font-feature-settings: 'palt';
            margin-top: unset;
        }
    }
    &__l-price{
        font-size: rem(18);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        font-weight: $regular;
        @include mq{
            font-size: rem(14);
            white-space: nowrap;
            width: fit-content;
        }
    }
    &__ttl3{
        font-size: rem(24);
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        padding: rem(10) 0;
        text-align: center;
        background: $gray12;
        line-height: 1;
        margin-bottom: rem(20);
        @include mq{
            margin-bottom: rem(10);
            font-size: rem(20);
        }
    }
    &__attention{
        width: fit-content;
        margin-left: auto;
        font-size: rem(14);
        font-weight: $demilight;
        letter-spacing: 0.05em;
        color:$gray11;
        line-height: calc(21/14);
        margin-bottom:rem(33) ;
        @include mq{
            margin-bottom: rem(23);
            font-size: rem(12);
        }
    }
}