@use "../utility" as *;

.sec-404 {
    padding: rem(150) 0 rem(100);
    .ttl {
        font-size: rem(130);
        font-family: $fourth-font-family;
        font-weight: bold;
        line-height: 1em;
        margin-bottom: 35px;
        small {
            font-size: rem(24);
            font-family: $fourth-font-family;
            display: block;
            line-height: 1em;
            letter-spacing: 0.05em;
        }
    }
    .txt {
        font-size: rem(14);
        line-height: 1.6em;
        letter-spacing: 0.05em;
    }
    @include mq{
        padding: rem(100) 0 rem(60);
        .ttl {
            font-size: rem(110);
            margin-bottom: 35px;
            small {
                font-size: rem(20);
            }
        }
        .txt {
            font-size: rem(14);
        }
    }
}
