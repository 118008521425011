@use "../utility" as *;

.footer{
    background: $gray;
    padding: rem(110) 0 rem(70);
    @include mq{
        padding: rem(55) 0 rem(30);
    }
    &__inner{
        max-width: rem(1200);
        margin: 0 auto;
        width: 90%;
        @include mq{
            max-width: rem(600);
        }
    }
    &__contents{
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(60);
        @include mq{
            flex-direction: column;
            gap: rem(30);
        }
    }
    &__content{
        width: 50%;
        @include mq{
            width: 100%;
        }
    }
    &__logo{
        @include mq{
            text-align: center;
        }
    }
    &__logo a{
        color: $black;
        font-size: rem(48);
        @include mq{
            font-size: rem(38);
        }
    }
    &__menus{
        display: grid;
        gap: rem(20);
        grid-template-columns: repeat(3,1fr);
        @include mq{
            grid-template-columns: 1fr 1fr;
            gap: rem(30);
        }
    }
    &__menu{
        font-size: rem(18);
        line-height: 1;
        letter-spacing: 0.05em;
        @include mq{
            text-align: left;
            font-size: rem(16);
        }

    }
    &__menu a{
        color: $black;
        font-family: $third-font-family;
        letter-spacing: 0.05em;
        transition: all 0.3s ease-in-out;
    }
    &__menu a:hover{
        color: $hover;
    }
    &__sns{
        margin-bottom: rem(50);
        display: flex;
        gap: rem(15);
        justify-content: flex-end;
        @include mq{
            margin-bottom: rem(25);
            justify-content: center;
        }
    }
    &__sns a{
        display: block;
        color: $black;
        font-size: rem(24);
        @include mq{
            font-size: rem(18);
        }
    }
    &__copy{
        text-align: center;
        color: $gray3;
        font-size: rem(12);
        font-family: $third-font-family;
        @include mq{
            font-size: rem(10);
        }
    }
}