@use "../utility" as *;
// staffページタイトル
.gallery{
    .page-top{
        margin-bottom: rem(120);
        @include mq{
            margin-bottom: rem(30);
        }
    }
    .page-top__inner{
        position: relative;
    }
    .page-top__inner::after{
        position: absolute;
        content: "";
        display: block;
        max-width: rem(1250);
        width: 100%;
        height: 1px;
        bottom: 0;
        left: $padding-pc;
        background: $gray8;
        @include mq{
            max-width: calc(100% - rem(40));
            left: rem(20);
        }
    }
    // popup
    .fancybox__backdrop {
        background: rgba(0,0,0,0.4);
    }
    .fancybox__toolbar {
        background: none;
    }
}

// read
.gallery{
    .page-read{
        margin-bottom: rem(125);
        @include mq{
            margin-bottom: rem(60);
        }
    }
    .page-read__img{
        width: 38%;
        aspect-ratio: 640/480;
        @include mq{
            width: 100%;
        }
    }
    .page-read__read{
        padding-right: rem(120);
        margin-top: rem(-150);
        font-size: rem(36);
        font-feature-settings: 'palt';
        @include mq{
            padding-right: unset;
            margin-top: unset;
            font-size: rem(18);
        }
    }
    .page-read__bg-text{
        bottom: 0;
        left: 14%;
        @include mq{
            bottom: unset;
            top: rem(-40);
            z-index: -1;
            left: 70%;
        }
    }
}

// select{
.pg-select{
    margin-bottom: rem(100);
    @include mq{
        margin-bottom: rem(50);
    }
    &__lists{
        width: fit-content;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(5,1fr);
        gap: rem(30);
        @include mq{
            grid-template-columns: repeat(3,1fr);
            gap: rem(20);
        }
    }
    &__list a{
        width: rem(160);
        aspect-ratio: 1;
        text-align: center;
        line-height: rem(160);
        font-size: rem(24);
        font-family: $third-font-family;
        font-weight: $regular;
        letter-spacing: 0.05em;
        border-radius: 50%;
        display: block;
        color: $gray9;
        border: 1px solid $gray6;
        @include mq{
            width: rem(100);
            line-height: rem(100);
            font-size: rem(18);
        }
    }
    &__list.is-active a{
        background: $black;
        color: $white;
    }
}

// gallery
.pg-gallery{
    &__inner{
        max-width: rem(1250);
        padding: 0 $padding-pc;
        margin:0 auto;
        @include mq{
            max-width: rem(600);
            padding:0 $padding-sp;
        }
    }
    &__images{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap:rem(30) ;
        margin-bottom: rem(75);
        @include mq{
            grid-template-columns: 1fr 1fr;
            gap: rem(15);
            margin-bottom: rem(30);
        }
    }
    &__image{
        // aspect-ratio: 380/565;
        padding-top: 148.5%;
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0,0,0,0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: 0.4s opacity;
            &:hover {
                opacity: 1;
            }
            ul {
                li {
                    display: flex;
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .txt01 {
                        font-size: rem(18);
                        color: #fff;
                        font-family: $third-font-family;
                        width: rem(70);
                        text-align: right;
                        margin-right: rem(70);
                        position: relative;
                        letter-spacing: 0.05em;
                        &:after {
                            content: "";
                            height: 1px;
                            width: rem(40);
                            background: #fff;
                            position: absolute;
                            top: 50%;
                            right: rem(-15);
                            transform: translateX(100%);
                        }
                    }
                    .txt02 {
                        font-size: rem(18);
                        color: #fff;
                        font-family: $fourth-font-family;
                        letter-spacing: 0.05em;
                    }
                }
            }
        }
    }
    &__pages{
        margin:0 auto rem(140);
        @include mq{
            margin-bottom: rem(70);

        }
    }
}